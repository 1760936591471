import {
  callRestPost,
  callRestGet,
  callRestDelete,
} from "../../utils/callRestApi";
import { createEndpointQueryParams } from "../../utils/createEndpointQueryParams";

export const heatMapApi = {
  createNew: async (body) => {
    const result = { data: null, error: null };
    await callRestPost(`/dashboard/heatmap`, body, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  delete: async (uuid) => {
    const result = { data: null, error: null };
    await callRestDelete(`/dashboard/heatmap/${uuid}`, null)
      .then((res) => {
        if (res.status === 204) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  list: async (page, data) => {
    const result = { data: null, error: null };

    await callRestGet(
      `/dashboard/heatmap?page=${page}&${createEndpointQueryParams(data)}`,
      null
    )
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  single: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/heatmap/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  singleChart: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/heatmap/chart/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  getDetails: async (data) => {
    const result = { data: null, error: null };
    const queryParams = createEndpointQueryParams(data);

    await callRestGet(
      `/dashboard/heatmap/filter?${queryParams}`,
      null
    )
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });
    return result;
  },

  getAnalysisList: async (page, data) => {
    const result = { data: null, error: null };

    await callRestGet(
      `/dashboard/heatmap/filter/second/step?page=${page}&${data}`,
      null
    )
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  getAlerts: async (id) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/heatmap/notifications/${id}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
