import { callRestPost, callRestGet, callRestPut } from "~/utils/callRestApi";
import { createEndpointQueryParams } from "~/utils/createEndpointQueryParams";

export const setupApi = {
  vaultsList: async () => {
    const result = { data: null, error: null };
    await callRestGet("/dashboard/vaults", null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  pairPrice: async (pair) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/pairs/price/${pair}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  createNew: async (body) => {
    const result = { data: null, error: null };
    await callRestPost("/dashboard/setup", body, null)
      .then((res) => {
        if (res.status === 201) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  list: async (page, data) => {
    const result = { data: null, error: null };
    await callRestGet(
      `/dashboard/setup?page=${page}&${createEndpointQueryParams(data)}`,
      null
    )
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  single: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/setup/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  changeState: async (id) => {
    const result = { data: null, error: null };
    await callRestPut(`/dashboard/setup/state/${id}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  stateHistory: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/setup/points/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
