import React, { useRef, useState } from "react";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import Header from "./header/Header";

import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/imbalances/TVChartContainer";
import { Loading } from "~/components/child/ui/Loading";
import { useMainChartDetails } from "~/utils/hooks";

const Imbalances = () => {
  const [pair, setPair] = useState("BTC/USDT");
  const [isPlotZones, setIsPlotZones] = useState(false);
  const [imbalanceValue, setImbalanceValue] = useState(99);
  const { theme, isLoading } = useMainChartDetails();

  const chartRef = useRef();

  return (
    <div className={`${chartSize?.fullScreen}`}>
      {!isLoading && theme ? (
        <>
          <Header
            chartRef={chartRef}
            pair={pair}
            setPair={setPair}
            isPlotZones={isPlotZones}
            setIsPlotZones={setIsPlotZones}
            imbalanceValue={imbalanceValue}
            setImbalanceValue={setImbalanceValue}
            theme={theme}
          />

          <div className={"h-full"}>
            <TVChartContainer
              ref={chartRef}
              pair={pair.replace("/", "")}
              setIsPlotZones={setIsPlotZones}
              imbalanceValue={imbalanceValue}
              theme={theme}
            />
          </div>
        </>
      ) : !isLoading ? (
        <DataFailureAlert />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Imbalances;
