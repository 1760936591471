export const Node = ({ title, isActive, uuid, details, supportingElement }) => (
  <div key={uuid} className="flex items-center w-full gap-2">
    <div
      className={`w-2 h-2 rounded-full z-10 border border-storm-40 ${
        isActive ? "bg-storm-40" : "bg-base"
      }`}
    ></div>
    <div
      className={`py-[0.625rem] px-2 w-full ${
        isActive ? "bg-sky-10" : "bg-storm-10"
      } gap-2 flex flex-col  rounded-lg`}
    >
      <div className="flex text-sm items-center justify-between">
        <p className=" text-storm-80 ">{title}</p>
        {details && details}
      </div>
      {supportingElement && supportingElement}
    </div>
  </div>
);
