import { callRestPost, callRestGet } from "../../../utils/callRestApi";

export const privateNoteApi = {
  createNew: async (body, uuid, section) => {
    const result = { data: null, error: null };
    await callRestPost(`dashboard/${section}/private/notes/${uuid}`, body, null)
      .then((res) => {
        if (res.status === 201) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  list: async (uuid, section) => {
    const result = { data: null, error: null };
    await callRestGet(`dashboard/${section}/private/notes/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
