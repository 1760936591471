import React from "react";

import GroupArrowLeftSvg from "../../assets/icon/svgJsx/GroupArrowLeftSvg";
import GroupArrowRightSvg from "../../assets/icon/svgJsx/GroupArrowRightSvg";

const NotificationPointsSvg = ({ status }) => {
  if (status === "goes over")
    return (
      <i className="NotificationPointsSvg">
        <GroupArrowRightSvg className="NotificationPointsSvg__over" />
      </i>
    );
  else if (status === "goes under")
    return (
      <i className="NotificationPointsSvg">
        <GroupArrowLeftSvg className="NotificationPointsSvg__under" />
      </i>
    );
  return (
    <>
      <i className="NotificationPointsSvg">
      <GroupArrowLeftSvg className="NotificationPointsSvg__under" />
      </i>
      <i className="NotificationPointsSvg">
      <GroupArrowRightSvg className="NotificationPointsSvg__over" />
      </i>
    </>
  );
};

export default NotificationPointsSvg;
