export class ZoneRenderClass {
  constructor(tvChart, pair, imbalanceValue) {
    this.tvChart = tvChart;
    this.pair = pair;
    this.imbalanceValue = imbalanceValue;
  }

  calculateZones = (zone) => {
    let volumeInMilions = Math.round(
      (zone.volume * (zone.points[0].price + zone.points[1].price)) /
        2 /
        1000000
    );
    let imb = Math.abs(zone.imbalance);
    let score = imb * volumeInMilions;

    if (imb < this.imbalanceValue) {
      return;
    }

    let color =
      "rgba(155, 210, 160, " +
      Math.abs(40 / (100 - zone.imbalance) / 100) +
      ")";
    if (zone.side == "Sell") {
      color =
        "rgba(250, 80, 95, " +
        Math.abs(40 / (100 - Math.abs(zone.imbalance)) / 100) +
        ")";
    }

    return { zone, volumeInMilions, color };
  };

  action = (zonesList) => {
    let chart = this.tvChart.current.chart();
    zonesList && zonesList.forEach((zoneItem) => {
      const result = this.calculateZones(zoneItem);

      if (!result) {
        return;
      }

      let { zone, volumeInMilions, color } = result;

      chart.createMultipointShape(zone.points, {
        shape: "rectangle",
        text: volumeInMilions + "M",
        lock: true,
        disableUndo: true,
        disableSelection: true,
        disableSave: true,

        overrides: {
          backgroundColor: color,
          linewidth: 0,
          textColor: "#291b00",
          fontSize: "12",
          showLabel: true,
          horzLabelsAlign: "center",
          vertLabelsAlign: "middle",
        },
      });
    });
  };
}
