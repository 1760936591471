import { TimeFrameCalculatorClass } from "../classes/timeFrameCalculatorClass";
import { validateInput } from "../helpers/tools/validateInput";

const getTimeRange = (
  timeCalc,
  beforeDays,
  afterDays,
  createdAt,
  baseDateType
) => {
  const functionName = `createTradeBoundariesWidgetTimeRange() => ${getTimeRange.name}`;
  validateInput(baseDateType, "baseDateType", functionName);
   
  if(beforeDays === 0) {
    validateInput(null, "beforeDays", functionName, 'beforeDays cannot be 0');
  }
  if(afterDays === 0) {
    validateInput(null, "afterDays", functionName, 'afterDays cannot be 0');
  }

  return {
    timeStart: timeCalc.beforeTime(beforeDays, createdAt, baseDateType),
    timeEnd: timeCalc.afterTime(afterDays, createdAt, baseDateType),
  };
};

export const createTradeBoundariesWidgetTimeRange = (
  interval,
  createdAt,
  baseDateType,
  tempDynamicRange = false
) => {
  const timeCalc = new TimeFrameCalculatorClass();

  if (tempDynamicRange) {
    return {
      timeStart: timeCalc.beforeTime(0, createdAt, baseDateType),
      timeEnd: Math.floor(Date.now() / 1000), 
    };
  }

  switch (interval) {
    case "1":
    case "3":
    case "5":
      return getTimeRange(timeCalc, 1, 1, createdAt, baseDateType);

    case "15":
      return getTimeRange(timeCalc, 4, 5, createdAt, baseDateType);

    case "30":
    case "45":
    case "60":
    case "120":
    case "180":
    case "240":
      return getTimeRange(timeCalc, 4, 30, createdAt, baseDateType);

    case "D":
      return getTimeRange(timeCalc, 30, 90, createdAt, baseDateType);

    case "W":
      return getTimeRange(timeCalc, 120, 365, createdAt, baseDateType);

    case "M":
    case "3M":
    case "6M":
    case "12M":
      return getTimeRange(timeCalc, 365, 1095, createdAt, baseDateType);

    default:
      validateInput(
        null,
        "interval",
        "createTradeBoundariesWidgetTimeRange",
        `Invalid interval: ['${interval}'].`
      );
  }
};
