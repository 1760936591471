import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

export const TechnicalTooltip = ({
  timeFrames,
  analyzers,
  types,
  technicalTypes,
  id,
  position,
}) => {
  return (
    <ReactTooltip
      id={id}
      place={position}
      multiline={true}
      content={
        <div className=" w-60 py-2 flex flex-col gap-2 text-sm font-normal font-roboto text-oil-light">
          {timeFrames?.length > 0 && (
            <>
              <span className="text-base font-robotoMed font-bold text-sky-light">
                TimeFrame
              </span>
              <div className=" flex items-center gap-2 flex-wrap border-b border-storm-30 pb-2">
                {timeFrames?.map((timeFrame, index) => (
                  <span className="flex items-center gap-2" key={index}>
                    {timeFrame}
                    {index !== timeFrames.length - 1 ? <span>.</span> : ""}
                  </span>
                ))}
              </div>
            </>
          )}
          {analyzers?.length > 0 && (
            <>
              <span className="text-base font-robotoMed font-bold text-sky-light">
                Analyzers
              </span>
              <div className=" flex items-center gap-2 flex-wrap border-b border-storm-30 pb-2">
                {analyzers?.map((analyser, index) => (
                  <span className="flex items-center gap-2" key={index}>
                    {analyser}
                    {index !== analyzers?.length - 1 ? <span>.</span> : ""}
                  </span>
                ))}
              </div>
            </>
          )}
          {types?.length > 0 && (
            <>
              <span className="text-base font-robotoMed font-bold text-sky-light">
                Type
              </span>
              <div className=" flex items-center gap-2 flex-wrap border-b border-storm-30 pb-2">
                {types?.map((type, index) => (
                  <span className="flex items-center gap-2" key={index}>
                    {changeWordFormatToTitleCase(type)}
                    {index !== types?.length - 1 ? <span>.</span> : ""}
                  </span>
                ))}
              </div>
            </>
          )}
          {technicalTypes?.length > 0 && (
            <>
              <span className="text-base font-robotoMed font-bold text-sky-light">
                Technical Type
              </span>
              <div className=" flex items-center gap-2 flex-wrap pb-2">
                {Array.isArray(technicalTypes) ? (
                  technicalTypes?.map((technicalType, index) => (
                    <span className="flex items-center gap-2" key={index}>
                      {changeWordFormatToTitleCase(technicalType.technical_type)}
                      {index !== technicalTypes?.length - 1 ? (
                        <span>.</span>
                      ) : (
                        ""
                      )}
                    </span>
                  ))
                ) : (
                  <span className="flex items-center gap-2">
                    {changeWordFormatToTitleCase(technicalTypes)}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      }
    />
  );
};
