import React, { useState, useEffect } from "react";
import { pairsApi } from "~/api/dashboard/pairsApi";
import Dropdown from "~/components/child/ui/DropDown";
import Button from "~/components/child/ui/Button";
import { useKeySubmission } from "~/utils/hooks";

const NewPair = () => {
  const [binancePairs, setBinancePairs] = useState(null);
  const [selectedPair, setSelectedPair] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState(
    {
      error: undefined,
      success: undefined,
    } || undefined
  );

  useEffect(() => {
    const loadJsonData = async () => {
      const binance = await import("~/assets/json/binance.json");
      const coinmarketcap = await import("~/assets/json/coinmarketcap.json");
      setBinancePairs(
        binance.default.map((item) => {
          const baseAsset = coinmarketcap.default.filter(
            (coin) => coin.symbol === item.baseAsset.symbol
          );
          const quoteAsset = coinmarketcap.default.filter(
            (coin) => coin.symbol === item.quoteAsset.symbol
          );
          return {
            symbol: item.pair,
            baseAsset: [...baseAsset],
            quoteAsset: [...quoteAsset],
          };
        })
      );
    };
    loadJsonData();
  }, []);

  useEffect(() => {
    if (
      (resultMessage?.error || resultMessage?.success) &&
      selectedPair !== null
    )
      setResultMessage(undefined);
  }, [selectedPair]);

  const onSubmit = () => {
    setIsLoading(true);

    pairsApi.addNew(selectedPair).then((res) => {
      if (res && !res.error && res?.data) {
        setIsLoading(false);
        setSelectedPair(null);

        setResultMessage({
          error: undefined,
          success: "The new pair was added successfully!",
        });
      } else if (res?.error) {
        setIsLoading(false);

        if (res?.error.response?.data?.errors) {
          setResultMessage({
            success: undefined,
            error: res?.error?.response?.data?.errors,
          });
        } else
          setResultMessage({
            success: undefined,
            error: [res?.error.response?.data.message],
          });
      }
    });
  };
  useKeySubmission(
    onSubmit,
    () =>
      isLoading ||
      !selectedPair?.quote_asset ||
      !selectedPair?.base_asset ||
      (resultMessage?.error && Object.values(resultMessage?.error)?.length > 0),
    (event) => event.key === "Enter",
    []
  );
  return (
    <div className="main__router__container">
      <div className="items-center bg-base rounded-2xl m-auto min-w-[24%] py-12 px-4">
        <form className="flex flex-col gap-5 w-full items-center">
          <Dropdown
            searchable
            optionsList={binancePairs?.map((pair) => ({
              key: pair?.symbol,
              value: pair?.symbol,
            }))}
            title="Pairs"
            value={selectedPair || undefined}
            renderItem={(item) => (
              <span className="p-2 cursor-pointer text-xs font-roboto w-full text-left">
                {item?.key}
              </span>
            )}
            onSelect={(item) => {
              setSelectedPair({
                pair: item.value,
                key: item.value,
              });
            }}
          />
          {selectedPair?.key && (
            <>
              <Dropdown
                optionsList={binancePairs
                  ?.find((pair) => pair.symbol === selectedPair?.key)
                  ?.baseAsset?.map((coin) => ({
                    key: coin?.slug,
                    value: coin.symbol,
                    symbol: coin.symbol,
                    name: coin.name,
                    image: `https://s2.coinmarketcap.com/static/img/coins/64x64/${coin?.id}.png`,
                  }))}
                title="Base Asset"
                disabled={!selectedPair?.key}
                value={selectedPair?.base_asset || undefined}
                renderItem={(item) => (
                  <span className="p-1 cursor-pointer flex items-center gap-2 text-xs font-roboto w-full text-left">
                    <img
                      className=" w-7 h-7 rounded-full"
                      alt="Quote Asset Icon"
                      src={item.image}
                    />
                    {item?.symbol} ({item?.name})
                  </span>
                )}
                onSelect={(item) => {
                  setSelectedPair({
                    ...selectedPair,
                    base_asset: {
                      key: item?.key,
                      symbol: item?.value,
                      name: item?.name,
                      image: item.image,
                    },
                  });
                }}
              />
              <Dropdown
                optionsList={binancePairs
                  ?.find((pair) => pair.symbol === selectedPair?.key)
                  ?.quoteAsset?.map((coin) => ({
                    key: coin?.slug,
                    value: coin.symbol,
                    symbol: coin.symbol,
                    name: coin.name,
                    image: `https://s2.coinmarketcap.com/static/img/coins/64x64/${coin?.id}.png`,
                  }))}
                title="Quote Asset"
                disabled={!selectedPair?.key}
                value={selectedPair?.quote_asset || undefined}
                renderItem={(item) => (
                  <span className="p-1 cursor-pointer flex items-center gap-2 text-xs font-roboto w-full text-left">
                    <img
                      className=" w-7 h-7 rounded-full"
                      alt="Quote Asset Icon"
                      src={item.image}
                    />
                    {item?.symbol} ({item?.name})
                  </span>
                )}
                onSelect={(item) => {
                  setSelectedPair({
                    ...selectedPair,
                    quote_asset: {
                      key: item.key,
                      symbol: item.value,
                      name: item.name,
                      image: item.image,
                    },
                  });
                }}
              />
            </>
          )}

          <Button
            type="submit"
            onClick={() => onSubmit()}
            variant="primary"
            className="mt-6 w-1/2 mx-auto"
            isLoading={isLoading}
            disabled={
              isLoading ||
              !selectedPair?.quote_asset ||
              !selectedPair?.base_asset ||
              (resultMessage?.error &&
                Object.values(resultMessage?.error)?.length > 0)
            }
          >
            Add Pair
          </Button>

          {resultMessage?.error && Object.keys(resultMessage?.error) && (
            <div className="flex flex-col items-center gap-2">
              {Object.keys(resultMessage?.error)?.map((key) => (
                <p
                  key={key}
                  className="m-0 font-roboto text-sm text-infrared-key text-center overflow-auto leading-5"
                >
                  {resultMessage?.error[key]}
                </p>
              ))}
            </div>
          )}
          {resultMessage?.success && (
            <p className="m-0 font-roboto text-sm text-twilight-key text-center overflow-auto leading-5">
              {resultMessage.success}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewPair;
