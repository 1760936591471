import React from "react";
import { Link } from "react-router-dom";
import { OTPInput } from "input-otp";

function Slot(props) {
  return (
    <div
      className={`relative w-12 h-12 text-2xl flex items-center justify-center transition-all duration-300 border  rounded-2xl border-storm-40 ${
        props?.isActive && "border-4 !border-sky-key text-sky-key"
      } `}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  );
}

export const Step2 = ({
  setSignUpData,
  signUpData,
  onBackHandler,
  timeRemaining,
  onResendCode,
}) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`; // Format: MM:SS
  };
  return (
    <div className="flex flex-col gap-4 items-center justify-between">
      <h5 className=" text-lg text-sky-70">Verification code</h5>
      <span className="text-base text-storm-60 font-roboto">
        We have sent a code to{" "}
        <span className="text-sky-60">{signUpData?.phoneNumber}</span>
      </span>
      <p className="py-2 rounded-2xl bg-[#EBEFF3] px-4 text-base font-roboto text-storm-key">
        Wrong Number?{" "}
        <Link
          onClick={() => {
            setSignUpData({ ...signUpData, otp: "" });
            onBackHandler();
          }}
          className="text-sky-key"
        >
          Change number
        </Link>
      </p>
      <OTPInput
        value={signUpData.otp}
        onChange={(value) => setSignUpData({ ...signUpData, otp: value })}
        maxLength={5}
        disabled={timeRemaining === 0}
        inputMode={"numeric"}
        containerClassName="py-8 flex items-center disabled:opacity-30"
        render={({ slots }) => (
          <div className="flex items-center gap-2.5">
            {slots.slice(0, 5).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        )}
      />

      <span className="py-4 text-storm-60 text-sm font-roboto">
        {timeRemaining > 0 ? (
          formatTime(timeRemaining)
        ) : (
          <span className="flex items-center gap-1">
            Time is up!
            <Link
              className="text-center font-robotoMed text-base text-sky-key"
              onClick={() => {
                setSignUpData({ ...signUpData, otp: "" });
                onResendCode();
              }}
            >
              Resend code
            </Link>
          </span>
        )}
      </span>
    </div>
  );
};
