import axios from "axios";

export const getImbalancesData = async (url) => {
    let result = null;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        result = res.data;
      } else {
        console.log("imbalances : get data error");
      }
    } catch (error) {
      console.error(error.error);
    }

    return result;
};