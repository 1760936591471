export const trimTrailingCharacter = (str, char) => {
  if (typeof str !== "string") {
    throw new Error("Invalid str argument: must be a string");
  }
  if (typeof char !== "string" || char.length !== 1) {
    throw new Error("Invalid char argument: must be a single character string");
  }

  const regex = new RegExp(`[${char} ]*$`);
  return str.replace(regex, "");
};
