import React from "react";

const colorSkyKey = "var(--cstm-sky-key)";

function CustomInput({
  label,
  onFocus,
  value,
  onChange,
  forcible,
  placeholder,
}) {
  return (
    <div
      style={{
        borderRadius: "1rem",
        border: "1px solid #E0E7EB",
        padding: "0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "var(--cstm-color-base)",
        position: "relative",
        gap: "0.5rem",
      }}
    >
      <span
        className="min-w-fit"
        style={{
          fontFamily: "RobotoMed",
          fontSize: "0.875rem",
          color: "#0d0d0d",
        }}
      >
        {label || "Date"}
        {forcible && " *"}
      </span>

      <div
        className="date-picker-input"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <input
          onFocus={onFocus}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            border: "none",
            outline: "none",
            zIndex: "2",
            background: "none",
            cursor: "pointer",
            width: "100%",
            color: "#0d0d0d",
          }}
        ></input>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            style={{ stroke: colorSkyKey }}
            stroke="#007BFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M8 2v3M16 2v3M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5z"
          ></path>
          <path
            style={{ stroke: colorSkyKey }}
            stroke="#007BFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15.695 13.7h.009M15.695 16.7h.009M11.995 13.7h.01M11.995 16.7h.01M8.294 13.7h.01M8.294 16.7h.01"
          ></path>
        </svg>
      </div>
    </div>
  );
}
export default CustomInput;
