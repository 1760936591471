import React from "react";

function RemoveSvg({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#ABABAB"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8 14.667A6.667 6.667 0 108 1.334a6.667 6.667 0 000 13.333v0zM6 10l4-4M6 6l4 4"
      ></path>
    </svg>
  );
}

export default RemoveSvg;
