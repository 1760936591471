import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import settingSvg from "../../assets/icon/setting.svg";
import ArrowRightSvg from "../../assets/icon/svgJsx/ArrowRightSvg";
import RowVerticalSvg from "../../assets/icon/svgJsx/RowVerticalSvg";

export default function Setup({
  parentCategoryName,
  navBoxIsOpen,
  setupIsOpen,
  setSetupIsOpen,
  breadcrumbItems,
  user,
}) {
  const clickOnHeatMapItems = () => {
    if (!navBoxIsOpen) {
      setSetupIsOpen(false);
    }
  };

  const parentItemRef = useRef(null);
  const childListRefItem = useRef(null);
  const currentMainListOfNav = breadcrumbItems?.main === parentCategoryName;

  useEffect(() => {
    if (!navBoxIsOpen) {
      setSetupIsOpen(false);
    }
    if (navBoxIsOpen && currentMainListOfNav) {
      setSetupIsOpen(true);
    }

    const handleClickOutside = (event) => {
      if (!navBoxIsOpen) {
        if (
          parentItemRef.current &&
          childListRefItem.current &&
          !parentItemRef.current.contains(event.target) &&
          !childListRefItem.current.contains(event.target)
        ) {
          setSetupIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [parentItemRef, childListRefItem, navBoxIsOpen]);

  return (
    <li
      className={`nav__list__item itemClose ${setupIsOpen && "listOpen"} ${
        !navBoxIsOpen && currentMainListOfNav && "isActiveClose"
      }`}
    >
      <div
        ref={parentItemRef}
        onClick={() => setSetupIsOpen(!setupIsOpen)}
        className="nav__list__item__box"
      >
        <div className="nav__list__item__box__title">
          <i className="nav__list__item__box__icon">
            <img src={settingSvg} alt="setting icon" />
          </i>
          <span>{navBoxIsOpen && <div>{parentCategoryName}</div>}</span>
        </div>

        <div>
          <i
            className={`nav__list__item__box__arrow-icon ${
              setupIsOpen ? "arrow-rotate-left" : ""
            }`}
          >
            <ArrowRightSvg />
          </i>
        </div>
      </div>
      <div
        className={`nav__list__item__sub-category ${
          !navBoxIsOpen && "sub-ctgy-mode-2"
        }`}
      >
        <ul ref={childListRefItem}>
          {!navBoxIsOpen && (
            <span className="nav__list__item__sub-category__title">
              {parentCategoryName}
            </span>
          )}

          <NavLink
            onClick={clickOnHeatMapItems}
            to="/setupList"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <li>
              <i>
                <RowVerticalSvg />
              </i>
              <span>Setup list</span>
            </li>
          </NavLink>
        </ul>
      </div>
    </li>
  );
}
