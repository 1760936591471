import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { widget } from "~/charting_library";
import { WidgetCreatorClass } from "../../utils/classes/widgetCreatorClass";
import { convertIntervalToTradingViewFormat } from "../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createTradeBoundariesWidgetTimeRange } from "../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../utils/creators/createChartTimeFrame";
import { plotVerticalLineAtCreationTime } from "../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { createChartConfig } from "../../config/createChartConfig";
import { timezoneListCustom } from "../../utils/helpers/tools/timezoneListCustom";
import { createWidgetLabelOverrides } from "../../utils/creators/createWidgetLabelOverrides";
import { getPairDataConfig } from "../../utils/helpers/tools/getPairDataConfig";
import { widgetStyleDetermine } from "../../utils/determinators/widgetStyleDetermine";
import { validateInput } from "../../utils/helpers/tools/validateInput";
import "../index.css";

export const TVChartContainer = React.memo(
  ({ tradeBoundariesPoints, pairDetail, initInterval, createdAt, theme, baseDateType }) => {
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("setup");
    const [interval, setInterval] = useState(convertIntervalToTradingViewFormat(initInterval));

    const { pair, dataFeed: datafeedUrl } = getPairDataConfig(pairDetail, "snake_case");

    const { timeFrom, timeTo } = createChartTimeFrame(interval, createdAt.split(" ")[0]);

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrom, to: timeTo },
        timezone: timezoneListCustom[1],
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreatorClass(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const renderTradeBoundariesPoints = (points, type, createWidget) => {
            const functionName = renderTradeBoundariesPoints.name;
            validateInput(points, "points", functionName);
            validateInput(type, "type", functionName);
            validateInput(createWidget, "createWidget", functionName);

            if (points && points.length > 0) {
              for (const point of points) {
                let pricePoint = point.amount.toString();
                let weightPricePoint = `${point.weight}%`;

                createWidget.trendLine(
                  pricePoint,
                  createTradeBoundariesWidgetTimeRange(interval, createdAt, baseDateType),
                  type,
                  point?.weight &&
                    createWidgetLabelOverrides("trendLineSetup", {
                      text: weightPricePoint,
                      textcolor: widgetStyleDetermine(type)?.color,
                    })
                );
              }
            }
          };

          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
            });

          const tradeBoundariesPointsData = [
            { points: tradeBoundariesPoints?.ep, type: "ep" },
            { points: tradeBoundariesPoints?.sl, type: "sl" },
            { points: tradeBoundariesPoints?.tp, type: "tp" },
          ];

          tradeBoundariesPointsData.forEach(({ points, type }) =>
            renderTradeBoundariesPoints(points, type, createWidget)
          );
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [pairDetail, interval, createdAt, theme, baseDateType]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);
TVChartContainer.propTypes = {
  tradeBoundariesPoints: PropTypes.object.isRequired,
  pairDetail: PropTypes.object.isRequired,
  initInterval: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  baseDateType: PropTypes.string.isRequired,
};
