import React from "react";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import Accordion from "~/components/child/ui/Accordion";
import TechnicalPointPreview from "~/components/child/PointsPreview";
import ArrayImg from "~/components/child/ui/imageUpload/Preview";

const Preview = ({ analysisValues }) => {
  const epList = analysisValues?.technical?.points?.ep?.filter(
    (item) => item?.value !== ""
  );
  const tpList = analysisValues?.technical?.points?.tp?.filter(
    (item) => item?.value !== ""
  );
  const slList = analysisValues?.technical?.points?.sl?.filter(
    (item) => item?.value !== ""
  );
  return (
    <div className=" mt-16">
      <div className="text-sky-70 text-base mb-4">
        Your New
        {analysisValues?.technical.type === "setup"
          ? " Setup Technical"
          : " Technical Analysis"}
      </div>

      <AnalysisHeader
        pairs={analysisValues?.new?.pair}
        timeFrame={analysisValues?.technical?.timeFrame?.value}
        positionType={analysisValues?.new?.positionType}
      />

      <div className="text-oil-60 font-yekanBakh leading-normal mb-4 mt-5 text-right break-words">
        {analysisValues?.technical?.description}
      </div>

      <div className="mt-4 flex flex-col">
        {(epList?.length > 0 || tpList?.length > 0 || slList?.length > 0) && (
          <div className="text-sky-70 text-base mb-2">
            your entries ({epList?.length + tpList?.length + slList?.length})
          </div>
        )}

        {epList?.filter((item) => item?.value !== "").length > 0 && (
          <Accordion
            className={{ root: " bg-storm-10 rounded-lg" }}
            label={{
              leftPart: (
                <span className=" text-sky-key flex font-robotoMed text-sm gap-1">
                  EP
                  <span className="font-roboto text-storm-key">({epList?.length})</span>
                </span>
              ),
            }}
            openDefault={true}
          >
            {epList
              ?.filter((item) => item?.value !== "")
              ?.map((epItem, index) => (
                <TechnicalPointPreview
                  label={`EP ${index + 1}`}
                  className="mb-1"
                  key={index}
                  value={epItem.value}
                  note={epItem.desc}
                  noteInTooltip
                />
              ))}
          </Accordion>
        )}
        {tpList?.filter((item) => item?.value !== "")?.length > 0 && (
          <Accordion
            className={{ root: " bg-storm-10 rounded-lg" }}
            label={{
              leftPart: (
                <span className=" text-leaf-key flex font-robotoMed text-sm gap-1">
                  TP
                  <span className="font-roboto text-storm-key">({tpList?.length})</span>
                </span>
              ),
            }}
            openDefault={true}
          >
            {tpList
              ?.filter((item) => item.value !== "")
              ?.map((tpItem, index) => (
                <TechnicalPointPreview
                  className="mb-1"
                  label={`TP ${index + 1}`}
                  key={index}
                  value={tpItem.value}
                  note={tpItem.desc}
                  riskToReward={tpItem.rr}
                  noteInTooltip
                />
              ))}
          </Accordion>
        )}
        {slList?.filter((item) => item.value !== "").length > 0 && (
          <div className="bg-storm-10 rounded-lg p-[0.875rem] flex">
            {slList
              ?.filter((item) => item.value !== "")
              ?.map((slItem, index) => (
                <TechnicalPointPreview
                  className="w-full mb-1"
                  label="SL"
                  key={index}
                  value={slItem.value}
                  note={slItem.desc}
                  noteInTooltip
                />
              ))}
          </div>
        )}
      </div>
      <div className="my-8">
        <ArrayImg
          isEditable={false}
          images={analysisValues.technical?.images}
          className={{
            info: "text-sm !gap-2",
            image: "w-16 h-16",
            actions: "!items-end !gap-2 flex-col",
            primary: { label: " text-[0.625rem]", icon: "w-5 h-5" },
          }}
        />
      </div>
    </div>
  );
};

export default Preview;
