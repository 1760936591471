import React from "react";
import PropTypes from 'prop-types';

const LeftCard = ({ setPair, theme }) => {
  const colors = {
    bgColorLight: "bg-sky-key",
    bgColorDark: "bg-sky-70",
  };

  const pairs = ["BTC/USDT", "ETH/USDT", "ETH/BTC"];

  return (
    <div>
      {pairs.map((pairVal) => (
        <button
          key={pairVal}
          onClick={() => setPair(pairVal)}
          className={`p-2 text-sky-light ${
            theme === "dark" ? colors?.bgColorDark : colors?.bgColorLight
          } ml-1`}
        >
          {pairVal}
        </button>
      ))}
    </div>
  );
};

export default LeftCard;
LeftCard.propTypes = {
   setPair: PropTypes.func.isRequired,
   theme: PropTypes.string.isRequired
 };
 
 