import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { widget } from '~/charting_library';
import { createChartConfig } from '~/components/child/tradingViewMainChart/config/createChartConfig.js';
import { ImbalancesOperationClass } from '../../utils/classes/imbalanceProcessor/imbalancesOperationClass';
import { convertIntervalToTradingViewFormat } from '../../utils/converters/convertTimeFrameToTradingViewFormat';

import '../index.css';

export const TVChartContainer = forwardRef(
  ({ pair, setIsPlotZones, imbalanceValue, theme }, chartRef) => {
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig('imbalances');
    const tvWidgetRef = useRef();

    const [interval, setInterval] = useState('D');

    useImperativeHandle(chartRef, () => ({
      ImbalancesOperation,
    }));

    const tvChart = tvWidgetRef;
    const ImbalancesOperation = new ImbalancesOperationClass(tvChart, pair, imbalanceValue);

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(baseChartConfig?.datafeedUrl?.binance),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: 1704054600, to: Math.floor(Date.now() / 1000) },
      };

      const tvWidget = new widget(widgetOptions);
      tvWidgetRef.current = tvWidget;

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
              try {
                ImbalancesOperation.removeAllWidget();
                setIsPlotZones(false);
              } catch (error) {
                setIsPlotZones(true);
                console.log('Change Interval Inbalances Error => ', error);
              }
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [pair, interval]);

    return <div ref={chartContainerRef} className={'TVChartContainer'} />;
  }
);
TVChartContainer.propTypes = {
  pair: PropTypes.string.isRequired,
  setIsPlotZones: PropTypes.func.isRequired,
  imbalanceValue: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
};
