import { useState, useEffect } from "react";
import { timeFramesApi } from "../../api/dashboard/timeFramesApi";

export const useTimeFramesList = () => {
  const [timeFramesList, setTimeFramesList] = useState([]);

  useEffect(() => {
    const fetchTimeFramesList = async () => {
      try {
        const data = await timeFramesApi.timeFrames();
        setTimeFramesList(data);
      } catch (error) {
        console.error("Error fetching time frames data:", error);
      }
    };

    fetchTimeFramesList();
  }, []);

  return timeFramesList.data;
};