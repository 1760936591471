import React from "react";

import iconSrc from "~/assets/icon/copy.svg";

const WriteSomething = ({ placeholder, setValue, value }) => {
  return (
    <>
      <div className="input-box-cstm-one write-something-cstm-one">
        <div className="write-something-cstm-one__copy-icon">
          <i>
            <img src={iconSrc} alt="" />
          </i>
        </div>

        <div className="write-something-cstm-one__textarea">
          <textarea
            className="scrollbar-style scrollbar-style-thumb"
            placeholder={placeholder}
            name=""
            id=""
            value={value}
            onChange={(e) => setValue(e.target.value)}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default WriteSomething;
