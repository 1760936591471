import React from "react";
import Button from "~/components/child/ui/Button";
import welcome from "~/assets/img/welcome.svg";

export const Welcome = () => {
  return (
    <div className="flex flex-col justify-around h-auto md:justify-normal gap-10">
      <div className="md:m-auto flex w-full flex-col items-center justify-center py-40 pt-40 md:pb-0">
        <img
          className="absolute w-[22.5rem] md:w-fit h-[42.5%] md:h-[64%] max-h-full z-[1] bg-cover bg-center"
          alt="sign up"
          src={welcome}
        />
        <div className="flex flex-col gap-7 md:gap-9 items-center z-10">
          <div className="flex flex-col gap-2">
            <h4 className="text-center text-sky-70 text-lg md:text-2xl">
              Registration Successful!
            </h4>
            <p className="text-center text-base text-storm-60 font-roboto ">
              Thank you for joining{" "}
              <span className="text-lg font-robotoMed">TradetWar!</span>
            </p>
          </div>
          <p className="md:text-base text-sm font-roboto !text-storm-60 text-center w-[16.5rem] sm:w-[49%]">
            The competition begins on{" "}
            <span className="text-base text-sky-50 md:text-lg  font-robotoMed">
              October 20th
            </span>
            .
            <br />
            <span className="text-base md:text-lg font-robotoMed text-storm-60">
              1Day
            </span>{" "}
            before the start, we will send you the access link to the{" "}
            <span className="text-base md:text-lg text-sky-60 font-robotoMed">
              TradeWar
            </span>{" "}
            panel, where you can compete and showcase your analysis skills.
          </p>
          {/* <Button
            className="!px-5 hidden md:flex mt-4 !rounded-3xl font-robotoMed"
            variant="secondary"
          >
            Download terms and condition
          </Button> */}
        </div>
      </div>
      {/* <Button
        className="!px-5 md:hidden mt-20 md:mt-40 !rounded-3xl font-robotoMed"
        variant="secondary"
      >
        Download terms and condition
      </Button> */}
    </div>
  );
};
