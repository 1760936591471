import React from "react";
import AddPhotoSvg from "~/assets/icon/svgJsx/AddPhotoSvg";

const Upload = ({ onAddNewImage, information, className }) => {
  return (
    <div className="flex items-center justify-center border-2 border-dashed border-storm-20 rounded-lg py-1 px-0 mt-3">
      <form
        encType="multipart/form-data"
        className={`relative w-full p-8 ${className?.form}`}
      >
        <input
          className="cursor-pointer absolute left-0 right-0 top-0 bottom-0 z-[2] opacity-0"
          type="file"
          accept="image/*"
          onChange={(e) => onAddNewImage(e)}
        />

        <button
          className={`flex flex-col items-center justify-center text-storm-key font-roboto bottom-0 gap-2 absolute left-0 right-0 top-0 z-[1] outline-none border-none bg-transparent cursor-pointer ${className?.info}`}
        >
          <AddPhotoSvg className={`h-8 w-8 ${className?.svg}`} />
          {information}
        </button>
      </form>
    </div>
  );
};

export default Upload;
