import React, { useState, useEffect } from "react";
import RemoveSvg from "~/assets/icon/svgJsx/RemoveSvg";
import MessageSvg from "~/assets/icon/svgJsx/MessageSvg";
import SendSvg from "~/assets/icon/svgJsx/SendSvg";
import TrashSvg from "~/assets/icon/svgJsx/TrashSvg";
import messageIcon from "~/assets/icon/message.svg";

const AddDescription = ({ desc, onChange, setDescription, description }) => {
  return (
    <div className="bg-sky-10 items-center border border-solid border-sky-30 rounded-lg flex justify-between py-1 px-[0.375rem]">
      {!desc || desc?.length === 0 ? (
        <>
          <input
            onChange={(e) => setDescription(e.target?.value)}
            value={description}
            disabled={desc?.length > 0}
            placeholder="I’m writing a note for preview"
            className="bg-sky-10 border-none text-storm-50 font-yekanBakh text-sm outline-none font-normal w-full"
          />

          <button
            className={` flex p-[3px] ${
              description?.length > 0 ? "bg-sky-key rounded-lg" : ""
            }`}
            onClick={() => {
              onChange(description);
              setDescription("");
            }}
          >
            <SendSvg
              className=" w-[1.125rem] h-[1.125rem]"
              color={description?.length > 0 ? "#f0f7ff" : "#007BFF"}
            />
          </button>
        </>
      ) : (
        <p className="items-center flex justify-between text-storm-50 font-yekanBakh text-sm gap-1 m-0 w-full">
          <p className="flex break-words">{desc}</p>
          <button className="flex min-w-fit" onClick={() => onChange("")}>
            <TrashSvg className="w-[1.125rem]" />
          </button>
        </p>
      )}
    </div>
  );
};

const Point = ({
  title,
  titleColor,
  pointVal,
  setPointVal,
  onDelete,
  handleOnBlurPointInput,
}) => {
  const [description, setDescription] = useState("");

  const [showDesc, setShowDesc] = useState(false);
  const handlePointValChange = (e) => {
    const { value } = e.target;

    const regex = /^\d{1,8}(\.\d{0,8})?(-\d{0,8}(\.\d{0,8})?)?$/;

    if (regex.test(value) || value === "") {
      setPointVal({ ...pointVal, value: value });
    }
  };
  const handleDescChange = (desc) => {
    setPointVal({ ...pointVal, desc: desc });
  };

  useEffect(() => {
    setDescription("");
    setShowDesc(false);
  }, [pointVal]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2 justify-between font-roboto">
        <div
          className={`flex flex-row items-center justify-between w-full p-3 bg-base border border-solid  rounded-xl border-storm-10
          `}
        >
          <div className=" flex items-center justify-start gap-3 w-full">
            {onDelete && (
              <button
                onClick={onDelete}
                className=" border-none bg-none outline-none flex p-0"
              >
                <RemoveSvg />
              </button>
            )}

            <span className={` min-w-fit font-robotoMed text-sm ${titleColor}`}>
              {title}
            </span>

            <input
              className="border-none outline-none flex w-full text-left"
              onChange={handlePointValChange}
              value={pointVal?.value || ""}
              placeholder="$0.00 - $0.00"
              type="text"
              onBlur={handleOnBlurPointInput}
            />
          </div>
          {pointVal?.rr && <div className="btn-ocean">R:R {pointVal.rr}</div>}
        </div>
        <button
          onClick={() => setShowDesc(!showDesc)}
          className="bg-base outline outline-solid outline-storm-20 rounded-xl flex p-2"
        >
          {pointVal?.desc === null || pointVal?.desc?.length === 0 ? (
            <MessageSvg color={showDesc ? "#007bff" : "#689"} />
          ) : (
            <img src={messageIcon} className="w-7 h-7 max-w-fit" alt="message icon" />
          )}
        </button>
      </div>
      {showDesc && (
        <AddDescription
          setDescription={setDescription}
          description={description}
          desc={pointVal?.desc}
          onChange={handleDescChange}
        />
      )}
    </div>
  );
};

export default Point;
