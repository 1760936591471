export const convertChartTypeCodeToName = (chartTypeList) => {
  if (chartTypeList) {
    const chartTypeNames = [];

    chartTypeList.forEach((item) => {
      switch (item) {
        case 0:
          chartTypeNames.push("Bars");
          break;
        case 1:
          chartTypeNames.push("Candles");
          break;
        case 9:
          chartTypeNames.push("Hollow Candles");
          break;
        case 2:
          chartTypeNames.push("Line");
          break;
        case 14:
          chartTypeNames.push("LineWithMarkers");
          break;
        case 15:
          chartTypeNames.push("Stepline");
          break;
        case 3:
          chartTypeNames.push("Area");
          break;
        case 16:
          chartTypeNames.push("HLC area");
          break;
        case 10:
          chartTypeNames.push("Baseline");
          break;
        case 13:
          chartTypeNames.push("Columns");
          break;
        case 12:
          chartTypeNames.push("High-low");
          break;
        case 8:
          chartTypeNames.push("Heiken Ashi");
          break;
        default:
          return chartTypeNames;
      }
    });

    return chartTypeNames;
  }
};
