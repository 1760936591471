import React from "react";
import { PairsSymbol } from "~/components/child/Symbols";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

const AnalysisHeader = ({ pairs, timeFrame, type, positionType }) => {
  return (
    <div className="flex justify-between items-center text-sm">
      <div className="flex items-center gap-1">
        <PairsSymbol
          pair={pairs.value}
          image={pairs.image}
          className="text-storm-80 font-robotoMed"
        />

        {timeFrame && <span className="text-storm-40">{timeFrame}</span>}

        <span className="text-sky-60 font-roboto">{type}</span>
      </div>

      {positionType && (
        <div
          className={` rounded-3xl py-2 px-4 font-roboto ${
            positionType.toLowerCase() === "long"
              ? "bg-twilight-30 text-twilight-70"
              : "bg-infrared-10 text-infrared-40"
          }`}
        >
          {changeWordFormatToTitleCase(positionType)}
        </div>
      )}
    </div>
  );
};

export default AnalysisHeader;
