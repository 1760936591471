import { useState, useEffect } from "react";
import { setupApi } from "../../api/dashboard/setupApi";

export const useVaultsList = () => {
  const [vaultsList, setVaultsList] = useState([]);

  useEffect(() => {
    const fetchPairsList = async () => {
      try {
        const data = await setupApi.vaultsList();
        setVaultsList(data);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    fetchPairsList();
  }, []);

  return vaultsList.data;
};
