import { callRestGet, callRestPut } from "../../utils/callRestApi";

export const usersApi = {
  list: async (allUsers) => {
    const result = { data: null, error: null };
    await callRestGet(`dashboard/users${allUsers ? "/all" : ""}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },

  single: async (id) => {
    const result = { data: null, error: null };
    await callRestGet(`dashboard/users/${id}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },

  userAnalytics: async (id, page) => {
    const result = { data: null, error: null };
    await callRestGet(`dashboard/users/technicals/${id}?page=${page}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  profile: async () => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/profile`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  editProfile: async (id, body) => {
    const result = { data: null, error: null };
    await callRestPut(`/dashboard/profile/${id}`, body, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
