import React from "react";

function SendSvg({ color, className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.40995 21.7501C4.28995 21.7501 3.57995 21.3701 3.12995 20.9201C2.24995 20.0401 1.62995 18.1701 3.60995 14.2001L4.47995 12.4701C4.58995 12.2401 4.58995 11.7601 4.47995 11.5301L3.60995 9.80014C1.61995 5.83014 2.24995 3.95014 3.12995 3.08014C3.99995 2.20014 5.87995 1.57014 9.83995 3.56014L18.3999 7.84014C20.5299 8.90014 21.6999 10.3801 21.6999 12.0001C21.6999 13.6201 20.5299 15.1001 18.4099 16.1601L9.84995 20.4401C7.90995 21.4101 6.46995 21.7501 5.40995 21.7501ZM5.40995 3.75014C4.86995 3.75014 4.44995 3.88014 4.18995 4.14014C3.45995 4.86014 3.74995 6.73014 4.94995 9.12014L5.81995 10.8601C6.13995 11.5101 6.13995 12.4901 5.81995 13.1401L4.94995 14.8701C3.74995 17.2701 3.45995 19.1301 4.18995 19.8501C4.90995 20.5801 6.77995 20.2901 9.17995 19.0901L17.7399 14.8101C19.3099 14.0301 20.1999 13.0001 20.1999 11.9901C20.1999 10.9801 19.2999 9.95014 17.7299 9.17014L9.16995 4.90014C7.64995 4.14014 6.33995 3.75014 5.40995 3.75014Z"
        fill={color || "#007BFF"}
      />
      <path
        d="M10.8399 12.75H5.43994C5.02994 12.75 4.68994 12.41 4.68994 12C4.68994 11.59 5.02994 11.25 5.43994 11.25H10.8399C11.2499 11.25 11.5899 11.59 11.5899 12C11.5899 12.41 11.2499 12.75 10.8399 12.75Z"
        fill={color || "#007BFF"}
      />
    </svg>
  );
}

export default SendSvg;
