
export const createEndpointQueryParams = (data)=> Object.keys(data)
?.map((key) => {
  const value = data[key];
  if (typeof value === "object") {
    return Object.keys(value)
      ?.map(
        (subKey) =>
          `${encodeURIComponent(`${key}[${subKey}]`)}=${encodeURIComponent(
            value[subKey]
          )}`
      )
      .join("&");
  } else {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }
})
.join("&");