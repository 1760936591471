export const newHeatMapStepValidation = (
  newHeatMapStepValues,
  errorMessage,
  isLoading
) => {
  return (
    newHeatMapStepValues?.title.trim() === "" ||
    newHeatMapStepValues?.analysisType === undefined ||
    newHeatMapStepValues?.analysisType?.length === 0 ||
    !newHeatMapStepValues?.date?.to ||
    !newHeatMapStepValues?.date?.from ||
    errorMessage.details ||
    isLoading
  );
};

export const detailsStepValidation = (detailsStepValues, analysisTypes) => {
  return (
    (analysisTypes?.technical &&
      (!detailsStepValues.technical?.pair?.key ||
        detailsStepValues.technical?.timeFrames?.length === 0 ||
        detailsStepValues.technical?.positionType?.length === 0 ||
        detailsStepValues.technical?.technicalType?.length === 0)) ||
    (analysisTypes?.fundamental &&
      detailsStepValues.fundamental?.pairs?.length === 0) ||
    (analysisTypes?.onChain &&
      detailsStepValues.onChain?.pairs?.length === 0)
  );
};
