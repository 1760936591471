import React from "react";

function ArrowTopSvg({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
    >
      <path
        stroke={color || "#C2CFD6"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 7l6-6 6 6"
      ></path>
    </svg>
  );
}

export default ArrowTopSvg;
