import React from "react";

import Symbols from "~/components/child/Symbols";
import Dropdown from "~/components/child/ui/DropDown";
import InputBase from "~/components/child/ui/InputBase";

import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";

export const FirstStep = ({ vmcData, setVmcData }) => {
  const intervals = [
    "5M",
    "15M",
    "30M",
    "1H",
    "2H",
    "4H",
    "8H",
    "1D",
    "1W",
    "1M",
  ];
  return (
    <>
      <p className="text-base text-oil-60">
        Enter the information to show the chart
      </p>
      <Symbols
        multipleValues
        value={
          vmcData?.inputData?.pairs?.length > 0
            ? vmcData?.inputData?.pairs
            : undefined
        }
        onSelect={(item) => {
          if (Array.isArray(item))
            setVmcData((prevState) => ({
              ...prevState,
              inputData: {
                ...prevState?.inputData,
                pairs: item,
              },
            }));
          else
            setVmcData((prevState) => ({
              ...prevState,
              inputData: {
                ...prevState?.inputData,
                pairs: onChangeCheckboxes([...vmcData?.inputData?.pairs], {
                  key: item.key,
                  value: item.value,
                }),
              },
            }));
        }}
      />
      <Dropdown
        multipleValues
        allOption={true}
        title="Time frame"
        maxItems={3}
        value={
          vmcData?.inputData?.intervals?.length > 0
            ? vmcData?.inputData?.intervals
            : undefined
        }
        optionsList={intervals?.map((timeFrame) => ({
          key: timeFrame,
          value: timeFrame,
        }))}
        renderItem={(item) => (
          <span className="py-2 text-xs font-roboto w-full text-left">
            {item.value}
          </span>
        )}
        onSelect={(item) => {
          if (Array.isArray(item))
            setVmcData((prevState) => ({
              ...prevState,
              inputData: {
                ...prevState?.inputData,
                intervals: [...item],
              },
            }));
          else {
            setVmcData((prevState) => ({
              ...prevState,
              inputData: {
                ...prevState?.inputData,
                intervals: onChangeCheckboxes(
                  [...vmcData?.inputData?.intervals],
                  {
                    key: item.key,
                    value: item.value,
                  }
                ),
              },
            }));
          }
        }}
      />
      <InputBase
        title="Moving average candles"
        value={vmcData.inputData.movingAverageCandles}
        setValue={(e) =>
          setVmcData({
            ...vmcData,
            inputData: {
              ...vmcData.inputData,
              movingAverageCandles: e.target.value,
            },
          })
        }
        className={{ input: "!text-left !w-[95%]" }}
      />
      <InputBase
        title="Standard duration"
        value={vmcData.inputData.standardDuration}
        setValue={(e) =>
          setVmcData({
            ...vmcData,
            inputData: {
              ...vmcData.inputData,
              standardDuration: e.target.value,
            },
          })
        }
        className={{ input: "!text-left !w-[95%]" }}
      />
    </>
  );
};
