import React from "react";
import { usersProfileColor } from "utils/usersProfileColor";

export const AvatarGroup = ({ avatars, maxVisible }) => {
  const visibleAvatars = avatars?.slice(0, maxVisible);
  const extraAvatarsCount = avatars?.length - maxVisible;

  return (
    <div className="flex text-storm-10 text-xs font-normal font-roboto -space-x-4">
      {visibleAvatars?.map((avatar, index) => (
        <div
          key={index}
          className={`w-6 h-6 flex items-center justify-center rounded-full border-[1.5px] border-storm-10 ${usersProfileColor(
            index
          )}`}
        >
          {avatar.value.charAt(0)?.toUpperCase()}
        </div>
      ))}
      {extraAvatarsCount > 0 && (
        <div className="w-6 h-6 rounded-full border-[1.5px] border-storm-10 bg-infrared-30 flex items-center justify-center">
          +{extraAvatarsCount}
        </div>
      )}
    </div>
  );
};
