import { validateInput } from "../../helpers/tools/validateInput";
import {
  initializeChartSource,
  extractPanesFromChartSources,
  extractPaneTypes,
  extractWidgetAndPriceStudies,
  removeMainSeriesAndStudyVolume,
  appendSanitizedSourcesToChartSource,
  appenNonPriceStudyPanesToChartSource,
  extractPriceStudyIds,
  appendPriceStudyIdsToRightAxisesState,
  setCandlestickChartType,
} from "./dependentModules";

export function combineAllChartsSources(chartSourceList = []) {
  validateInput(chartSourceList, 'chartSourceList', 'combineAllChartsSources')
  let chartSource = initializeChartSource(chartSourceList);


  const panesList = extractPanesFromChartSources(chartSourceList);

  const { priceStudyAndWidgetPanes, nonPriceStudyPanes } =
    extractPaneTypes(panesList);

  const widgetAndPriceStudySources = extractWidgetAndPriceStudies(
    priceStudyAndWidgetPanes
  );
  
  const sanitizedWidgetAndPriceStudyList = removeMainSeriesAndStudyVolume( 
    widgetAndPriceStudySources
  );

  chartSource = appendSanitizedSourcesToChartSource(
    chartSource,
    sanitizedWidgetAndPriceStudyList
  );

  chartSource = appenNonPriceStudyPanesToChartSource(
    chartSource,
    nonPriceStudyPanes
  );

  const studyPriceIdList = extractPriceStudyIds(chartSource);

  chartSource = appendPriceStudyIdsToRightAxisesState(
    chartSource,
    studyPriceIdList
  );

  const finalChartSource = setCandlestickChartType(chartSource);

  return finalChartSource;
}
