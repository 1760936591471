import React from "react";

import Accordion from "./ui/Accordion";

import RemoveSvg from "../../assets/icon/svgJsx/RemoveSvg";
import EditSvg from "../../assets/icon/svgJsx/EditSvg";

const FundamentalEntry = ({
  id,
  label,
  date,
  description,
  analyser,
  onDelete,
  onEdit,
}) => {
  return (
    <div key={id} className="fundamentalEntry">
      <Accordion
        label={{
          leftPart: (
            <div className="fundamentalEntry-leftPart">
              {onDelete && (
                <button onClick={onDelete}>
                  <RemoveSvg />
                </button>
              )}

              {label && (
                <label className="fundamentalEntry-leftPart-label">
                  {label}
                </label>
              )}
              <span className="fundamentalEntry-leftPart-date">{date}</span>
              {analyser && (
                <span className="fundamentalEntry-leftPart-analyser">
                  {analyser}
                </span>
              )}
            </div>
          ),
          rightPart: (
            <div className="fundamentalEntry-rightPart">
              {onEdit && (
                <button onClick={onEdit}>
                  <EditSvg />
                </button>
              )}
            </div>
          ),
        }}
      >
        <div className="fundamentalEntry-box">{description}</div>
      </Accordion>
    </div>
  );
};

export default FundamentalEntry;
