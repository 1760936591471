import React from "react";

import ImagesPreview from "~/components/child/ui/imageUpload/Preview";
import UploadImage from "~/components/child/ui/imageUpload/Upload";

export const UploadAnalysisPhoto = ({ onAddImage, onRemove, onSetAsPrimary, images }) => {
  return (
    <div className="flex flex-col gap-4 mt-6">
      <UploadImage
        information="Add photo (maximum 5 photos)"
        onAddNewImage={(e) => onAddImage(e)}
        className={{
          form: "!p-3",
          info: "flex !flex-row items-center !justify-start ml-1.5 text-sm font-roboto text-sky-ky",
          svg: "w-[1.125rem] h-[0.875rem]",
        }}
      />

      <ImagesPreview
        onRemove={(id) => onRemove(id)}
        onSetAsPrimary={(id) => onSetAsPrimary(id)}
        images={images}
        className={{
          info: "text-sm !gap-2",
          image: "w-16 h-16",
          actions: "!items-end !gap-2 flex-col",
          primary: { label: " text-[0.625rem]", icon: "w-5 h-5" },
        }}
      />
    </div>
  );
};
