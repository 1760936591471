import React from "react";

import DatePicker from "react-multi-date-picker";
import CustomInput from "./CustomInput";

function DatePickerCustm({
  emitDatePicker,
  date,
  inputLabel,
  forcible,
  position,
  className,
  minDate,
  maxDate,
}) {
  const weekDays = ["Sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  return (
    <div className={`date-picker-one ${className}`}>
      <DatePicker
        onOpenPickNewDate={false}
        className="custom-container-one color-cstm"
        format="MM/DD/YYYY"
        popperPlacement="right"
        calendarPosition={position ? position : "right-top"}
        monthYearSeparator=" "
        showOtherDays
        render={
          <CustomInput
            placeholder={"MM/DD/YYYY"}
            forcible={forcible}
            label={inputLabel}
          />
        }
        onChange={(value) => {
          emitDatePicker(value);
        }}
        arrow={false}
        weekDays={weekDays}
        value={date || undefined}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default DatePickerCustm;
