import { useState, useEffect } from "react";
import { pairsApi } from "../../api/dashboard/pairsApi";

export const usePairsList = () => {
  const [pairsList, setPairsList] = useState([]);

  useEffect(() => {
    const fetchPairsList = async () => {
      try {
        const data = await pairsApi.pairs();
        setPairsList(data);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    fetchPairsList();
  }, []);

  return pairsList.data;
};
