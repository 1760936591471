import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AvatarGroup } from "./AvatarGroup";
import { usersProfileColor } from "utils/usersProfileColor";
import NotificationSvg from "~/assets/icon/svgJsx/NotificationSvg";
import disabledNotificationSvg from "~/assets/icon/disabledNotification.svg";

export const NotificationStatus = ({ users }) => {
  const isEnable = users?.length > 0;
  return (
    <>
      <div className="flex items-center gap-0.5 text-storm-key font-roboto text-base mr-2.5">
        {isEnable ? (
          <NotificationSvg color="#668899" width={20} height={20} />
        ) : (
          <img
            src={disabledNotificationSvg}
            alt="disabled notification"
            className="w-5 h-5"
          />
        )}
        Notification is {isEnable ? "enable" : "disable"}
        {isEnable && (
          <span data-tooltip-id="notificationsDetail">
            <AvatarGroup avatars={users} maxVisible={4} />
          </span>
        )}
      </div>
      <ReactTooltip
        id="notificationsDetail"
        multiline={true}
        place="bottom-start"
        className="z-50"
        content={
          <div className=" py-2 flex flex-col gap-2 text-sm font-normal font-roboto text-oil-light">
            <span className="text-storm-light text-xs font-roboto">
              Send to:
            </span>
            {users?.map((user, index) => (
              <div key={index} className="flex items-center gap-2">
                <span
                  className={`w-6 h-6 items-center text-black ${usersProfileColor(
                    index
                  )} justify-center text-base rounded-full outline outline-storm-10 flex`}
                >
                  {user.value.charAt(0)?.toUpperCase()}
                </span>
                {user.value}
              </div>
            ))}
          </div>
        }
      />
    </>
  );
};
