import React from "react";

import Description from "~/components/child/ui/Description";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import { ReceiveNotification } from "~/components/child/notification/ReceiveNotification";
import { UploadAnalysisPhoto } from "~/components/child/UploadAnalysisPhoto";
import Point from "./Point";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { handlePointChange, handleDeletePoint } from "./helper";

const Details = ({
  analysisValues,
  setAnalysisValues,
  isEditMode,
  setDataHasChanged,
  setNotification,
  notification,
  handleOnBlurPointInput,
  errorMessage,
}) => {
  return (
    <>
      <div className="bg-storm-10 rounded-lg text-sky-70 flex flex-col gap-4 justify-between p-2">
        {!isEditMode && (
          <span>
            New {changeWordFormatToTitleCase(analysisValues.technical.type)} Technical
          </span>
        )}
        <AnalysisHeader
          pairs={analysisValues.new.pair}
          timeFrame={analysisValues.technical.timeFrame.value}
          positionType={analysisValues.new.positionType}
          type="Technical"
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            {analysisValues?.technical?.points?.ep?.map((epPoint, index) => (
              <Point
                key={`ep-${index}`}
                title={`EP${
                  analysisValues?.technical?.points?.ep?.length > 1 ? index + 1 : ""
                }${
                  index === 0 && analysisValues?.technical?.type === "setup" ? " *" : ""
                }`}
                titleColor="text-sky-key"
                pointVal={epPoint}
                setPointVal={(updatedPointVal) => {
                  handlePointChange(
                    updatedPointVal,
                    "ep",
                    index,
                    setAnalysisValues,
                    setDataHasChanged
                  );
                }}
                onDelete={
                  index !== 0
                    ? () => {
                        handleDeletePoint(
                          "ep",
                          index,
                          setAnalysisValues,
                          setDataHasChanged
                        );
                      }
                    : undefined
                }
                handleOnBlurPointInput={handleOnBlurPointInput}
              />
            ))}
            <button
              className="text-sky-40 font-roboto text-sm text-left"
              onClick={() => {
                setAnalysisValues((prevState) => {
                  const updatedPoints = { ...prevState.technical.points };
                  updatedPoints.ep = [...updatedPoints.ep, { value: "", desc: "" }];

                  return {
                    ...prevState,
                    technical: {
                      ...prevState.technical,
                      points: updatedPoints,
                    },
                  };
                });
              }}
            >
              + Add another EP
            </button>
          </div>
          <div className="flex flex-col gap-2">
            {analysisValues?.technical?.points?.tp?.map((tpPoint, index) => (
              <Point
                key={`tp-${index}`}
                title={`TP${
                  analysisValues?.technical?.points?.tp?.length > 1 ? index + 1 : ""
                }${
                  index === 0 && analysisValues?.technical?.type === "setup" ? " *" : ""
                }`}
                titleColor="text-leaf-key"
                pointVal={tpPoint}
                setPointVal={(updatedPointVal) => {
                  handlePointChange(
                    updatedPointVal,
                    "tp",
                    index,
                    setAnalysisValues,
                    setDataHasChanged
                  );
                }}
                onDelete={
                  index !== 0
                    ? () => {
                        handleDeletePoint(
                          "tp",
                          index,
                          setAnalysisValues,
                          setDataHasChanged
                        );
                      }
                    : undefined
                }
                handleOnBlurPointInput={handleOnBlurPointInput}
              />
            ))}
            <button
              className="text-sky-40 font-roboto text-sm text-left"
              onClick={() => {
                setAnalysisValues((prevState) => {
                  const updatedPoints = { ...prevState.technical.points };
                  updatedPoints.tp = [...updatedPoints.tp, { value: "", desc: "" }];
                  return {
                    ...prevState,
                    technical: {
                      ...prevState.technical,
                      points: updatedPoints,
                    },
                  };
                });
              }}
            >
              + Add another TP
            </button>
          </div>
          {analysisValues?.technical?.points?.sl && (
            <Point
              title={`SL${analysisValues?.technical?.type === "setup" ? " *" : ""}`}
              titleColor="text-infrared-key"
              pointVal={analysisValues?.technical?.points.sl[0]}
              setPointVal={(updatedPointVal) => {
                handlePointChange(
                  updatedPointVal,
                  "sl",
                  0,
                  setAnalysisValues,
                  setDataHasChanged
                );
              }}
              handleOnBlurPointInput={handleOnBlurPointInput}
              />
          )}
          <p className=" font-roboto text-sm text-infrared-key overflow-auto">
            {errorMessage && errorMessage}
          </p>
        </div>
      </div>
      {!isEditMode && (
        <ReceiveNotification
          className="mt-4 bg-storm-10 border-none w-auto"
          users={[...analysisValues?.technical?.notificationUsers]}
          onClickHandler={() =>
            setNotification({ ...notification, modal: !notification.modal })
          }
          switchIsOn={notification.switch}
          setSwitchIsOn={() =>
            setNotification({ ...notification, switch: !notification.switch })
          }
        />
      )}
      <Description
        description={analysisValues?.technical?.description}
        setDescription={(updatedDesc) => {
          setAnalysisValues({
            ...analysisValues,
            technical: {
              ...analysisValues.technical,
              description: updatedDesc,
            },
          });
          setDataHasChanged(true);
        }}
        limitNumber={7000}
      />
      <UploadAnalysisPhoto
        onAddImage={(e) => {
          if (e.target.files[0])
            setAnalysisValues({
              ...analysisValues,
              technical: {
                ...analysisValues.technical,
                images: [
                  ...analysisValues.technical.images,
                  {
                    id:
                      analysisValues.technical.images.length > 0
                        ? analysisValues.technical.images[
                            analysisValues.technical.images.length - 1
                          ].id + 1
                        : 0,
                    name: e.target.files[0].name,
                    src: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    active: analysisValues.technical.images?.length === 0 ? 1 : 0,
                  },
                ],
              },
            });
          setDataHasChanged(true);
        }}
        onRemove={(id) => {
          setAnalysisValues({
            ...analysisValues,
            technical: {
              ...analysisValues.technical,
              images: analysisValues.technical.images.filter((image) => image.id !== id),
            },
          });
          setDataHasChanged(true);
        }}
        onSetAsPrimary={(id) => {
          setAnalysisValues({
            ...analysisValues,
            technical: {
              ...analysisValues.technical,
              images: analysisValues?.technical?.images?.map((image) => ({
                ...image,
                active: id === image.id ? 1 : 0,
              })),
            },
          });
          setDataHasChanged(true);
        }}
        images={analysisValues.technical.images}
      />
    </>
  );
};

export default Details;
