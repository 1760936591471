import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { validateInput } from "../../../utils/helpers/tools/validateInput";



export const convertDateToUnix = (entry) => {
    validateInput(entry, 'entry', 'convertDateToUnix')
    const dateArray = entry?.date.split("/");
    const date = `${dateArray[2]}-${dateArray[0]}-${
      dateArray[1] < 10 ? "0" + dateArray[1] : dateArray[1]
    }`;

    const resulte = convertDateToUnixTime(1, date);
    return resulte;
  }

  export const processChartData = (fundChartData, interval) => {
    const functionName = processChartData.name;
    validateInput(fundChartData, 'fundChartData', functionName)
    validateInput(interval, 'interval', functionName)
   return setIntervalChartSource(fundChartData, interval);
  }