import React from "react";
import { usePairsList } from "~/utils/hooks";
import Dropdown from "~/components/child/ui/DropDown";

export const PairsSymbol = ({
  image,
  pair,
  multipleValues = false,
  className,
}) => {
  return (
    <div className={`flex items-center text-sm gap-1 font-roboto ${className}`}>
      {image && image?.length > 0 && (
        <div
          className={`relative flex flex-col  ${
            multipleValues ? "h-5 w-5" : "w-10 h-10"
          }`}
        >
          <img
            className={`absolute ${
              multipleValues ? "h-[0.875rem] w-[0.875rem]" : "w-5 h-5"
            }  right-1 top-[2px] rounded-full border-[2px] border-oil-10`}
            src={image[1]}
            alt=""
          />
          <img
            className={`absolute ${
              multipleValues ? "h-[0.875rem] w-[0.875rem]" : "w-5 h-5"
            } right-3 top-3 rounded-full border-[2px] border-oil-10`}
            src={image[0]}
            alt=""
          />
        </div>
      )}
      {pair}
    </div>
  );
};

const Symbols = ({
  optionsList,
  value,
  onSelect,
  editable = true,
  placeholder,
  multipleValues = false,
  shouldShowSelectedValues = true,
  hasLabel = true,
  onDeleteOption,
  allOption = false,
}) => {
  const pairsList = usePairsList()?.data;

  return (
    <Dropdown
      onDeleteOption={onDeleteOption}
      multipleValues={multipleValues}
      shouldShowSelectedValues={shouldShowSelectedValues}
      searchable
      editable={editable}
      title={hasLabel ? "Symbol" : undefined}
      allOption={allOption}
      optionsList={
        optionsList
          ? optionsList
          : pairsList &&
            pairsList?.map((pair) => ({
              key: pair.key,
              value: pair.pair,
              image: pair.image,
              baseSymbol: pair.base_symbol,
              quoteSymbol: pair.quote_symbol,
              exchange: pair.exchange,
            }))
      }
      placeholder={placeholder}
      value={value}
      renderItem={(item) => (
        <span className="min-h-8 w-full flex items-center justify-start text-left">
          <PairsSymbol image={item?.image} pair={item.value} />
        </span>
      )}
      onSelect={(item) => onSelect(item)}
    />
  );
};
export default Symbols;
