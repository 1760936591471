import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { setThemeChartSource } from "../../../utils/chartSourceModifiers/setThemeChartSource";
import { convertChartTimeZone } from "../../../utils/converters/convertChartTimeZone";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { validateInput } from "../../../utils/helpers/tools/validateInput";

export const processChartData = (initChartData, interval, theme) => {
  const functionName = processChartData.name
  validateInput(initChartData, 'initChartData', functionName)
  validateInput(interval, 'interval', functionName)
  validateInput(theme, 'theme', functionName)  

  const parsedChartData = JSON.parse(JSON.parse(initChartData));
  const modifyChartDataInterval = setIntervalChartSource(parsedChartData, interval) ?? null;
  const modifyChartDataTheme = setThemeChartSource(modifyChartDataInterval, theme);
  return convertChartTimeZone(modifyChartDataTheme, 1);
};
