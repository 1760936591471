import React from "react";

function StrongBoxSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g stroke="#007BFF" strokeWidth="1.5" opacity="0.9">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"
        ></path>
        <g strokeMiterlimit="10" opacity="0.4">
          <path d="M14.35 10.91c-.1.71-.52 1.31-1.1 1.67v1.98a1.25 1.25 0 01-2.5 0v-1.98c-.58-.36-1-.96-1.1-1.67-.02-.11-.03-.23-.03-.35 0-1.52 1.44-2.7 3.02-2.28.8.21 1.45.86 1.66 1.66.09.33.1.66.05.97zM22 10.91h-7.65M9.65 10.91H2"></path>
        </g>
      </g>
    </svg>
  );
}

export default StrongBoxSvg;