import React, { useState, useRef, useEffect } from "react";
import InputBase from "./InputBase";
import ArrowTopSvg from "~/assets/icon/svgJsx/ArrowTopSvg";
import ArrowDownSvg from "~/assets/icon/svgJsx/ArrowDownSvg";
import RemoveSvg from "~/assets/icon/svgJsx/RemoveSvg";

const OptionItem = ({
  handleOptionClick,
  onSelect,
  multipleValues,
  option,
  value,
  renderItem,
  length,
  isAllOption = false,
}) => {
  const isChecked = isAllOption
    ? value?.length === length
    : value?.length > 0 && value?.some((obj) => obj.key === option.key);

  return (
    <li
      key={option.key}
      onClick={(e) => {
        handleOptionClick(option, e);
        onSelect(option);
      }}
      className="flex py-1 cursor-pointer"
    >
      {multipleValues && length > 1 && (
        <input
          className="mr-1"
          checked={isChecked}
          type="checkbox"
          onChange={(e) => {
            onSelect({ ...option });
          }}
        />
      )}
      {renderItem(option)}
    </li>
  );
};

const Dropdown = ({
  optionsList,
  allOption = false,
  renderItem,
  placeholder,
  value,
  bgColor,
  editable = true,
  title,
  searchable = false,
  shouldShowSelectedValues = true,
  multipleValues = false,
  onDeleteOption,
  maxItems = 3,
  onSelect,
  className,
  inputStartAdornment,
  renderCustomOptionsList,
  optionsListEndAdornment,
  alwaysOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(alwaysOpen);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef(null);
  const handleOptionClick = (option, event) => {
    if (!multipleValues) {
      setIsOpen(alwaysOpen || false);
    }

    event?.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(alwaysOpen || false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!isOpen) setSearchTerm("");
  }, [isOpen]);

  useEffect(() => {
    if (renderCustomOptionsList) setIsOpen(alwaysOpen || false);
  }, [value]);

  const filteredOptions =
    searchTerm !== ""
      ? optionsList
          ?.filter((option) =>
            option?.value?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )
          ?.sort((a, b) => {
            if (a.value.toLowerCase().startsWith(searchTerm.toLowerCase())) {
              return -1;
            } else if (
              b.value.toLowerCase().startsWith(searchTerm.toLowerCase())
            ) {
              return 1;
            } else {
              return 0;
            }
          })
      : optionsList;

  return (
    <div className={` w-full relative ${className}`} ref={dropdownRef}>
      <InputBase
        className={{ root: `bg-${bgColor} mb-1` }}
        children={
          <div
            className=" flex items-center justify-between"
            onClick={(e) => {
              if (editable) {
                e.preventDefault();
                setIsOpen(alwaysOpen || !isOpen);
              }
            }}
          >
            <div className="flex items-center gap-3">
              {inputStartAdornment}
              {value && shouldShowSelectedValues ? (
                multipleValues && Array.isArray(value) && value?.length > 0 ? (
                  <div className="flex my-[3px] flex-wrap gap-2 items-center">
                    {Array.isArray(value) &&
                      value?.slice(0, maxItems)?.map((item) => {
                        return (
                          <div key={item.key}>
                            <div className="px-2 text-storm-50 flex items-center gap-2 text-xs rounded-3xl bg-storm-20">
                              {onDeleteOption && (
                                <button
                                  onClick={(e) => {
                                    onDeleteOption(item);

                                    if (isOpen) setIsOpen(alwaysOpen || false);

                                    e.stopPropagation();
                                  }}
                                >
                                  <RemoveSvg className="min-w-4" />
                                </button>
                              )}
                              {renderItem(item)}
                            </div>
                          </div>
                        );
                      })}
                    {Array.isArray(value) && value?.length > maxItems && (
                      <span className="py-2 px-2 text-storm-50 bg-storm-20 text-xs rounded-3xl">
                        +{value?.length - maxItems} more
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center min-h-9">
                    {renderItem(value)}
                  </div>
                )
              ) : placeholder ? (
                placeholder
              ) : (
                <div className=" min-h-9" />
              )}
            </div>
            {editable && (
              <i>
                {isOpen ? <ArrowTopSvg color="#002247" /> : <ArrowDownSvg />}
              </i>
            )}
          </div>
        }
        title={title}
      />

      {isOpen && (
        <div
          className={` bg-${
            bgColor || "base"
          } bg-base border border-storm-20 rounded-2xl w-full overflow-x-hidden absolute bottom-1 translate-y-full z-10  `}
        >
          <ul className="scrollbar-style scrollbar-style-thumb max-h-96 overflow-y-auto overflow-x-hidden px-2 my-2.5 mx-1">
            {searchable && (
              <li
                className={`flex py-1 mt-2 sticky top-0 bg-${
                  bgColor || "base"
                } z-10`}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={`border bg-${bgColor}  rounded-2xl border-oil-40 py-2 px-3 w-11/12 font-roboto text-sm`}
                />
              </li>
            )}
            {allOption && searchTerm.length === 0 && (
              <OptionItem
                option={{ key: "all", value: "All" }}
                value={value}
                isAllOption={true}
                onSelect={() => {
                  if (Array.isArray(value) && value?.length > 0) {
                    onSelect([]);
                  } else {
                    onSelect(
                      optionsList.map((option) => ({
                        key: option.key,
                        value: option.value,
                      }))
                    );
                  }
                }}
                multipleValues={multipleValues}
                renderItem={renderItem}
                handleOptionClick={handleOptionClick}
                length={optionsList?.length}
              />
            )}

            {renderCustomOptionsList ||
              filteredOptions?.map((option) => (
                <OptionItem
                  key={option.key}
                  option={option}
                  value={value}
                  onSelect={onSelect}
                  multipleValues={multipleValues}
                  renderItem={renderItem}
                  handleOptionClick={handleOptionClick}
                  length={optionsList.length}
                />
              ))}
            {optionsListEndAdornment && optionsListEndAdornment}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
