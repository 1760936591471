import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/fullChart/TVChartContainer";
import { Loading } from "~/components/child/ui/Loading";

import { useMainChartDetails } from "~/utils/hooks";
import { useFullChartNearRealTimeUpdater } from "./helper";

const FullChart = () => {
  const {
    isLoading,
    chartData,
    setChartData,
    theme, 
    setTheme,
    favorites,
    setFavorites,  
    layoutList,
    setLayoutList,
    templateList, 
    setTemplateList,
  } = useMainChartDetails();

  useFullChartNearRealTimeUpdater();


  return (
    <div className={`${chartSize?.fullScreen}`}>
      {!isLoading &&
      chartData &&
      theme &&
      favorites &&
      layoutList &&
      templateList ? (
        <TVChartContainer
          chartData={chartData}
          setChartData={setChartData}
          theme={theme}
          setTheme={setTheme}
          favorites={favorites}
          setFavorites={setFavorites}
          layoutList={layoutList}
          setLayoutList={setLayoutList}
          templateList={templateList}
          setTemplateList={setTemplateList}
        />
      ) : !isLoading ? (
        <DataFailureAlert />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default FullChart;
