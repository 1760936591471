import { validateInput } from "../helpers/tools/validateInput";

export const setIntervalChartSource = (chartSource, resolution) => {

  validateInput(resolution, 'resolution or interaval', 'setIntervalChartSource')

  chartSource?.charts.forEach((chart) => {
    chart?.panes.forEach((pane) => {
      pane?.sources.forEach((source) => {
        const interval = source?.state?.interval;
        if (interval) {
          source.state.interval = resolution;
        }
      });
    });
  });
  return chartSource;
};
