import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { widget } from "~/charting_library";
import { WidgetCreatorClass } from "../../../utils/classes/widgetCreatorClass";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { createChartConfig } from "../../../config/createChartConfig";
import { processChartData } from "./helper";
import { getPairDataConfig } from "../../../utils/helpers/tools/getPairDataConfig";
import { validateInput } from "../../../utils/helpers/tools/validateInput";

import "../../index.css";

export const TVChartContainer = React.memo(
  ({
    tradeBoundariesPoints,
    pairDetail,
    initChartData,
    initInterval,
    createdAt,
    baseDateType,
    theme,
  }) => {
    
    const [interval, setInterval] = useState(
      convertIntervalToTradingViewFormat(initInterval)
    );

    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("technical");
    const { timeFrom, timeTo } = createChartTimeFrame(interval, createdAt.split(" ")[0]);
    
    const { pair, dataFeed: datafeedUrl } = getPairDataConfig(pairDetail, 'snake_case') 

    const finalChartData = processChartData(initChartData, interval, theme);


    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.detail?.disabledFeatures,
        enabled_features: specificConfigs?.detail?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrom, to: timeTo },
        saved_data: finalChartData,
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreatorClass(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
        
          const renderTradeBoundariesPoints = (points, type, createWidget) => {
            const functionName = renderTradeBoundariesPoints.name
            validateInput(points, 'points', functionName)
            validateInput(type, 'type', functionName)
            validateInput(createWidget, 'createWidget', functionName)  

            if (points && points.length > 0) {
              for (const point of points) {
                const shapeType = point?.max_amount === 0 ? "single" : "range";
                const widgetTimeRange = createTradeBoundariesWidgetTimeRange(
                  interval,
                  createdAt,
                  baseDateType
                );
      
                if (shapeType === "single") {
                  createWidget.trendLine(
                    point?.base_amount.toString(),
                    widgetTimeRange,
                    type
                  );
                }
                if (shapeType === "range") {
                  createWidget.rectangle(
                    [point?.base_amount.toString(), point?.max_amount.toString()],
                    widgetTimeRange,
                    type
                  );
                }
              }
            }
          };
          
          const tradeBoundariesPointsData = [
            { points: tradeBoundariesPoints?.ep, type: "ep" },
            { points: tradeBoundariesPoints?.sl, type: "sl" },
            { points: tradeBoundariesPoints?.tp, type: "tp" },
          ];

          tradeBoundariesPointsData?.forEach(({ points, type }) =>
            renderTradeBoundariesPoints(points, type, createWidget)
          );

          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [finalChartData, interval]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);


TVChartContainer.propTypes = {
  tradeBoundariesPoints: PropTypes.object.isRequired,
  pairDetail: PropTypes.object.isRequired,
  initChartData: PropTypes.string.isRequired,
  initInterval: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  baseDateType: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};
