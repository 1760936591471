import React, { useState, useEffect } from "react";

import { setupApi } from "~/api/dashboard/setupApi";

import Modal from "~/components/child/ui/Modal";
import Button from "~/components/child/ui/Button";
import { Loading } from "~/components/child/ui/Loading";
import { Node } from "~/components/child/history/Node";

import { useKeySubmission } from "~/utils/hooks";
import { statusesTheme } from "~/utils/statusesTheme";
import { getState } from "../helper";

export const ChangeStateConfirm = ({
  setShowConfirmModal,
  id,
  setIsStatusChanged,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const changeSetupState = (id) => {
    setIsLoading(true);
    setErrorMessage(undefined);
    setupApi
      .changeState(id)
      .then((res) => {
        if (res && !res.error && res.data) {
          setShowConfirmModal(false);
          setIsStatusChanged(true);
        } else if (res?.error) {
          if (res?.error.response?.data?.errors) {
            setErrorMessage({
              ...errorMessage,
              create: res?.error?.response?.data.errors,
            });
          } else
            setErrorMessage({
              ...errorMessage,
              create: { error: [res?.error.response?.data.message] },
            });
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  useKeySubmission(
    () => changeSetupState(id),
    () => isLoading || errorMessage?.length > 0,
    (event) => event.key === "Enter",
    []
  );

  return (
    <Modal>
      <p className="text-soil-dark font-roboto text-base font-normal leading-snug m-0">
        You are changing the state of setup.
        <br />
        Are you sure?
      </p>
      <div className="flex items-center flex-row-reverse gap-4 justify-between">
        <Button
          disabled={isLoading || errorMessage?.length > 0}
          onClick={() => changeSetupState(id)}
          type="submit"
          variant="primary"
          className="w-full"
          isLoading={isLoading}
        >
          Yes, it's ok
        </Button>

        <Button
          onClick={() => {
            setErrorMessage("");
            setShowConfirmModal(false);
          }}
          variant="outline"
          className="w-full"
        >
          Cancel
        </Button>
      </div>
      {errorMessage && (
        <p className="profile__confirm__alert__error">{errorMessage}</p>
      )}
    </Modal>
  );
};

export const History = ({ id, isStatusChanged }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [historyDetails, setHistoryDetails] = useState();

  const fetchHistory = async (id) => {
    try {
      const result = await setupApi.stateHistory(id);
      setHistoryDetails(Object.values(result?.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (id) fetchHistory(id);
  }, [id, isStatusChanged]);

  return (
    <>
      {historyDetails?.length > 0 && !isLoading ? (
        <div>
          <div
            className={`${
              historyDetails.length !== 2 ? "node" : ""
            } flex-col gap-2 flex items-center relative w-full`}
          >
            <Node
              details={
                <span className="text-sm font-roboto text-sky-key">
                  {historyDetails[historyDetails.length - 1]}
                </span>
              }
              title={
                <span
                  className={`${statusesTheme(
                    getState(historyDetails[historyDetails.length - 2])
                  )} py-1 px-2.5 m-auto flex w-fit rounded-3xl border text-sm`}
                >
                  {historyDetails[historyDetails.length - 2]}
                </span>
              }
              uuid={id}
              isActive={historyDetails.length === 2}
            />

            {historyDetails.length > 2 &&
              historyDetails
                ?.slice(0, -2)
                .map((item, index) => (
                  <Node
                    key={index}
                    details={
                      <span className="text-sm font-roboto text-sky-key">
                        {item.created_at}
                      </span>
                    }
                    title={
                      <span
                        className={`${statusesTheme(
                          getState(item.state)
                        )} py-1 px-2.5 m-auto flex w-fit rounded-3xl border text-sm`}
                      >
                        {item.state}
                      </span>
                    }
                    uuid={id}
                    isActive={index === (historyDetails.length - 3)}
                  />
                ))}
          </div>
        </div>
      ) : (
        isLoading && <Loading size="small" />
      )}
    </>
  );
};
