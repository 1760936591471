import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { authApi } from "~/api/auth/authApi";

import { useKeySubmission } from "~/utils/hooks";

import EyeHiddenSvg from "~/assets/icon/svgJsx/EyeHiddenSvg";
import EyeShowSvg from "~/assets/icon/svgJsx/EyeShowSvg";

const Login = () => {
  const [showHiddenPassword, setShowHiddenPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const submitForm = (e) => {
    e?.preventDefault();
    setIsLoading(true);
    const body = {
      email: userName,
      password: password,
    };
    authApi
      .login(body)
      .then((res) => {
        if (res && !res.error && res?.data) {
          localStorage.setItem("accessToken", res?.data.meta[0].access_token);
          localStorage.setItem("refreshToken", res?.data.meta[0].refresh_token);
          localStorage.setItem(
            "accessTokenExpiration",
            res?.data.meta[0].access_token_expiration
          );
          localStorage.setItem(
            "refreshTokenExpiration",
            res?.data.meta[0].refresh_token_expiration
          );

          window.location.href = "/";
        } else if (res?.error) {
          setErrorMessage(res?.error.response?.data.message);
          setLoginAttempts(loginAttempts + 1);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const isLoginButtonDisable = () => {
    return (
      errorMessage?.length > 0 ||
      isLoading ||
      userName?.length === 0 ||
      password?.length === 0 ||
      (!captchaVerified && loginAttempts > 2)
    );
  };

  useKeySubmission(
    submitForm,
    isLoginButtonDisable,
    (event) => event.key === "Enter",
    []
  );

  return (
    <div className="login-cstm">
      <aside className="login-cstm__aside">
        <div className="login-cstm__aside__title">
          <h3>Trade Assistant</h3>
        </div>

        <div className="login-cstm__aside__box-form">
          <div className="login-cstm__aside__form">
            <div className="login-cstm__aside__form__title">
              <h4>Login to your account</h4>
            </div>
          </div>

          <form onSubmit={(e) => submitForm(e)}>
            <div className="select-cstm-one login-cstm__aside__input">
              <div className="select-cstm-one__title login-cstm__aside__input__title">
                Username
              </div>
              <input
                onChange={(e) => {
                  if (errorMessage?.length > 0) setErrorMessage("");
                  setUsername(e.target.value);
                }}
                type="text"
                name="username"
              />
            </div>

            <div className="select-cstm-one login-cstm__aside__input">
              <div className="select-cstm-one__title login-cstm__aside__input__title">
                Password
              </div>

              <input
                className="password"
                onChange={(e) => {
                  if (errorMessage?.length > 0) setErrorMessage("");
                  setPassword(e.target.value);
                }}
                style={{ fontSize: showHiddenPassword ? "40px" : "unset" }}
                type={showHiddenPassword ? "password" : "text"}
                name="password"
              />

              <div
                onClick={() => setShowHiddenPassword(!showHiddenPassword)}
                className="login-cstm__aside__input__password"
              >
                {showHiddenPassword ? <EyeShowSvg /> : <EyeHiddenSvg />}
              </div>
            </div>
            {loginAttempts > 2 && (
              <div className="login-cstm__aside__captcha">
                <ReCAPTCHA
                  sitekey="6Lf-0OMpAAAAAFkR2szwSvtdmD_vEgjwU0xgItKC"
                  onChange={handleCaptchaVerify}
                  onExpired={() => {
                    // Set the captcha verified flag to false when the captcha expires
                    setCaptchaVerified(false);
                  }}
                />
                {captchaVerified ? (
                  <p>Captcha verified! Proceeding with login.</p>
                ) : (
                  <p>Please complete the captcha to continue.</p>
                )}
              </div>
            )}

            <button
              disabled={isLoginButtonDisable()}
              className="select-cstm-one login-cstm__aside__form-button"
              tabIndex={0}
            >
              Login
            </button>
            {errorMessage && <p className="login-cstm__aside__error">{errorMessage}</p>}
          </form>
        </div>
      </aside>

      <div className="login-cstm__wallpaper"></div>
    </div>
  );
};

export default Login;
