import React, { useState, useEffect } from "react";

import { technicalApi } from "../../../api/dashboard/analysis/technicalApi";

import { Loading } from "../ui/Loading";
import { Node } from "./Node";

export const AnalysisHistory = ({ id, activeAnalysis, setActiveAnalysis }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [historyDetails, setHistoryDetails] = useState();

  const fetchEditHistory = async () => {
    try {
      const result = await technicalApi.editHistory(id);
      setHistoryDetails(result?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (id) fetchEditHistory();
  }, [id]);

  return (
    <>
      {historyDetails && !isLoading ? (
        <div>
          <div className="node flex-col gap-2 flex items-center relative w-full">
            <Node
              details={
                <button
                  className="text-sky-key font-roboto"
                  onClick={() => {
                    setActiveAnalysis(id);
                  }}
                >
                  {activeAnalysis === id ? "Showing" : "Show"}
                </button>
              }
              title="Current version"
              uuid={id}
              isActive={activeAnalysis === id}
            />
            {historyDetails?.delete_log.length > 0 && (
              <>
                <Node
                  title={`${historyDetails.delete_log.length} previous version`}
                  isActive={null}
                />
                {historyDetails.delete_log.map((child, index) => {
                  const date = new Date(child.created_at);
                  const formattedDate =
                    date.toISOString().slice(0, 10) +
                    " - " +
                    date.toTimeString().slice(0, 8);
                  const isActive =
                    child.deleted_analytic.analysis.uuid === activeAnalysis;
                  return (
                    <Node
                      key={index}
                      title={formattedDate}
                      uuid={child.deleted_analytic.analysis.uuid}
                      isActive={isActive}
                      details={
                        <>
                          {isActive !== null && (
                            <button
                              className="text-sky-key font-roboto"
                              onClick={() => {
                                setActiveAnalysis(
                                  child.deleted_analytic.analysis.uuid
                                );
                              }}
                            >
                              {isActive ? "Showing" : "Show"}
                            </button>
                          )}
                        </>
                      }
                      supportingElement={
                        <div className="flex flex-col gap-1 text-storm-40 text-xs">
                          <h6>Edited Items</h6>
                          <p className="font-roboto">
                            {child?.difference?.map((item, index) => (
                              <span>
                                {item}
                                {index !== child?.difference?.length - 1 &&
                                  " . "}
                              </span>
                            ))}
                          </p>
                        </div>
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      ) : isLoading ? (
        <Loading size="small" />
      ) : (
        <p className="font-roboto text-sm text-storm-key">
          This analyse doesn't have any history.
        </p>
      )}
    </>
  );
};
