import React from "react";

function Tooltip({ children, className }) {
  return (
    <div
      className={`absolute right-[1.2%] shadow-[0_1px_8px_0_rgba(0,0,0,.122)] top-[75%] py-4 px-3 bg-storm-light rounded-lg w-1/12 ${className}`}
    >
      <ul className="flex flex-col gap-2 list-none m-0 p-0">
        {children?.map((item) => (
          <li
            onClick={() => {
              if (item?.onClickHandler) item.onClickHandler();
            }}
            className="flex items-center gap-1"
          >
            {item?.elements}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tooltip;
