import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import ChartSquareSvg from "../../assets/icon/svgJsx/ChartSquareSvg";
import ArrowRightSvg from "../../assets/icon/svgJsx/ArrowRightSvg";
import RowVerticalSvg from "../../assets/icon/svgJsx/RowVerticalSvg";
import MapSvg from "../../assets/icon/svgJsx/MapSvg";
import NotificationSvg from "../../assets/icon/svgJsx/NotificationSvg";
import MessageTextSvg from "../../assets/icon/svgJsx/MessageTextSvg";
import ProfileSvg from "../../assets/icon/svgJsx/ProfileSvg";

export default function HeatMap({
  parentCategoryName,
  navBoxIsOpen,
  heatMapIsOpen,
  setHeatMapIsOpen,
  breadcrumbItems,
  user,
}) {
  const clickOnHeatMapItems = () => {
    if (!navBoxIsOpen) {
      setHeatMapIsOpen(false);
    }
  };

  const parentItemRef = useRef(null);
  const childListRefItem = useRef(null);
  const currentMainListOfNav = breadcrumbItems?.main === parentCategoryName;

  useEffect(() => {
    if (!navBoxIsOpen) {
      setHeatMapIsOpen(false);
    }
    if (navBoxIsOpen && currentMainListOfNav) {
      setHeatMapIsOpen(true);
    }

    const handleClickOutside = (event) => {
      if (!navBoxIsOpen) {
        if (
          parentItemRef.current &&
          childListRefItem.current &&
          !parentItemRef.current.contains(event.target) &&
          !childListRefItem.current.contains(event.target)
        ) {
          setHeatMapIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [parentItemRef, childListRefItem, navBoxIsOpen]);

  return (
    <li
      className={`nav__list__item itemClose ${heatMapIsOpen && "listOpen"} ${
        !navBoxIsOpen && currentMainListOfNav && "isActiveClose"
      }`}
    >
      <div
        ref={parentItemRef}
        onClick={() => setHeatMapIsOpen(!heatMapIsOpen)}
        className="nav__list__item__box"
      >
        <div className="nav__list__item__box__title">
          <i className="nav__list__item__box__icon">
            <MapSvg />
          </i>
          <span>{navBoxIsOpen && <div>{parentCategoryName}</div>}</span>
        </div>

        <div>
          <i
            className={`nav__list__item__box__arrow-icon ${
              heatMapIsOpen ? "arrow-rotate-left" : ""
            }`}
          >
            {/* arrow-right */}
            <ArrowRightSvg />
          </i>
        </div>
      </div>
      <div
        className={`nav__list__item__sub-category ${
          !navBoxIsOpen && "sub-ctgy-mode-2"
        }`}
      >
        <ul ref={childListRefItem}>
          {!navBoxIsOpen && (
            <span className="nav__list__item__sub-category__title">
              {parentCategoryName}
            </span>
          )}

          {user?.role[0]?.permissions?.new_analytic && (
            <NavLink
              onClick={clickOnHeatMapItems}
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li className="">
                <i>
                  <MessageTextSvg />
                </i>
                <span>Analysis</span>
              </li>
            </NavLink>
          )}

          {user?.role[0]?.permissions?.analytics_list && (
            <NavLink
              onClick={clickOnHeatMapItems}
              to="/analysisList"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li>
                <i>
                  <RowVerticalSvg />
                </i>
                <span>Analysis List</span>
              </li>
            </NavLink>
          )}
          {user?.role[0]?.permissions?.heatmap_list && (
            <NavLink
              onClick={clickOnHeatMapItems}
              to="/heatMap-list"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li>
                <i>
                  <MapSvg />
                </i>
                <span>HeatMap List</span>
              </li>
            </NavLink>
          )}
          {user?.role[0]?.permissions?.notifications_list && (
            <NavLink
              onClick={clickOnHeatMapItems}
              to="/notification"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li>
                <i>
                  <NotificationSvg />
                </i>
                <span>Notification</span>
              </li>
            </NavLink>
          )}
          {user?.role[0]?.permissions?.full_access && (
            <NavLink
              onClick={clickOnHeatMapItems}
              to="/userList"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li className="">
                <i>
                  <ProfileSvg className="w-6 h-6" />
                </i>
                <span>User List</span>
              </li>
            </NavLink>
          )}
        </ul>
      </div>
    </li>
  );
}
