import React, { useState } from "react";

export const Checkbox = ({ id, label, value, checked, onChange, ...props }) => {

  const [isChecked, setIsChecked] = useState(checked)

  const handleOnChange = () => {
    if (onChange) {
      setIsChecked(!isChecked)
      onChange()
    }
  
  }

  const renderInput = () => {
        return (
          <>
            <div className="flex items-center">
              <input
                className="w-4 h-4"
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={handleOnChange}
              />
              <div className="ml-1">
                <label htmlFor={id}>{label}</label>
              </div>
            </div> 
          </>
        );
  };

  return renderInput();
};








