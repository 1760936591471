import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import PropTypes from 'prop-types';

export default function NavBlockerAlert({
  open,
  setOpen,
  blocker,
  nearRealTimeDataUpdateHelper,
}) {

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="bg-storm-80 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div
        onClick={() => {
          setOpen(true);
          blocker.reset();
        }}
        className="fixed inset-0 z-10 w-screen overflow-y-auto "
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="bg-sky-light relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="mt-2">
                    <p className="text-lg text-sky-70 font-robotoMed">
                      Are you sure you want to Exit ?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={() => {
                  blocker.reset();
                  nearRealTimeDataUpdateHelper();
                }}
                className="text-infrared-50 ring-1 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-robotoMed shadow-sm text--50 sm:ml-3 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                No, cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  blocker.proceed();
                  nearRealTimeDataUpdateHelper();
                }}
                className="text-leaf-50 ring-1 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-robotoMed text-white shadow-sm hover:bg-red-500 sm:w-auto"
              >
                Yes, sure
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

NavBlockerAlert.propTypes = {
  open: PropTypes.bool.isRequired, 
  setOpen: PropTypes.func.isRequired,
  blocker: PropTypes.object.isRequired,
  nearRealTimeDataUpdateHelper: PropTypes.func.isRequired,
};