import React from "react";

function CopySvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        stroke="#9AB0BC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.333 5.5V12c0 2-1.194 2.667-2.667 2.667H5.333C3.859 14.667 2.666 14 2.666 12V5.5c0-2.167 1.193-2.667 2.667-2.667 0 .414.166.787.44 1.06.273.274.646.44 1.06.44h2.333c.827 0 1.5-.673 1.5-1.5 1.473 0 2.667.5 2.667 2.667z"
      ></path>
      <path
        stroke="#9AB0BC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.667 2.833c0 .827-.673 1.5-1.5 1.5H6.834c-.413 0-.787-.166-1.06-.44a1.491 1.491 0 01-.44-1.06c0-.826.673-1.5 1.5-1.5h2.333c.414 0 .787.167 1.06.44.274.274.44.647.44 1.06zM5.334 8.666h2.667M5.334 11.334h5.333"
      ></path>
    </svg>
  );
}

export default CopySvg;
