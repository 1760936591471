import React, { useEffect, useRef, useState } from "react";

import NavBlockerAlert from "../../ui/NavBlockerAlert";
import useLocalStorage from "use-local-storage";
import FullChartProcessorClass from "../../utils/classes/fullChartProcessorClass";
import PropTypes from "prop-types";

import { widget } from "~/charting_library";
import { useBlocker } from "react-router-dom";
import { convertChartTypeCodeToName } from "../../utils/converters/convertChartTypeCodeToName";
import { createChartConfig } from "../../config/createChartConfig";
import { useOnBeforeUnload } from "./helper";
import { menuTvIcons } from "../../utils/helpers/tools/menuTvIcons";

import "../index.css";

export const TVChartContainer = ({
  chartData,
  setChartData,
  theme,
  setTheme,
  favorites,
  setFavorites,
  layoutList,
  setLayoutList,
  templateList,
  setTemplateList,
}) => {
  const chartContainerRef = useRef();
  const { baseChartConfig, specificConfigs } = createChartConfig("mainChart");

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      chartHasChanged && currentLocation.pathname !== nextLocation.pathname
  );

  const [openAlert, setOpenAlert] = useState(true);
  const [chartHasChanged, setChartHasChanged] = useState(false);
  const [nearRealTimeState, setNearRealTimeState] = useLocalStorage(
    "full_chart_near_real_time",
    null
  );

  const fullChartProcessor = new FullChartProcessorClass(nearRealTimeState);
  const nearRealTimeDataUpdateHelper = fullChartProcessor?.nearRealTimeDataUpdateHelper;

  const checkChartHasChanged = () => {
    setChartHasChanged(true);
  };

  useEffect(() => {
    setNearRealTimeState({
      chartData: chartData,
      chartTheme: theme,
      favorites: favorites,
    });
  }, [chartData, theme, favorites, chartHasChanged]);

  useOnBeforeUnload(nearRealTimeDataUpdateHelper, nearRealTimeState, chartHasChanged);

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(baseChartConfig?.datafeedUrl?.binance),
      disabled_features: specificConfigs?.disabledFeatures,
      enabled_features: specificConfigs?.enabledFeatures,
      theme: theme,
      symbol: null,
      interval: "D",
      saved_data: chartData,

      save_load_adapter: {
        charts: layoutList,
        chartTemplates: templateList,
        saveLoadAdapterManager: new FullChartProcessorClass(
          nearRealTimeState,
          layoutList,
          templateList,
          setTemplateList,
          setLayoutList,
          setChartHasChanged
        ),

        getAllCharts: function () {
          return this.saveLoadAdapterManager.getAllCharts(layoutList);
        },
        saveChart: function (chartData) {
          return this.saveLoadAdapterManager.saveChart(chartData);
        },
        removeChart: function (id) {
          return this.saveLoadAdapterManager.removeChart(id);
        },
        getChartContent: function (id) {
          return this.saveLoadAdapterManager.getChartContent(id);
        },

        async getAllChartTemplates() {
          return this.saveLoadAdapterManager.getAllChartTemplates(templateList);
        },

        async saveChartTemplate(templateName, content) {
          return this.saveLoadAdapterManager.saveChartTemplate(templateName, content);
        },

        async removeChartTemplate(templateName) {
          templateList = templateList.filter((x) => x.name !== templateName);

          this.isDirty = true;
          return this.saveLoadAdapterManager.removeChartTemplate(templateList);
        },

        async getChartTemplateContent(templateName) {
          return this.saveLoadAdapterManager.getChartTemplateContent(templateName);
        },
      },

      context_menu: {
        items_processor: (defaultItems, actionFactory) => {
          const createMenuItem = (label, icon, theme) => {
            return actionFactory.createAction({
              label,
              icon,
              hint: `Reset theme to (${label} mode)`,
              onExecute: () => {
                setTheme(theme);
                tvWidget.changeTheme(theme);
                setChartHasChanged(true);
              },
            });
          };

          const darkModeItem = createMenuItem("Dark", menuTvIcons.darkMode, "dark");

          const lightModeItem = createMenuItem("Light", menuTvIcons.lightMode, "light");

          const items = [...defaultItems, darkModeItem, lightModeItem];
          return items;
        },
      },

      favorites: {
        intervals: favorites[0]?.intervals ?? [],
        indicators: favorites[0]?.indicators ?? [],
        drawingTools: favorites[0]?.drawingTools ?? [],
        chartTypes: convertChartTypeCodeToName(favorites[0]?.chartTypes) ?? [],
      },

      settings_adapter: {
        initialSettings: {
          "loadChartDialog.favorites": favorites[1]?.loadChartDialog ?? [],
        },

        setValue: function (key, value) {
          setFavorites((prevFavorites) => {
            const updatedFavorites = [...prevFavorites];

            const updateFavoriteKey = (subKey, parsedValue) => {
              updatedFavorites[0] = {
                ...updatedFavorites[0],
                [subKey]: parsedValue,
              };
            };

            const keyMapping = {
              "chart.favoriteDrawings": () =>
                updateFavoriteKey("drawingTools", JSON.parse(value)),
              "chart.favoriteLibraryIndicators": () =>
                updateFavoriteKey("indicators", JSON.parse(value)),
              "IntervalWidget.quicks": () =>
                updateFavoriteKey("intervals", JSON.parse(value)),
              "StyleWidget.quicks": () =>
                updateFavoriteKey("chartTypes", JSON.parse(value)),
              "loadChartDialog.favorites": () => {
                updatedFavorites[1] = {
                  ...updatedFavorites[1],
                  loadChartDialog: value,
                };
              },
            };

            if (keyMapping[key]) {
              keyMapping[key]();
              checkChartHasChanged();
            }

            return updatedFavorites;
          });
        },

        removeValue: function (key) {
          console.log(`remove value: ${key}`);
        },
      },
    };
    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        tvWidget.save((data) => {
          setChartData(data);
        });
        tvWidget.subscribe("undo_redo_state_changed", () => {
          tvWidget.save((data) => {
            setChartHasChanged(true);
            setChartData(data);
          });
        });
      });
    });
    return () => {
      tvWidget.remove();
    };
  }, []);

  return (
    <>
      {blocker.state === "blocked" && (
        <NavBlockerAlert
          open={openAlert}
          setOpen={setOpenAlert}
          blocker={blocker}
          nearRealTimeDataUpdateHelper={nearRealTimeDataUpdateHelper}
        />
      )}

      <div ref={chartContainerRef} className={"TVChartContainer"} />
    </>
  );
};

TVChartContainer.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  setFavorites: PropTypes.func.isRequired,
  layoutList: PropTypes.array.isRequired,
  setLayoutList: PropTypes.func.isRequired,
  templateList: PropTypes.array.isRequired,
  setTemplateList: PropTypes.func.isRequired,
};
