import React from "react";
import { priceTypeDetermine } from "../../../utils/determinators/priceTypeDetermine";
import { trimTrailingCharacter } from "../../../utils/helpers/tools/trimTrailingCharacter";
import { setIntervalChartSource } from "../../../utils/chartSourceModifiers/setIntervalChartSource";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { validateInput } from "../../../utils/helpers/tools/validateInput";

export const processChartData = (initInterval, techChartData) => {
  const functionName = processChartData.name;
  validateInput(initInterval, "initInterval", functionName);
  validateInput(techChartData, "techChartData", functionName);
  const interval = convertIntervalToTradingViewFormat(initInterval);
  const finalChartData = setIntervalChartSource(techChartData, interval);
  return { interval, finalChartData };
};

export const renderTradeBoundariesPoints = (
  points,
  type,
  baseDateType,
  interval,
  createWidget,
  setTradeBoundariesWidgetIds
) => {
  const functionName = renderTradeBoundariesPoints.name;
  validateInput(points, "points", functionName);
  validateInput(type, "type", functionName);
  validateInput(baseDateType, "baseDateType", functionName);
  validateInput(interval, "interval", functionName);
  validateInput(createWidget, "createWidget", functionName);
  validateInput(setTradeBoundariesWidgetIds, "setTradeBoundariesWidgetIds", functionName);

  points.map((point) => {
    let initPrice = point?.value;
    let price = trimTrailingCharacter(initPrice, "-");
    if (price !== "") {
      const { type: priceType } = priceTypeDetermine(price) || {};
      if (priceType === "single") {
        const { shapeId } = createWidget.trendLine(
          price,
          createTradeBoundariesWidgetTimeRange(interval, null, baseDateType),
          type
        );
        setTradeBoundariesWidgetIds((prev) => [...prev, shapeId]);
      }
      if (priceType === "range") {
        const { shapeId } = createWidget.rectangle(
          price.split("-"),
          createTradeBoundariesWidgetTimeRange(interval, null, baseDateType),
          type
        );
        setTradeBoundariesWidgetIds((prev) => [...prev, shapeId]);
      }
    }
  });
};

