import React from "react";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";

import InputBase from "~/components/child/ui/InputBase";
import Dropdown from "~/components/child/ui/DropDown";
import Description from "~/components/child/ui/Description";
import DatePicker from "~/components/child/ui/datePicker/DatePicker";

export const NewHeatMap = ({ heatMapValues, setHeatMapValues }) => {
  return (
    <div className=" heatMap__new-heatMap-popup__box__form my-10">
      <InputBase
        title="Title"
        value={heatMapValues.new.title}
        setValue={(e) =>
          setHeatMapValues({
            ...heatMapValues,
            new: { ...heatMapValues.new, title: e.target.value },
          })
        }
      />

      <Description
        description={heatMapValues.new.description}
        setDescription={(updatedDesc) =>
          setHeatMapValues({
            ...heatMapValues,
            new: { ...heatMapValues.new, description: updatedDesc },
          })
        }
        limitNumber={8000}
      />
      <Dropdown
        multipleValues
        title="Analysis Type"
        value={heatMapValues?.new?.analysisType}
        optionsList={["Technical", "Fundamental", "On_chain"].map((type) => ({
          key: type?.toLowerCase(),
          value: type,
        }))}
        renderItem={(item) => (
          <span className="py-2 text-xs font-roboto w-full text-left">
            {item.value}
          </span>
        )}
        onSelect={(item) => {
          setHeatMapValues({
            ...heatMapValues,
            new: {
              ...heatMapValues.new,
              analysisType: onChangeCheckboxes(
                heatMapValues?.new.analysisType || [],
                {
                  key: item.key,
                  value: item.value,
                }
              ),
            },
          });
        }}
        onDeleteOption={(item) => {
          setHeatMapValues({
            ...heatMapValues,
            new: {
              ...heatMapValues.new,
              analysisType: heatMapValues?.new?.analysisType?.filter(
                (analysisType) => item.key !== analysisType.key
              ),
            },
          });
        }}
      />
      <div className="flex items-center justify-between gap-4 w-full">
        <DatePicker
          forcible={true}
          className="w-full"
          date={heatMapValues?.new?.date?.from}
          emitDatePicker={(value) => {
            setHeatMapValues({
              ...heatMapValues,
              new: {
                ...heatMapValues.new,
                date: {
                  ...heatMapValues.new.date,
                  from: value !== null ? value : undefined,
                },
              },
            });
          }}
          inputLabel="From date"
          position="right-start or right-top"
          maxDate={heatMapValues.new.date.to}
        />
        <DatePicker
          minDate={heatMapValues.new.date.from}
          forcible={true}
          className="w-full"
          date={heatMapValues?.new?.date?.to}
          emitDatePicker={(value) => {
            setHeatMapValues({
              ...heatMapValues,
              new: {
                ...heatMapValues.new,
                date: {
                  ...heatMapValues.new.date,
                  to: value !== null ? value : undefined,
                },
              },
            });
          }}
          inputLabel="To date"
          position="right-start or right-top"
        />
      </div>
    </div>
  );
};
