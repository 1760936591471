export const calculateRangeAverage = (value) => {
  if (value.includes("-")) {
    var range = value.split("-");
    var start = Number(range[0]);
    var end = Number(range[1]);

    if (isNaN(start) || isNaN(end)) {
      return 0;
    }

    return (start + end) / 2;
  } else {
    return value;
  }
};
export const calculateTotalAverage = (array) => {
  var sum = 0;
  var count = 0;
  for (var i = 0; i < array.length; i++) {
    var value = array[i].value;
    if (typeof value === "string" && value?.includes("-")) {
      var rangeAverage = calculateRangeAverage(value);

      sum += rangeAverage;
      count++;
    } else {
      if (!isNaN(value)) {
        sum += Number(value);
        count++;
      }
    }
  }

  var average = count > 0 ? sum / count : 0;

  return Number(average);
};

export const riskToRewardResult = (ep, sl, tp) => {
  if (Number(ep) !== 0 && Number(sl) !== 0 && Number(tp) !== 0) {
    const result = Math.abs(
      (Number(tp) - Number(ep)) / (Number(ep) - Number(sl))
    ).toFixed(2);

    if (!isNaN(result) && isFinite(result) && result !== 0 && result < 99.99)
      return result;
  }
  return null;
};
