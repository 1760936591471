import { combineAllChartsSources } from "../chartSourceModifiers/combineAllChartsSources";
import { setIntervalChartSource } from "../chartSourceModifiers/setIntervalChartSource";
import { setThemeChartSource } from "../chartSourceModifiers/setThemeChartSource";
import { convertChartTimeZone } from "../converters/convertChartTimeZone";
import { convertIntervalToTradingViewFormat } from "../converters/convertTimeFrameToTradingViewFormat";
import { validateInput } from "../helpers/tools/validateInput";

class HeatmapChartProcessorClass {
  constructor(heatmapChartDataDetails, selectedAnalysersIds, theme) {
    this.thisClassName = 'HeatmapChartProcessorClass'
    const additionalErrorMessage = 'constructor()'
    validateInput(heatmapChartDataDetails, 'heatmapChartDataDetails', this.thisClassName, additionalErrorMessage);
    validateInput(selectedAnalysersIds, 'selectedAnalysersIds', this.thisClassName, additionalErrorMessage);
    validateInput(theme, 'theme', this.thisClassName, additionalErrorMessage);

    
    const {
      analysis_data: analysisDetails,
      date,
      description,
      pair: pairDetail,
      title,
      user_name: userName,
      uuid,
    } = heatmapChartDataDetails?.data || {};

    const { oldest_analysis_date: oldestAnalysisDate } =
      heatmapChartDataDetails?.data?.analysis_data || {};

    const { time_frame: resolution } =
      heatmapChartDataDetails?.data?.timeframe || {};

    this.heatmapChartDataDetails = heatmapChartDataDetails;
    this.selectedAnalysersIds = selectedAnalysersIds;
    this.theme = theme;
    this.analysisDetails = analysisDetails;
    this.date = date;
    this.description = description;
    this.pairDetail = pairDetail;
    this.title = title;
    this.userName = userName;
    this.uuid = uuid;
    this.oldestAnalysisDate = oldestAnalysisDate;
    this.intervalData = convertIntervalToTradingViewFormat(resolution);

    const { filteredTechnicalList, filteredFundamentalList } =
      this.getFilteredData();
    this.technicalList = filteredTechnicalList;
    this.fundamentalList = filteredFundamentalList;
  }

  filterList(list) {
    return list?.filter((item) =>
      this.selectedAnalysersIds?.includes(item?.analysis?.uuid)
    );
  }

  getFilteredData() {
    return {
      filteredTechnicalList: this.filterList(
        this.heatmapChartDataDetails?.data?.analysis_data?.technical
      ),
      filteredFundamentalList: this.filterList(
        this.heatmapChartDataDetails?.data?.analysis_data?.fundamental
      ),
    };
  }

  intervalCalculatedHandler(userInterval, interval) {
    const functionName = this.intervalCalculatedHandler.name
    validateInput(userInterval, 'userInterval', functionName, this.thisClassName);
    validateInput(interval, 'interval', functionName, this.thisClassName);
    if (userInterval) {
      return userInterval;
    } else if (this.fundamentalList.length > 0 && userInterval === "") {
      return "D";
    } else if (this.technicalList.length > 0 && userInterval === "") {
      return interval;
    } else {
      return interval;
    }
  }

  getMergedAllChartData() {
    const combinedChartData = this.technicalList.concat(this.fundamentalList);

    let allChartData = [];

    combinedChartData.forEach((item) => {
      item.chart_data = item.chart_data === "null" ? null : item.chart_data;
      if (item.chart_data) {
        allChartData.push(JSON.parse(item.chart_data));
      }
    });

    return allChartData.length > 0
      ? combineAllChartsSources(allChartData)
      : null;
  }

  processChartData(userInterval, interval) {
    const functionName = this.processChartData.name
    validateInput(userInterval, 'userInterval', functionName, this.thisClassName);
    validateInput(interval, 'interval', functionName, this.thisClassName);
    const initChartData = this.getMergedAllChartData();

    const finalCalculatedInterval = this.intervalCalculatedHandler(
      userInterval,
      interval
    );

    let modifyChartDataInterval = setIntervalChartSource(
      initChartData,
      finalCalculatedInterval
    );

    const modifyChartDataTheme = setThemeChartSource(
      modifyChartDataInterval,
      this.theme
    );

    return convertChartTimeZone(modifyChartDataTheme, 1);
  }
}

export default HeatmapChartProcessorClass;
