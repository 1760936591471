export const usersProfileColor = (index) => {
  switch (index % 4) {
    case 0: {
      return "bg-ultraviolet-20";
    }
    case 1:
      return "bg-sun-30";
    case 2:
      return "bg-infrared-30";
    case 3:
      return "bg-leaf-30";
    default:
      break;
  }
};
