import { useEffect } from "react";

export const useKeySubmission = (
  onSubmit,
  isButtonDisable,
  keyCondition,
  dependencies = []
) => {
  useEffect(() => {
    const isButtonDisabled = isButtonDisable ? isButtonDisable() : false;

    const handleKeyDown = (event) => {
      if (keyCondition(event)) {
        if (!isButtonDisabled) {
          onSubmit();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit, isButtonDisable, keyCondition, ...dependencies]);
};
