import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Loading } from "~/components/child/ui/Loading";
import { PairsSymbol } from "~/components/child/Symbols";

export const ChooseAnalysis = ({
  analysisList,
  heatMapValues,
  setHeatMapValues,
  isLoading,
  hasMoreData,
  next,
}) => {
  const handleChangeCheckboxes = (id) => {
    const updatedAnalysisArray = [...heatMapValues.analytics];

    if (heatMapValues.analytics.includes(id)) {
      const index = heatMapValues.analytics.indexOf(id);
      updatedAnalysisArray.splice(index, 1);
    } else updatedAnalysisArray.push(id);

    return updatedAnalysisArray;
  };
  const uuids = analysisList?.map((item) => item.analysis.uuid);

  return (
    <div className=" my-10">
      <div className="heatMap__new-heatMap-popup__box__title mb-6">
        <h5 className="flex items-center gap-4">
          Choose analysis
          <span className=" font-roboto text-sm font-normal text-oil-60">
            {analysisList?.length} analysis
          </span>
        </h5>
        <p className="font-roboto text-sm font-normal text-oil-dark">
          From{" "}
          {`${heatMapValues?.new?.date?.from.year}/${heatMapValues?.new?.date?.from.month?.number}/${heatMapValues?.new?.date?.from.day}`}{" "}
          to{" "}
          {`${heatMapValues?.new?.date?.to.year}/${heatMapValues?.new?.date?.to.month?.number}/${heatMapValues?.new?.date?.to.day}`}
        </p>
      </div>
      {analysisList.length > 0 ? (
        <div
          id="scrollableDiv"
          className="list-table-cstm-one scrollbar-style scrollbar-style-thumb h-[30rem] overflow-y-auto heatMap__new-heatMap-popup__box__form"
        >
          <InfiniteScroll
            dataLength={analysisList.length}
            next={next}
            hasMore={hasMoreData}
            loader={isLoading && <Loading size="medium" />}
            scrollableTarget="scrollableDiv"
          >
            <table className="list-table-cstm-one__content-table">
              <thead>
                <tr>
                  <th>
                    <label>
                      <input
                        checked={
                          !isLoading &&
                          heatMapValues.analytics.length === uuids.length
                        }
                        onChange={() => {
                          if (heatMapValues.analytics.length === uuids.length)
                            setHeatMapValues({
                              ...heatMapValues,
                              analytics: [],
                            });
                          else
                            setHeatMapValues({
                              ...heatMapValues,
                              analytics: uuids,
                            });
                        }}
                        type="checkbox"
                      />
                    </label>
                  </th>
                  <th>Symbol</th>
                  <th>Type</th>

                  <th>User</th>
                  <th>Date</th>
                  <th>TimeFrame</th>
                </tr>
              </thead>

              <tbody>
                {analysisList?.map((item, index) => {
                  return (
                    <tr key={item.analysis.uuid}>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={heatMapValues.analytics.includes(
                              item.analysis.uuid
                            )}
                            onChange={() =>
                              setHeatMapValues({
                                ...heatMapValues,

                                analytics: handleChangeCheckboxes(
                                  item.analysis.uuid
                                ),
                              })
                            }
                          />
                        </label>
                      </td>
                      <td>
                        <PairsSymbol
                          pair={item.analysis.pair.pair}
                          image={item.analysis.pair.image}
                        />
                      </td>
                      <td className="list-table-cstm-one__content-table__flex">
                        <div className="list-table-cstm-one__content-table__analysis-type gap-2 flex items-center">
                          <span className="rounded py-1 px-2 bg-storm-20 text-storm-50">
                            {item.analysis?.analysis_type?.charAt(0)}
                          </span>

                          {item.analysis?.analysis_type === "TECHNICAL" && (
                            <span
                              className={`${
                                item.analysis?.position_type?.charAt(0) === "L"
                                  ? "  text-twilight-70 bg-twilight-30 "
                                  : "text-infrared-50 bg-infrared-10"
                              } rounded py-1 px-2`}
                            >
                              {item.analysis?.position_type?.charAt(0)}
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        {item.analysis?.user?.first_name}{" "}
                        {item.analysis?.user?.last_name}
                      </td>

                      <td className="list-table-cstm-one__content-table__date-time">
                        <div className="list-table-cstm-one__content-table__date-time__date">
                          {item.analysis?.created_at?.split(" ")[0]}
                        </div>
                        <div className="list-table-cstm-one__content-table__date-time__time">
                          {item.analysis?.created_at?.split(" ")[1]}
                        </div>
                      </td>
                      <td>{item.analysis?.time_frame?.time_frame || "-"} </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      ) : (
        !isLoading &&
        analysisList.length === 0 && (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title text-center">No Result!</h4>
          </div>
        )
      )}
    </div>
  );
};
