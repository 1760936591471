import { riskToRewardResult } from "~/utils/riskToRewardResult";
import { calculateTotalAverage, calculateRangeAverage } from "~/utils/riskToRewardResult";

export const calculateRiskToReward = (ep, sl, tpList, setAnalysisValues) => {
  tpList?.map((item, index) => {
    if (item.value.length > 0)
      setAnalysisValues((prevData) => {
        const newData = { ...prevData };
        newData.technical.points.tp[index].rr = riskToRewardResult(
          calculateTotalAverage(ep),
          calculateRangeAverage(sl),
          calculateRangeAverage(item.value)
        );
        return newData;
      });
  });
};

export const handlePointChange = (
  updatedPointVal,
  pointType,
  index,
  setAnalysisValues,
  setDataHasChanged
) => {
  setAnalysisValues((prevData) => {
    const newData = { ...prevData };
    newData.technical.points[pointType][index] = updatedPointVal;

    const epAvg = calculateTotalAverage(
      newData.technical.points.ep.filter((item) => item.value !== "")
    );
    const slValue = newData.technical.points.sl[0].value;
    const tpList = newData.technical.points.tp;
    newData.technical.points.tp = tpList?.map((item) => {
      if (item.value.length > 0) {
        item.rr = riskToRewardResult(
          epAvg,
          calculateRangeAverage(slValue),
          calculateRangeAverage(item.value)
        );
      } else item.rr = null;
      return item;
    });

    return newData;
  });
  setDataHasChanged(true);
};

export const handleDeletePoint = (
  pointType,
  index,
  setAnalysisValues,
  setDataHasChanged
) => {
  setAnalysisValues((prevState) => {
    const updatedPoints = { ...prevState.technical.points };
    updatedPoints[pointType].splice(index, 1);
    calculateRiskToReward(
      updatedPoints.ep,
      updatedPoints.sl[0].value,
      updatedPoints.tp,
      setAnalysisValues
    );
    return {
      ...prevState,
      points: updatedPoints,
    };
  });
  setDataHasChanged(true);
};
