import React, { memo, useState, useEffect } from "react";

import { privateNoteApi } from "../../api/dashboard/analysis/privateNoteApi";

import { MessageInput } from "./MessageInput";
import { Loading } from "./ui/Loading";

import CopySvg from "../../assets/icon/svgJsx/CopySvg";

export const Item = ({ description }) => {
  return (
    <div className="privateNote__item">
      <CopySvg />
      <p>{description}</p>
    </div>
  );
};

export const PrivateNote = memo(
  ({ id, section, setTotalCount, permissions }) => {
    const [commentMessage, setCommentMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [privateNoteList, setPrivateNoteList] = useState([]);

    const analysisFetchPrivateNotes = async () => {
      try {
        const result = await privateNoteApi.list(id, section);
        setPrivateNoteList(result?.data?.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    const onSendMessage = () => {
      setIsLoading(true);

      privateNoteApi
        .createNew(
          {
            comment_text: commentMessage,
          },
          id,
          section
        )
        .then((res) => {
          if (res && !res.error && res.data) {
            setCommentMessage("");
            analysisFetchPrivateNotes();
          } else if (res?.error) {
            if (res?.error.response?.data.errors) {
              setErrorMessage(res?.error.response?.data.errors);
            } else {
              setErrorMessage([res?.error.response?.data.message]);
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    useEffect(() => {
      if (id && permissions?.private_note_list) analysisFetchPrivateNotes();
    }, [id, permissions]);

    useEffect(() => {
      if (setTotalCount && privateNoteList && !isLoading)
        setTotalCount(privateNoteList?.length);
    }, [privateNoteList, isLoading]);

    return (
      <div className="privateNote">
        {privateNoteList?.length > 0 && !isLoading ? (
          <div className="privateNote__list">
            {privateNoteList.map((item, index) => (
              <Item key={index} description={item.text} />
            ))}
          </div>
        ) : !isLoading ? (
          <div className="privateNote__emptyList">
            <h4 className="privateNote__emptyList__title">
              There isn't any private note for this {section}!
            </h4>
          </div>
        ) : (
          <Loading size="small" />
        )}
        {permissions?.new_private_note && (
          <MessageInput
            handleSubmit={onSendMessage}
            value={commentMessage}
            setValue={setCommentMessage}
          />
        )}
      </div>
    );
  }
);
