import { createPairDataConfig } from "../../creators/createPairDataConfig";
import { validateInput } from "./validateInput";

export const getPairDataConfig = (pairDetail, mode) => {
  const mappings = {
    camelCase: {
      baseSymbol: pairDetail.baseSymbol,
      quoteSymbol: pairDetail.quoteSymbol,
      exchange: pairDetail.exchange,
    },
    snake_case: {
      baseSymbol: pairDetail.base_symbol,
      quoteSymbol: pairDetail.quote_symbol,
      exchange: pairDetail.exchange,
    },
  };

  if (!mappings[mode]) {
    validateInput(null, 'mode', 'getPairDataConfig', `Invalid mode: ${mode}. Expected 'camelCase' or 'snake_case'.`);
  }

  const { baseSymbol, quoteSymbol, exchange } = mappings[mode];
  return createPairDataConfig(baseSymbol, quoteSymbol, exchange);
};
