import React from "react";

function MoreOneSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="13"
      fill="none"
      viewBox="0 0 3 13"
      className="m-auto"
    >
      <path
        fill="#000"
        d="M1.453 12.477c-.286 0-.531-.102-.734-.305a.988.988 0 01-.313-.735c0-.286.104-.53.313-.734.203-.203.448-.305.734-.305.287 0 .531.102.734.305a1 1 0 01.305.735c0 .291-.099.539-.297.742a1.007 1.007 0 01-.742.297zm0-4.938a1 1 0 01-.734-.305.989.989 0 01-.313-.734c0-.286.104-.531.313-.734a1 1 0 01.734-.305 1 1 0 01.734.305 1 1 0 01.305.734c0 .292-.099.54-.297.742a1.007 1.007 0 01-.742.297zm0-4.937a1 1 0 01-.734-.305.989.989 0 01-.313-.735c0-.286.104-.53.313-.734a1 1 0 01.734-.305 1 1 0 01.734.305 1 1 0 01.305.735c0 .291-.099.539-.297.742a1.007 1.007 0 01-.742.297z"
      ></path>
    </svg>
  );
}

export default MoreOneSvg;
