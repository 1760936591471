import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { apiConfig } from "../../../utils/constants";

const ImageSlider = ({ images }) => {
  return (
    <div className="image-slider mt-5">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        initialSlide={images.findIndex((item) => item.is_primary === true)} // initial step slide
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {images?.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={apiConfig.BASE_API_URL + '/trade/' + item.picture} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
