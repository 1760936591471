import React from "react";

export const Loading = ({ size = "large", iconButton = false }) => {
  return (
    <div className="load-wrap">
      <div className={`load ${iconButton ? "load-iconButton" : ""}`}>
        <div className={`line line-${size}`}></div>
        <div className={`line line-${size}`}></div>
        <div className={`line line-${size}`}></div>
      </div>
    </div>
  );
};
