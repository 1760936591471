import {
  callRestPost,
  callRestGet,
  callRestPut,
} from "../../utils/callRestApi";

export const notificationApi = {
  createNew: async (body, type = "general", id) => {
    const result = { data: null, error: null };
    await callRestPost(
      `/dashboard/${type === "heatmap" ? "heatmap/" : ""}notifications${
        id ? `/${id}` : ""
      }?type=${type}`,
      body,
      null
    )
      .then((res) => {
        if (res.status === 201) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
  list: async (type, page) => {
    const result = { data: null, error: null };
    await callRestGet(
      `/dashboard/notifications?type=${type}&page=${page}`,
      null
    )
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  activation: async (id) => {
    const result = { data: null, error: null };
    await callRestPut(`/dashboard/notifications/activation/${id}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
};
