// * Description : 
// * Use the 'isLoading' to ensure data retrieval.

import { useState, useEffect } from "react";
import { mainChartApi } from "../../api/dashboard/mainChartApi";
import useLocalStorage from "use-local-storage";

export const useMainChartDetails = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useLocalStorage('full_chart_data', null);
  const [theme, setTheme] = useLocalStorage('full_chart_theme', null);
  const [favorites, setFavorites] = useLocalStorage('full_chart_favorites', null);
  const [layoutList, setLayoutList] = useLocalStorage('full_chart_layout_list', null);
  const [templateList, setTemplateList] = useLocalStorage('full_chart_template_list', null);

  useEffect(() => {  

      const fetchMainChartDetails = async () => {
        try {

          const getMainChartDetail = await mainChartApi.detail('main_chart_data');
          const getLayoutList = await mainChartApi.detail('layouts');
          const getTemplateList = await mainChartApi.detail('template'); 
 
          if (getMainChartDetail?.data && getLayoutList?.data && getTemplateList?.data) {
            const fullChartData = JSON.parse(getMainChartDetail?.data?.chart_data?.chart_data)
            const layoutListData = JSON.parse(getLayoutList?.data?.chart_data?.chart_data)
            const templateListData = JSON.parse( getTemplateList?.data?.chart_data?.chart_data)
     
            setChartData(fullChartData?.chartData)
            setTheme(fullChartData?.chartTheme)
            setFavorites(fullChartData?.favorites)
            setLayoutList(layoutListData)
            setTemplateList(templateListData)

            setIsLoading(false) 
          } else {
            setIsLoading(false) 
            setChartData(null); 
            setTheme(null)
            setFavorites(null)
            setLayoutList(null)
            setTemplateList(null) 
          }
          
        } catch (error) {
          console.error("Error fetching main chart data details: =>", error);
          setIsLoading(false) 
          setChartData(null); 
          setTheme(null)
          setFavorites(null)
          setLayoutList(null)
          setTemplateList(null) 
        }
      };
  

      fetchMainChartDetails()

   
  }, []);

    return {
      isLoading,
      chartData,
      setChartData,
      theme,
      setTheme,
      favorites,
      setFavorites,
      layoutList,
      setLayoutList,
      templateList,
      setTemplateList
    }

};