import { validateInput } from "../helpers/tools/validateInput";

export const setThemeChartSource = (currentChartData, theme) => {

  const colors = {
    light: {
      layoutBackground: "rgb(255, 255, 255)",
      layoutGridLine: "rgba(42, 46, 57, 0.06)",
      textChart: "rgb(0, 0, 0)",
    },
    dark: {
      layoutBackground: "rgb(19, 23, 34)",
      layoutGridLine: "rgba(240, 243, 250, 0.06)",
      textChart: "rgb(255, 255, 255)",
    },
  };

  if (!colors[theme]) {
    validateInput(theme, 'theme', 'setThemeChartSource')
  }

  const selectedTheme = colors[theme];
  const paneProperties = currentChartData?.charts[0]?.chartProperties?.paneProperties;
  const scalesProperties = currentChartData?.charts[0]?.chartProperties?.scalesProperties;

  if (paneProperties) {
    paneProperties.backgroundType = "solid";
    paneProperties.background = selectedTheme.layoutBackground;
    paneProperties.backgroundGradientEndColor = selectedTheme.layoutBackground;
    paneProperties.backgroundGradientStartColor = selectedTheme.layoutBackground;
    paneProperties.horzGridProperties.color = selectedTheme.layoutGridLine;
    paneProperties.vertGridProperties.color = selectedTheme.layoutGridLine;
  }

  if (scalesProperties) {
    scalesProperties.textColor = selectedTheme.textChart;
  }

  return currentChartData;
};
