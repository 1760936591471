const widgetStyles = {
  '-': 'fundamentalDatePoint',
  long: 'fundamentalDatePointLong',
  short: 'fundamentalDatePointShort',
};

export const fundamentalVerticalLineStyleDetermine = (type) => {
  const style = type ? widgetStyles[type.toLowerCase()] : 'fundamentalDatePoint'
  return style;
};
