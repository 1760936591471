import React, { useEffect } from "react";
import { handlePlotImbalances, handleRemoveAllWidget } from "../helpers";
import PropTypes from 'prop-types';

const RightCard = ({
  pair,
  imbalanceValue,
  setImbalanceValue,
  isPlotZones,
  setIsPlotZones,
  chartRef,
}) => {

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setImbalanceValue(value);
    }
  };

  useEffect(() => {
    setIsPlotZones(false);
  }, [pair]);

  return (
    <div className="flex">
      <input
        value={imbalanceValue}
        onChange={handleInputChange}
        type="text"
        className={
          "outline-none text-center text-sky-70 rounded	mr-2 w-10 border-solid border-2 border-sky-key"
        }
      />

      <button
        onClick={() =>
          !isPlotZones && handlePlotImbalances(chartRef, setIsPlotZones)
        }
        className={`${
          isPlotZones ? "bg-leaf-40" : "bg-leaf-key"
        }  p-2 text-sky-light`}
      >
        PLOT ZONES
      </button>
      <button
        onClick={() => handleRemoveAllWidget(chartRef, setIsPlotZones)}
        className={"bg-infrared-key text-sky-light p-2 ml-1"}
      >
        CLEAR
      </button>
    </div>
  );
};

export default RightCard;
RightCard.propTypes = {
 pair: PropTypes.string.isRequired,
  imbalanceValue: PropTypes.number.isRequired,
  setImbalanceValue: PropTypes.func.isRequired,
  isPlotZones: PropTypes.bool.isRequired,
  setIsPlotZones: PropTypes.func.isRequired ,
  chartRef: PropTypes.object.isRequired ,
};

