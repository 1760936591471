export const defaultChartTypeSource = {
    "style": 1,
    "esdShowDividends": true,
    "esdShowSplits": true,
    "esdShowEarnings": true,
    "esdShowBreaks": false,
    "esdFlagSize": 2,
    "showContinuousContractSwitches": true,
    "showContinuousContractSwitchesBreaks": false,
    "showFuturesContractExpiration": true,
    "showLastNews": true,
    "showCountdown": false,
    "bidAsk": {
        "visible": false,
        "lineStyle": 1,
        "lineWidth": 1,
        "bidLineColor": "#2962FF",
        "askLineColor": "#F7525F"
    },
    "prePostMarket": {
        "visible": true,
        "lineStyle": 1,
        "lineWidth": 1,
        "preMarketColor": "#FB8C00",
        "postMarketColor": "#2962FF"
    },
    "highLowAvgPrice": {
        "highLowPriceLinesVisible": false,
        "highLowPriceLabelsVisible": false,
        "averageClosePriceLineVisible": false,
        "averageClosePriceLabelVisible": false,
        "highLowPriceLinesColor": "",
        "highLowPriceLinesWidth": 1,
        "averagePriceLineColor": "",
        "averagePriceLineWidth": 1
    },
    "visible": true,
    "showPriceLine": true,
    "priceLineWidth": 1,
    "priceLineColor": "",
    "baseLineColor": "#B2B5BE",
    "showPrevClosePriceLine": false,
    "prevClosePriceLineWidth": 1,
    "prevClosePriceLineColor": "#555555",
    "minTick": "default",
    "dividendsAdjustment": {},
    "backAdjustment": false,
    "settlementAsClose": true,
    "sessionId": "regular",
    "sessVis": false,
    "useVolumeWeightBars": false,
    "statusViewStyle": {
        "fontSize": 16,
        "showExchange": true,
        "showInterval": true,
        "symbolTextSource": "description"
    },
    "candleStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "drawWick": true,
        "drawBorder": true,
        "borderColor": "#378658",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "wickColor": "#737375",
        "wickUpColor": "#089981",
        "wickDownColor": "#F23645",
        "barColorsOnPrevClose": false,
        "drawBody": true
    },
    "hollowCandleStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "drawWick": true,
        "drawBorder": true,
        "borderColor": "#378658",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "wickColor": "#737375",
        "wickUpColor": "#089981",
        "wickDownColor": "#F23645",
        "drawBody": true
    },
    "haStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "drawWick": true,
        "drawBorder": true,
        "borderColor": "#378658",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "wickColor": "#737375",
        "wickUpColor": "#089981",
        "wickDownColor": "#F23645",
        "showRealLastPrice": false,
        "barColorsOnPrevClose": false,
        "inputs": {},
        "inputInfo": {},
        "drawBody": true
    },
    "barStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "barColorsOnPrevClose": false,
        "dontDrawOpen": false,
        "thinBars": true
    },
    "hiloStyle": {
        "color": "#2962FF",
        "showBorders": true,
        "borderColor": "#2962FF",
        "showLabels": true,
        "labelColor": "#2962FF",
        "drawBody": true
    },
    "columnStyle": {
        "upColor": "rgba(8, 153, 129, 0.5)",
        "downColor": "rgba(242, 54, 69, 0.5)",
        "barColorsOnPrevClose": true,
        "priceSource": "close"
    },
    "lineStyle": {
        "color": "#2962FF",
        "linestyle": 0,
        "linewidth": 2,
        "priceSource": "close"
    },
    "lineWithMarkersStyle": {
        "color": "#2962FF",
        "linestyle": 0,
        "linewidth": 2,
        "priceSource": "close"
    },
    "steplineStyle": {
        "color": "#2962FF",
        "linestyle": 0,
        "linewidth": 2,
        "priceSource": "close"
    },
    "areaStyle": {
        "color1": "rgba(41, 98, 255, 0.28)",
        "color2": "#2962FF",
        "linecolor": "#2962FF",
        "linestyle": 0,
        "linewidth": 2,
        "priceSource": "close",
        "transparency": 100
    },
    "hlcAreaStyle": {
        "highLineColor": "#089981",
        "highLineStyle": 0,
        "highLineWidth": 2,
        "lowLineColor": "#F23645",
        "lowLineStyle": 0,
        "lowLineWidth": 2,
        "closeLineColor": "#868993",
        "closeLineStyle": 0,
        "closeLineWidth": 2,
        "highCloseFillColor": "rgba(8, 153, 129, 0.2)",
        "closeLowFillColor": "rgba(242, 54, 69, 0.2)"
    },
    "renkoStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "upColorProjection": "#a9dcc3",
        "downColorProjection": "#f5a6ae",
        "borderUpColorProjection": "#a9dcc3",
        "borderDownColorProjection": "#f5a6ae",
        "wickUpColor": "#089981",
        "wickDownColor": "#F23645",
        "inputs": {
            "source": "close",
            "sources": "Close",
            "boxSize": 3,
            "style": "ATR",
            "atrLength": 14,
            "wicks": true
        },
        "inputInfo": {
            "source": {
                "name": "Source"
            },
            "sources": {
                "name": "Source"
            },
            "boxSize": {
                "name": "Box size"
            },
            "style": {
                "name": "Style"
            },
            "atrLength": {
                "name": "ATR length"
            },
            "wicks": {
                "name": "Wicks"
            }
        }
    },
    "pbStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "upColorProjection": "#a9dcc3",
        "downColorProjection": "#f5a6ae",
        "borderUpColorProjection": "#a9dcc3",
        "borderDownColorProjection": "#f5a6ae",
        "inputs": {
            "source": "close",
            "lb": 3
        },
        "inputInfo": {
            "source": {
                "name": "Source"
            },
            "lb": {
                "name": "Number of line"
            }
        }
    },
    "kagiStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "upColorProjection": "#a9dcc3",
        "downColorProjection": "#f5a6ae",
        "inputs": {
            "source": "close",
            "style": "ATR",
            "atrLength": 14,
            "reversalAmount": 1
        },
        "inputInfo": {
            "source": {
                "name": "Source"
            },
            "style": {
                "name": "Style"
            },
            "atrLength": {
                "name": "ATR length"
            },
            "reversalAmount": {
                "name": "Reversal amount"
            }
        }
    },
    "pnfStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "upColorProjection": "#a9dcc3",
        "downColorProjection": "#f5a6ae",
        "inputs": {
            "sources": "Close",
            "reversalAmount": 3,
            "boxSize": 1,
            "style": "ATR",
            "atrLength": 14,
            "oneStepBackBuilding": false
        },
        "inputInfo": {
            "sources": {
                "name": "Source"
            },
            "boxSize": {
                "name": "Box size"
            },
            "reversalAmount": {
                "name": "Reversal amount"
            },
            "style": {
                "name": "Style"
            },
            "atrLength": {
                "name": "ATR length"
            },
            "oneStepBackBuilding": {
                "name": "One step back building"
            }
        }
    },
    "baselineStyle": {
        "baselineColor": "#758696",
        "topFillColor1": "rgba(8, 153, 129, 0.28)",
        "topFillColor2": "rgba(8, 153, 129, 0.05)",
        "bottomFillColor1": "rgba(242, 54, 69, 0.05)",
        "bottomFillColor2": "rgba(242, 54, 69, 0.28)",
        "topLineColor": "#089981",
        "bottomLineColor": "#F23645",
        "topLineWidth": 2,
        "bottomLineWidth": 2,
        "priceSource": "close",
        "transparency": 50,
        "baseLevelPercentage": 50
    },
    "rangeStyle": {
        "barStyle": 0,
        "upColor": "#089981",
        "downColor": "#F23645",
        "upColorProjection": "#a9dcc3",
        "downColorProjection": "#f5a6ae",
        "thinBars": true,
        "candlesUpColor": "#089981",
        "candlesDownColor": "#F23645",
        "candlesBorderUpColor": "#089981",
        "candlesBorderDownColor": "#F23645",
        "candlesWickUpColor": "#089981",
        "candlesWickDownColor": "#F23645",
        "inputs": {
            "range": 10,
            "phantomBars": false
        },
        "inputInfo": {
            "range": {
                "name": "Range"
            },
            "phantomBars": {
                "name": "Phantom bars"
            }
        }
    },
    "volFootprintStyle": {
        "upColor": "#089981",
        "downColor": "#F23645",
        "drawWick": true,
        "drawBorder": true,
        "borderColor": "#378658",
        "borderUpColor": "#089981",
        "borderDownColor": "#F23645",
        "wickColor": "#737375",
        "wickUpColor": "#089981",
        "wickDownColor": "#F23645",
        "barColorsOnPrevClose": false,
        "drawBody": true,
        "deltaAdjust": true,
        "showSummary": true,
        "type": "Buy and sell",
        "pointOfControl": true,
        "imbalanceHighlight": {
            "buyColor": "#089981",
            "sellColor": "#F23645",
            "visible": true
        },
        "stackedLevels": {
            "visible": true,
            "count": 3
        },
        "inputs": {
            "rowSize": "Auto",
            "atrLength": 14,
            "imbalancePercent": 300,
            "ticksPerRow": 100,
            "showVA": true,
            "vaPercent": 70
        },
        "bgColors": {}
    },
    "symbol": "BTCUSDT",
    "shortName": "BTCUSDT",
    "timeframe": "",
    "onWidget": false,
    "interval": "15",
    "unitId": null,
    "currencyId": "USDT",
    "priceAxisProperties": {
        "autoScale": true,
        "autoScaleDisabled": false,
        "lockScale": false,
        "percentage": false,
        "percentageDisabled": false,
        "log": false,
        "logDisabled": false,
        "alignLabels": true,
        "isInverted": false,
        "indexedTo100": false
    }
}