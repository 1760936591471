import React from "react";
import Header from "./Header";
import Nav from "../navigation/index";

const Layout = ({ user, breadcrumbItems, children }) => {
  return (
    <>
      <div className="layout">
        <div className="layout__container">
          <Nav user={user} breadcrumbItems={breadcrumbItems} />

          <main className="main">
            <Header user={user} breadcrumbItems={breadcrumbItems} />

            <div className="main__router">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
