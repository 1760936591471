import { mainChartApiUpdateHelper } from "../helpers/api/mainChartApiUpdateHelper";

class FullChartProcessorClass {
  constructor(
    nearRealTimeState,
    layoutList,
    templateList,
    setTemplateList,
    setLayoutList,
    setChartHasChanged
  ) {
    this.nearRealTimeState = nearRealTimeState;
    this.layoutList = layoutList;
    this.setLayoutList = setLayoutList;
    this.templateList = templateList;
    this.setTemplateList = setTemplateList;
    this.setChartHasChanged = setChartHasChanged;
  }

  nearRealTimeDataUpdateHelper = () => {
    mainChartApiUpdateHelper.event("main_chart_data", JSON.stringify(this.nearRealTimeState));
  };

  updateChartLists = (type, data) => {
    mainChartApiUpdateHelper.event(type, JSON.stringify(data));
  };

  getAllCharts = () => {
    return Promise.resolve(this.layoutList);
  };

  saveChart = (chartData) => {
    if (!chartData.id) {
      chartData.id = Math.random().toString();
    } else {
      this.removeChart(chartData.id);
    }

    const savedChartData = {
      ...chartData,
      id: chartData.id,
      timestamp: Math.round(Date.now() / 1000),
    };

    if (this.layoutList.push(savedChartData)) {
      this.setLayoutList(this.layoutList);
      this.updateChartLists("layouts", this.layoutList);
      this.setChartHasChanged(true);
    }
  };
  removeChart = (id) => {
    this.layoutList.filter((item) => item.id !== id);

    for (var i = 0; i < this.layoutList.length; ++i) {
      if (this.layoutList[i].id === id) {
        this.layoutList.splice(i, 1);
      }
    }

    this.setLayoutList(this.layoutList);
    this.updateChartLists("layouts", this.layoutList);
    this.setChartHasChanged(true);
  };

  getChartContent = (id) => {
    for (var i = 0; i < this.layoutList.length; ++i) {
      if (this.layoutList[i].id === id) {
        return Promise.resolve(this.layoutList[i].content);
      }
    }

    return Promise.reject();
  };

  getAllChartTemplates = (templateList) => {
    return templateList.map((x) => x.name);
  };

  saveChartTemplate = (templateName, content) => {
    const theme = this.templateList.find((x) => x.name === templateName);

    if (theme) {
      theme.content = content;
      this.setTemplateList(this.templateList);
      this.setChartHasChanged(true);
      this.updateChartLists("template", this.templateList);
    } else {
      this.templateList.push({ name: templateName, content });
      this.setTemplateList(this.templateList);
      this.updateChartLists("template", this.templateList);
      this.setChartHasChanged(true);
    }
  };

  removeChartTemplate = (newTemplateList) => {
    this.templateList = newTemplateList;
    this.setTemplateList(this.templateList);
    this.updateChartLists("template", newTemplateList);
    this.setChartHasChanged(true);
  };

  getChartTemplateContent(templateName) {
    const theme = {};
    const content = this.templateList.find((x) => x.name === templateName)?.content;

    if (content) {
      theme.content = structuredClone(content);
    }

    return theme;
  }
}

export default FullChartProcessorClass;
