import { callRestGet, callRestPost } from "../../utils/callRestApi";

export const pairsApi = {
  pairs: async (type = "pairs") => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/pairs?type=${type}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  addNew: async (body) => {
    const result = { data: null, error: null };
    await callRestPost(`/dashboard/pairs`, body, null)
      .then((res) => {
        if (res.status === 201) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;
  },
};
