export const allPointsHaveValues = (points) => {
  return Object.values(points).every((arr) => {
    return arr.some((item) => item?.value?.trim() !== "");
  });
};

export const validatePriceConditions = (positionType, points, setErrorMessage) => {
  const omitInvalidValues = (points) => {
    return points
      .map((point) => parseFloat(point.value))
      .filter((value) => !isNaN(value));
  };
  const epPrices = omitInvalidValues([...points.ep]);

  const tpPrices = omitInvalidValues([...points.tp]);

  const slPrices = omitInvalidValues([...points.sl]);

  const isTpGreaterThanEp = tpPrices.every((tp) => epPrices.every((ep) => tp > ep));
  const isTpLessThanEp = tpPrices.every((tp) => epPrices.every((ep) => tp < ep));
  const isSlLessThanEp = slPrices.every((sl) => epPrices.every((ep) => sl < ep));
  const isSlGreaterThanEp = slPrices.every((sl) => epPrices.every((ep) => sl > ep));
  if (positionType === "long") {
    if (!isTpGreaterThanEp)
      setErrorMessage({
        points: "TP points should be greater than EPs",
      });
    else if (!isSlLessThanEp)
      setErrorMessage({
        points: "SL points should be less than EPs",
      });
  } else if (positionType === "short") {
    if (!isTpLessThanEp)
      setErrorMessage({
        points: "TP points should be less than EPs",
      });
    else if (!isSlGreaterThanEp)
      setErrorMessage({
        points: "SL points should be greater than EPs",
      });
  }
};
