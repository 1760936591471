import { callRestPost } from "../../utils/callRestApi";

export const vmcApi = {
  inputData: async (body) => {
    const result = { data: null, error: null };

    await callRestPost(`/dashboard/volume/market/cap`, body, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  chart: async (body) => {
    const result = { data: null, error: null };
    await callRestPost(`/dashboard/volume/market/cap/chart`, body, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
