import { useEffect } from "react";

export const useOnBeforeUnload = (
  nearRealTimeDataUpdateHelper,
  nearRealTimeState,
  chartHasChanged
) => {
  useEffect(() => {
    const onBeforeUnload = (event) => {
      event.preventDefault();
      nearRealTimeDataUpdateHelper();
    };
    chartHasChanged && window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [nearRealTimeState]);
};
