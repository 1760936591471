import React, { useState } from "react";

import arrowRight from "../../assets/icon/arrows/arrow-right.svg";

export const RightNav = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <nav
      className={`${className ? className : ""} rightNav ${
        isOpen ? "rightNav__isOpen" : "rightNav__isClose"
      }`}
    >
      <button className="rightNav__arrowBtn" onClick={() => setIsOpen(!isOpen)}>
        <img src={arrowRight} alt="arrow icon" />
      </button>
      {isOpen && <div className="rightNav__children scrollbar-style scrollbar-style-thumb">{children}</div>}
    </nav>
  );
};
