import { validateInput } from "../helpers/tools/validateInput";

export const createWidgetLabelOverrides = (type, additionalOverrides) => {

  switch (type) {

    case "trendLineHorzHeatMap":
      return {
        showLabel: true,
        horzLabelsAlign: "center",
        vertLabelsAlign: "middle",
        ...additionalOverrides,
      };
      case "rectangleHorzHeatMap":
      return {
        showLabel: true,
        horzLabelsAlign: "center",
        vertLabelsAlign: "middle",
        ...additionalOverrides,
      };
      case "trendLineSetup":
      return {
        showLabel: true,
        horzLabelsAlign: "center",
        fontsize: "22",
        vertLabelsAlign: "middle",
        ...additionalOverrides,
      };
    case "verticalLineHeatMap":
      return {
        showLabel: true,
        horzLabelsAlign: "center",
        vertLabelsAlign: "middle",
        ...additionalOverrides,
      };
    default:
      validateInput(null, 'type', 'createWidgetLabelOverrides', `Invalid type: ${type}`)
  }
};
