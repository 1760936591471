import React, { createContext, useState, useEffect } from "react";
import { usersApi } from "../../api/dashboard/usersApi";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      const result = await usersApi.profile();
      if (result?.data) {
        setUser(result.data.data);
      } else if (result.error) setError(result.error);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(error);
      setLoading(false);
    }
  };

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  useEffect(() => {
    if (accessToken && accessToken.length > 0) fetchUserData();
  }, [accessToken]);

  return (
    <UserContext.Provider value={{ user, loading, error, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
