import React, { useState, useEffect } from "react";

import { commentApi } from "../../api/dashboard/analysis/commentApi";

import { MessageInput } from "./MessageInput";

import { Loading } from "./ui/Loading";

import avatar from "../../assets/img/avatar.png";

const Comment = ({ id, section, permissions }) => {
  const [showNewCommentBox, setShowNewCommentBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [commentMessage, setCommentMessage] = useState("");
  const [commentList, setCommentList] = useState({
    items: [],
    totalNumbers: 0,
  });
  const [loadMore, setLoadMore] = useState({ itemNumbers: 1, show: true });

  const analysisFetchComments = async () => {
    try {
      const result = await commentApi.list(id, section);

      setCommentList({
        items: result?.data?.data?.slice(0, loadMore.itemNumbers * 3),
        totalNumbers: result?.data?.data?.length,
      });
      if (loadMore.itemNumbers * 3 >= result?.data?.data?.length)
        setLoadMore({ ...loadMore, show: false });
      else setLoadMore({ ...loadMore, show: true });

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (id && permissions?.comment_list) analysisFetchComments();
  }, [id, loadMore.itemNumbers, permissions]);

  const onSendMessage = () => {
    commentApi
      .createNew(
        {
          comment_text: commentMessage,
        },
        id,
        section
      )
      .then((res) => {
        if (res && !res.error && res.data) {
          setCommentMessage("");
          analysisFetchComments();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  return (
    <div className="comment">
      <div className="comment__header">
        <h4>
          Comment
          {commentList?.totalNumbers && !isLoading ? (
            <span>({commentList?.totalNumbers} comments)</span>
          ) : (
            ""
          )}
        </h4>
        <button
          onClick={() => setShowNewCommentBox(true)}
          className="btn-cstm btn-dark-cstm"
        >
          New Comment
        </button>
      </div>
      {commentList?.items?.length > 0 && !isLoading ? (
        <div className="comment__list">
          <ul className="scrollbar-style scrollbar-style-thumb">
            {commentList.items.map((item, index) => (
              <li key={index} className="comment__list__item">
                <div className="comment__list__item__title">
                  <img src={avatar} alt="avatar" />
                  <p>
                    {item.user.first_name} {item.user.last_name}
                  </p>
                  <span>{item.date_time}</span>
                </div>
                {item.text}
              </li>
            ))}
          </ul>
          {loadMore.show && (
            <button
              onClick={() =>
                setLoadMore({
                  ...loadMore,
                  itemNumbers: loadMore.itemNumbers + 1,
                })
              }
              className="comment__loadMore"
            >
              Load more
            </button>
          )}
        </div>
      ) : !isLoading ? (
        <div className="comment__emptyList">
          <h4 className="comment__emptyList__title">
            There isn't any comment for this {section}!
          </h4>
        </div>
      ) : (
        <Loading size="small" />
      )}
      {permissions?.new_comment && (
        <div
          className={`comment__newComment ${
            showNewCommentBox
              ? "comment__newComment__show"
              : "comment__newComment__hide"
          }`}
        >
          <h4>New Comment</h4>
          <MessageInput
            handleSubmit={onSendMessage}
            value={commentMessage || ""}
            setValue={setCommentMessage}
          />
        </div>
      )}
    </div>
  );
};

export default Comment;
