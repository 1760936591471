import { validateInput } from "../helpers/tools/validateInput";



const convertUTCDateToUnix = (date) => {
  const initDate = date ? new Date(date) : new Date();
  
  const year = initDate.getUTCFullYear();
  const month = initDate.getUTCMonth() + 1;
  const day = initDate.getUTCDate();
  
  const timestamp = Date.UTC(year, month - 1, day) / 1000;
  return Math.floor(timestamp);
};

const convertStandardDateToUnix = (date) => {
  const timestamp = new Date(date).getTime() / 1000;
  if (isNaN(timestamp)) {
    throw new Error("Invalid date format for mode 1.");
  }

  return Math.floor(timestamp);
};

export const convertDateToUnixTime = (mode, date) => {
  if(!date) {
    throw new Error("Invalid date");
  }
  if (mode === 0) {
    return convertUTCDateToUnix(date);
  } else if (mode === 1) {
    return convertStandardDateToUnix(date);
  }

  validateInput(null,null, 'convertDateToUnixTime', `Invalid mode: Mode must be 0 (UTC format) or 1 (Standard format).`)
  
};
