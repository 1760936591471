import React from "react";

function GroupArrowRightSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      fill="none"
      s
      viewBox="0 0 8 15"
      className={className || ""}
    >
      <path
        stroke="#349D57"
        strokeLinecap="round"
        d="M1 1l4.793 4.793a1 1 0 010 1.414L1 12M7 9l-5 5"
      ></path>
    </svg>
  );
}

export default GroupArrowRightSvg;
