export class RotationsRenderClass {
  constructor(tvChart, pair, imbalanceValue) {
    this.tvChart = tvChart;
    this.pair = pair;
    this.imbalanceValue = imbalanceValue;
  }

  action = (rotationsList) => {
    let chart = this.tvChart.current.chart();

    rotationsList && rotationsList.forEach((rotationsItem) => {
      chart.createMultipointShape(rotationsItem.points, {
        shape: "rectangle",
        lock: true,
        disableUndo: true,
        disableSelection: true,
        disableSave: true,
        overrides: {
          backgroundColor: "rgb(81, 200, 255 , 50%)",
          linewidth: 0,
        },
      });
    });
  };
}
