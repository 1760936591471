import React, { useState } from "react";

import { PairsSymbol } from "~/components/child/Symbols";

import bottomNavigationSvg from "~/assets/icon/arrows/bottomNavigation.svg";
import topNavigationSVG from "~/assets/icon/arrows/topNavigation.svg";

const Status = ({ icon, date, time }) => {
  return (
    <div className="flex w-full font-roboto items-center gap-1 justify-start">
      {date}
      <span className="text-lg">.</span>
      {time}
      <span className="flex p-2 ml-0.5 bg-sky-10 rounded-xl">
        <img src={icon} alt="icon" className="" />
      </span>
    </div>
  );
};

export const Cart = ({ pair, timeFrame, breakTime, breakType }) => {
  return (
    <div className="flex flex-col items-center justify-between gap-2">
      <div className="flex w-full items-center justify-between gap-4">
        <PairsSymbol
          pair={pair?.key}
          image={[pair.bade_image, pair.quote_asset]}
        />
        <span className="py-1.5 rounded-2xl px-2.5 text-storm-50 bg-storm-20">
          {timeFrame}
        </span>
      </div>
      <div className="flex items-center gap-8 w-full">
        <Status
          date={breakTime.split(" ")[0]}
          time={breakTime.split(" ")[1]}
          icon={breakType === "DOWN" ? bottomNavigationSvg : topNavigationSVG}
        />
      </div>
    </div>
  );
};

export const SecondStep = ({
  vmcData,
  setVmcData,
  onSelectCard,
  isLoading,
}) => {
  const [selectedCarts, setSelectedCarts] = useState([]);

  const handleCartClick = (item, isSelected) => {
    if (!isSelected) {
      setSelectedCarts((prevState) => [
        ...prevState,
        { pair: item.pair.key, interval: item.time_frame },
      ]);
      onSelectCard(item.pair, item.time_frame);
    } else {
      setSelectedCarts((prevState) =>
        prevState.filter(
          (cart) =>
            !(cart.pair === item.pair.key && cart.interval === item.time_frame)
        )
      );
      setVmcData((prevState) => ({
        ...prevState,
        charts: prevState.charts.filter((chart) => {
          return !(
            Object.keys(chart)[0] === item.pair.key &&
            Object.keys(Object.values(chart)[0])[0] === item.time_frame
          );
        }),
      }));
    }
  };
  return (
    <>
      <p className="text-xs text-oil-50">Select up to 4 to display the chart</p>
      {vmcData?.carts?.map((item, index) => {
        const isSelected = selectedCarts.some(
          (cart) =>
            cart.pair === item.pair.key && cart.interval === item.time_frame
        );
        return (
          <button
            key={index}
            className={`p-2 disabled:cursor-not-allowed text-xs border rounded-lg ${
              isSelected ? "border-sky-40" : "border-storm-20"
            }`}
            onClick={() => handleCartClick(item, isSelected)}
            disabled={isLoading}
          >
            <Cart
              pair={item.pair}
              timeFrame={item.time_frame}
              breakTime={item.break_time}
              breakType={item.break_type}
            />
          </button>
        );
      })}
    </>
  );
};
