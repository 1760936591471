import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { authApi } from "../../api/auth/authApi";

const ProtectedRoute = ({ children, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const accessTokenExpiration = useMemo(
    () => new Date(localStorage.getItem("accessTokenExpiration")),
    [isAuthenticated]
  );

  const refreshTokenExpiration = useMemo(
    () => new Date(localStorage.getItem("refreshTokenExpiration")),
    [isAuthenticated]
  );
  const currentDate = useMemo(() => new Date(), [isAuthenticated]);

  useEffect(() => {
    if (accessToken) {
      if (currentDate.getTime() <= accessTokenExpiration.getTime()) {
        setIsAuthenticated(true);
      } else if (
        currentDate.getTime() > accessTokenExpiration.getTime() &&
        currentDate.getTime() <= refreshTokenExpiration.getTime()
      ) {
        const headers = {
          Authorization: `Bearer ${refreshToken}`,
        };

        authApi
          .refreshToken(headers)
          .then((res) => {
            if (res && !res.error && res.data) {
              localStorage.setItem("accessToken", res.data.meta.access_token);
              localStorage.setItem(
                "accessTokenExpiration",
                res.data.meta.access_token_expiration
              );

              setIsAuthenticated(true);
            }
            if (res.error) navigate("/login");
          })
          .catch((error) => {
            console.error("Error refreshing token:", error);
          });
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [
    accessToken,
    currentDate,
    accessTokenExpiration,
    refreshTokenExpiration,
    refreshToken,
    navigate,
  ]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return null;
};

export default ProtectedRoute;
