import React from "react";

function WarningOne() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FBAB5B"
        d="M8.001 15.501a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5zm0-14a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5z"
      ></path>
      <path
        fill="#FBAB5B"
        d="M8.001 12.18a.5.5 0 01-.5-.5V6.997a.5.5 0 111 0v4.683a.5.5 0 01-.5.5zM8 5.505a.659.659 0 01-.19-.04.462.462 0 01-.16-.11.4.4 0 01-.11-.16.433.433 0 01-.04-.19.475.475 0 01.15-.36.658.658 0 01.16-.11.5.5 0 01.38 0 .638.638 0 01.16.11.473.473 0 01.15.36.433.433 0 01-.04.19.575.575 0 01-.11.16.453.453 0 01-.16.11.652.652 0 01-.19.04z"
      ></path>
    </svg>
  );
}

export default WarningOne;
