import React from "react";
import { useImbalanceInputCalculator } from "../helpers";
import RightCard from "./RightCard";
import LeftCard from "./LeftCard";
import PropTypes from 'prop-types';

const Header = ({
  chartRef,
  pair,
  setPair,
  isPlotZones,
  setIsPlotZones,
  imbalanceValue,
  setImbalanceValue,
  theme,
}) => {

  useImbalanceInputCalculator(imbalanceValue, setImbalanceValue); 

  return (
    <div
      className={`flex justify-between border-y-4 p-1 
      ${
        theme === "dark"
          ? "bg-chartDark-key border-chartDark-10"
          : "bg-storm-light border-storm-20"
      }`}
    >
      <LeftCard setPair={setPair} theme={theme} />

      <RightCard
        pair={pair}
        imbalanceValue={imbalanceValue}
        setImbalanceValue={setImbalanceValue}
        isPlotZones={isPlotZones}
        setIsPlotZones={setIsPlotZones}
        chartRef={chartRef}
      />
    </div>
  );
};

export default Header;
Header.propTypes = {
  chartRef: PropTypes.object.isRequired,
  pair: PropTypes.string.isRequired,
  setPair: PropTypes.func.isRequired,
  isPlotZones: PropTypes.bool.isRequired,
  setIsPlotZones: PropTypes.func.isRequired,
  imbalanceValue: PropTypes.number.isRequired,
  setImbalanceValue: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

