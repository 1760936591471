import React, { useState, useEffect, useRef } from "react";
import Pagination from "rc-pagination/lib/Pagination";

import { NavLink, useNavigate } from "react-router-dom";

import { listApi } from "~/api/dashboard/analysis/listApi";

import MoreOneSvg from "~/assets/icon/svgJsx/MoreOneSvg";
import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import ArrowLineRightSvg from "~/assets/icon/svgJsx/ArrowLineRightSvg";
import filterSvg from "~/assets/icon/filter.svg";

import { Filter } from "~/components/child/Filter";
import { Loading } from "~/components/child/ui/Loading";

import { transformedFilterItems } from "~/utils/getTransformedData";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

const AnalysisList = ({ user, permissions }) => {
  const [appliedFilters, setAppliedFilters] = useState({
    pairs: [],
    date: { from: undefined, to: undefined },
    analyzers: [],
    type: [],
    timeFrame: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterItemsCount, setFilterItemsCount] = useState(0);
  const [showMoreActionsTooltipId, setShowMoreActionsTooltipId] = useState(null);

  const submenuRef = useRef(null);
  const navigate = useNavigate();

  const fetchAnalysisList = async (page) => {
    setIsLoading(true);
    try {
      const result = await listApi(page, transformedFilterItems(appliedFilters));

      setListItems(result.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (currentPage && permissions?.analytics_list) fetchAnalysisList(currentPage);
  }, [permissions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchAnalysisList(page);
  };

  useEffect(() => {
    if (!showFilter) {
      setFilterItemsCount(0);
      for (const key in appliedFilters) {
        if (Array.isArray(appliedFilters[key])) {
          setFilterItemsCount(
            (prevFilterItemsCount) => (prevFilterItemsCount += appliedFilters[key].length)
          );
        } else {
          if (appliedFilters.date.from)
            setFilterItemsCount((prevFilterItemsCount) => {
              return prevFilterItemsCount + 1;
            });
          if (appliedFilters.date.to)
            setFilterItemsCount((prevFilterItemsCount) => {
              return prevFilterItemsCount + 1;
            });
        }
      }
    }
  }, [appliedFilters, showFilter]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setShowMoreActionsTooltipId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="main__router__container">
      {showFilter && (
        <Filter
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          isOpen={showFilter}
          setIsOpen={setShowFilter}
          onSubmitFilters={() => {
            setCurrentPage(1);
            fetchAnalysisList(1);
          }}
          totalResults={listItems?.meta?.total}
          fullAccess={permissions.full_access}
        />
      )}

      <div className="main__router__container__list-box-container">
        {permissions?.analytics_filter && (
          <button className="filterBtn" onClick={() => setShowFilter(true)}>
            <img src={filterSvg} alt="filter icon" />
            Filter
            {filterItemsCount > 0 && <span>{filterItemsCount} Items</span>}
            <ArrowLineRightSvg />
          </button>
        )}
        {!isLoading && listItems?.data?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
              <table className="list-table-cstm-one__content-table">
                <thead>
                  <tr>
                    <th>Row</th>
                    <th>User</th>
                    <th>Type</th>
                    <th>Pair</th>
                    <th>Timeframe</th>
                    <th>Date</th>
                    <th>Detail</th>
                    <th>...</th>
                  </tr>
                </thead>

                <tbody>
                  {listItems?.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>
                        {item.analysis?.user?.first_name} {item.analysis?.user?.last_name}
                      </td>

                      <td className="list-table-cstm-one__content-table__flex">
                        <div className="list-table-cstm-one__content-table__analysis-type rounded-3xl text-xs font-normal bg-storm-20 text-storm-50">
                          {changeWordFormatToTitleCase(
                            item.analysis?.analysis_type
                          ).replace("_", "")}
                        </div>
                        {item.analysis?.position_type && (
                          <div
                            className={`list-table-cstm-one__content-table__position-type ${item.analysis?.position_type?.toLowerCase()}`}
                          >
                            {changeWordFormatToTitleCase(item.analysis?.position_type)}
                          </div>
                        )}
                      </td>

                      <td> {item.analysis?.pair?.pair}</td>
                      <td>
                        {item.analysis?.analysis_type === "TECHNICAL"
                          ? item.analysis?.time_frame?.time_frame
                          : "-"}
                      </td>

                      <td className="list-table-cstm-one__content-table__date-time">
                        <div className="list-table-cstm-one__content-table__date-time__date">
                          {item.analysis?.created_at?.split(" ")[0]}
                        </div>
                        <div className="list-table-cstm-one__content-table__date-time__time">
                          {item.analysis?.created_at?.split(" ")[1]}
                        </div>
                      </td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <NavLink
                          to={`/${item.analysis?.analysis_type
                            .replace(/_/g, "")
                            ?.toLowerCase()}-view/${item.analysis?.uuid}`}
                          className="flex justify-center items-center"
                          target="_blank"
                        >
                          <ArrowRightSvg />
                        </NavLink>
                      </td>
                      <td className="list-table-cstm-one__content-table__more flex justify-center relative">
                        {showMoreActionsTooltipId === item.analysis?.uuid && (
                          <div
                            ref={submenuRef}
                            className=" absolute w-24 top-[20%] right-[60%] bg-white p-2 z-10 shadow-[0_1px_8px_0_rgba(0,0,0,.122)] py-2 px-3 bg-storm-light rounded-lg"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                navigate(`/?id=${item.analysis?.uuid}`);
                                setShowMoreActionsTooltipId(null);
                              }}
                              className="text-storm-key flex font-yekanBakh text-base font-bold text-left w-full leading-6"
                            >
                              Edit
                            </button>
                          </div>
                        )}
                        {item.analysis?.analysis_type.toLowerCase() === "technical" &&
                          user?.uuid === item?.analysis?.user?.uuid &&
                          !item?.is_closed &&
                          item?.status !== "win" &&
                          item?.status !== "lose" && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();

                                setShowMoreActionsTooltipId((prevId) =>
                                  prevId === item.analysis?.uuid
                                    ? null
                                    : item.analysis?.uuid
                                );
                              }}
                              className="header__box__flex-right__userInfo__profile"
                            >
                              <MoreOneSvg />
                            </button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {listItems?.meta?.total > 10 && (
              <div className="pagination-one">
                <Pagination
                  current={currentPage}
                  total={listItems?.meta?.total}
                  pageSize={10}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  prevIcon={<ArrowLeftSvg />}
                  nextIcon={<ArrowRightSvg />}
                />
              </div>
            )}
          </div>
        ) : !isLoading ? (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">No results!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default AnalysisList;
