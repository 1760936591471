import React from "react";
import AnalysisHeader from "~/components/child/AnalysisHeader";
import FundamentalEntry from "~/components/child/FundamentalEntry";
import ArrayImg from "~/components/child/ui/imageUpload/Preview";

const Preview = ({ analysisValues }) => {
  return (
    <div className="fundamental-preview__box">
      <div className="fundamental-preview__box__title">your analysis Preview</div>
      <AnalysisHeader
        pairs={analysisValues.new.pair}
        type="Fundamental"
        positionType={
          analysisValues.new.positionType !== "-" && analysisValues.new.positionType
        }
      />
      <p className="fundamental-preview__box__description mt-2">
        {analysisValues.fundamental.description}
      </p>
      <div className="fundamental-preview__box__entries">
        <div className="fundamental-preview__box__entries__title">
          <span>your entries</span>
        </div>

        {analysisValues.fundamental.entries.length > 0 &&
          analysisValues.fundamental.entries?.map((item) => (
            <div key={item?.id}>
              <FundamentalEntry
                id={item?.id}
                label={item?.title}
                date={item.date}
                description={item?.tooltip}
              />
            </div>
          ))}
      </div>
      <div className="mb-8">
        <ArrayImg
          isEditable={false}
          images={analysisValues.fundamental?.images}
          className={{
            info: "text-sm !gap-2",
            image: "w-16 h-16",
            actions: "!items-end !gap-2 flex-col",
            primary: { label: " text-[0.625rem]", icon: "w-5 h-5" },
          }}
        />
      </div>
    </div>
  );
};

export default Preview;
