import { validateInput } from "../../helpers/tools/validateInput";
import { defaultChartTypeSource } from "../defaultChartTypeSource";

export function initializeChartSource(chartSourceList) {
  validateInput(chartSourceList, "chartSourceList", "initializeChartSource");

  let chartSource = JSON.parse(JSON.stringify([...chartSourceList][0]));

  chartSource?.charts[0]?.panes.forEach((pane) => {
    pane?.sources.forEach((source) => {
      if (source.id === "_seriesId") {
        pane.sources = pane.sources.filter(
          (source) =>
            source.type === "MainSeries" || source.type === "study_Volume"
        );
        chartSource.charts[0].panes = [pane];
      }
    });
  });

  return chartSource;
}

export function extractPanesFromChartSources(chartSourceList) {
  validateInput(
    chartSourceList,
    "chartSourceList",
    "extractPanesFromChartSources"
  );

  let panesList = [];

  chartSourceList.forEach((chartSource) => {
    let source = chartSource?.charts[0]?.panes;
    panesList.push(...source);
  });

  return panesList;
}

export function extractPaneTypes(panesList) {
  validateInput(panesList, "panesList", "extractPaneTypes");

  const priceStudyAndWidgetPanes = panesList.filter(
    (pane) => pane.mainSourceId === "_seriesId"
  );
  const nonPriceStudyPanes = panesList.filter(
    (pane) => pane.mainSourceId !== "_seriesId"
  );

  return { priceStudyAndWidgetPanes, nonPriceStudyPanes };
}

export function extractWidgetAndPriceStudies(priceStudyAndWidgetPanes) {
  validateInput(
    priceStudyAndWidgetPanes,
    "priceStudyAndWidgetPanes",
    "extractWidgetAndPriceStudies"
  );

  let widgetAndPriceStudyList = [];

  priceStudyAndWidgetPanes.forEach((pane) => {
    let priceStudyPane = pane?.sources;
    widgetAndPriceStudyList.push(...priceStudyPane);
  });
  return widgetAndPriceStudyList;
}

export function removeMainSeriesAndStudyVolume(widgetAndPriceStudyList) {
  validateInput(
    widgetAndPriceStudyList,
    "widgetAndPriceStudyList",
    "removeMainSeriesAndStudyVolume"
  );

  return widgetAndPriceStudyList.filter(
    (item) => item.type !== "MainSeries" && item.type !== "study_Volume"
  );
}

export function appendSanitizedSourcesToChartSource(
  chartSource,
  sanitizedChartSource
) {
  const functionName = appendSanitizedSourcesToChartSource.name;
  validateInput(chartSource, "chartSource", functionName);
  validateInput(sanitizedChartSource, "sanitizedChartSource", functionName);

  if (chartSource?.charts[0]?.panes[0]?.sources) {
    chartSource.charts[0].panes[0].sources.push(...sanitizedChartSource);
  }
  return chartSource;
}

export function appenNonPriceStudyPanesToChartSource(
  chartSource,
  nonPriceStudyPanes
) {
  const functionName = appenNonPriceStudyPanesToChartSource.name;
  validateInput(chartSource, "chartSource", functionName);
  validateInput(nonPriceStudyPanes, "nonPriceStudyPanes", functionName);

  chartSource?.charts[0]?.panes.push(...nonPriceStudyPanes);
  return chartSource;
}

export function extractPriceStudyIds(chartSource) {
  validateInput(chartSource, "chartSource", "extractPriceStudyIds");

  let studyPriceIdList = [];

  chartSource?.charts[0]?.panes[0]?.sources.forEach((source) => {
    if (
      source.type === "Study" ||
      source.type === "study_VbPVisible" ||
      source.type === "study_PivotPointsStandard"
    ) {
      studyPriceIdList.push(source.id);
    }
  });

  return studyPriceIdList;
}

export function appendPriceStudyIdsToRightAxisesState(
  chartSource,
  studyPriceIds
) {
  const functionName = appendPriceStudyIdsToRightAxisesState.name;

  validateInput(chartSource, "chartSource", functionName);
  validateInput(studyPriceIds, "studyPriceIds", functionName);

  chartSource?.charts[0]?.panes[0]?.rightAxisesState[0]?.sources.push(
    ...studyPriceIds
  );

  return chartSource;
}

export function setCandlestickChartType(chartSource) {
  validateInput(chartSource, "chartSource", "setCandlestickChartType");

  chartSource.charts[0].panes[0].sources[0].state = defaultChartTypeSource;
  return chartSource;
}
