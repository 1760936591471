import { timezoneListCustom } from '../helpers/tools/timezoneListCustom'
import { validateInput } from '../helpers/tools/validateInput';

export const convertChartTimeZone = (chartSource, code) => {

  validateInput(timezoneListCustom[code], 'code', 'convertChartTimeZone', `Timezone with code ${code} is not supported or does not exist.`)
  chartSource?.charts.forEach((chart) => {
    if (chart?.timezone) {
      chart.timezone = timezoneListCustom[code];
    }
  });
  return chartSource;
};
