import React from "react";

import InputBase from "~/components/child/ui/InputBase";

export const Step1 = ({ setSignUpData, signUpData }) => {
  return (
    <>
      <div className="flex flex-col items-center gap-4">
        <p className=" font-roboto text-sky-80 text-xl">
          <b className="font-robotoMed">B</b>attle of <b className="font-robotoMed">T</b>
          raders, <b className="font-robotoMed">W</b>
          in the <b className="font-robotoMed">W</b>ar!
        </p>
        <h4 className=" text-lg text-sky-70">Sign Up</h4>
      </div>
      <div className="flex w-full flex-col gap-4 md:gap-8">
        <div className="flex flex-col md:flex-row w-full items-center gap-4">
          <InputBase
            title="First name"
            value={signUpData.firstName}
            setValue={(e) =>
              setSignUpData({
                ...signUpData,
                firstName: e.target.value,
              })
            }
            className={{
              root: "w-[100%]",
              input: "!text-left !w-[95%]",
              title: "text-sm text-sky-60 -top-2.5",
            }}
            placeholder="Exp. Zohre"
          />
          <InputBase
            title="Last name"
            value={signUpData.lastName}
            setValue={(e) =>
              setSignUpData({
                ...signUpData,
                lastName: e.target.value,
              })
            }
            className={{
              root: "w-[100%]",
              input: "!text-left !w-[95%]",
              title: "text-sm text-sky-60 -top-2.5",
            }}
            placeholder="Exp. Mohajeri"
          />
        </div>
        <InputBase
          title="Phone number"
          value={signUpData.phoneNumber}
          setValue={(e) =>
            setSignUpData({
              ...signUpData,
              phoneNumber: e.target.value,
            })
          }
          className={{
            root: "w-[100%]",
            input: "!text-left !w-[95%]",
            title: "text-sm text-sky-60 -top-2.5",
          }}
          placeholder="Exp. 09123456789"
          maxLength={11}
          minLength={11}
        />
        <InputBase
          title="Email"
          value={signUpData.mail}
          setValue={(e) =>
            setSignUpData({
              ...signUpData,
              mail: e.target.value,
            })
          }
          className={{
            root: "w-[100%]",
            input: "!text-left !w-[95%]",
            title: "text-sm text-sky-60 -top-2.5",
          }}
          placeholder="Exp. example@gmail.com"
        />
      </div>
    </>
  );
};
