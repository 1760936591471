import React from "react";

const Switch = ({ isOn, setIsOn, label }) => {
  return (
    <div className="flex gap-1 items-center text-sm font-roboto text-storm-key">
      <div
        onClick={() => setIsOn(!isOn)}
        className={` w-12 h-6 rounded-3xl cursor-pointer relative ${
          isOn ? "bg-sky-key" : "border-sky-key border"
        }`}
      >
        <div
          className={` w-5 h-5 rounded-full transition-colors absolute top-0.5 left-0.5 ${
            isOn ? "bg-base translate-x-6" : " bg-sky-key"
          }`}
        ></div>
      </div>
      {label || ""}
    </div>
  );
};

export default Switch;
