import React from "react";

const Breadcrumb = ({ steps }) => {
  const activeStepIndex = steps.findIndex((step) => step.active === 1);

  return (
    <nav className="breadcrumb mb-8">
      <ol className="breadcrumb__list">
        {steps.map((step, index) => (
          <>
            <li
              key={index}
              className={`breadcrumb__list__item ${
                index <= activeStepIndex ? "breadcrumb__list__item__active" : ""
              }`}
            >
              <div
                className={`breadcrumb__list__item__bullet ${
                  index <= activeStepIndex ? "breadcrumb__list__item__active__bullet" : ""
                }`}
              />
            </li>
            {index < steps.length - 1 && (
              <div
                key={`line_${index}`}
                className={`breadcrumb__list__item__line ${
                  index < activeStepIndex ? "breadcrumb__list__item__active__line" : ""
                }`}
              />
            )}
          </>
        ))}
      </ol>
      <div className="breadcrumb__title-list">
        {steps.map((step, index) => (
          <span
            key={index}
            className={`breadcrumb__title-list__label ${
              index === activeStepIndex ? "breadcrumb__title-list__label__active" : ""
            } ${
              index < activeStepIndex
                ? "breadcrumb__title-list__label__before-active"
                : ""
            }`}
          >
            {step.label}
          </span>
        ))}
      </div>
    </nav>
  );
};

export default Breadcrumb;
