import React, { useState, useEffect } from "react";
import Dropdown from "~/components/child/ui/DropDown";
import InputBase from "~/components/child/ui/InputBase";
import Button from "~/components/child/ui/Button";

import { useKeySubmission } from "~/utils/hooks";

import TrashSvg from "~/assets/icon/svgJsx/TrashSvg";

const AmountOptionItem = (item, isIncluded, onClickHandler) => {
  return (
    <button
      onClick={() => onClickHandler(item, isIncluded)}
      className={`flex w-full items-center justify-between py-2.5 font-roboto`}
      key={item.key}
    >
      <div className="flex gap-1 items-center">
        <span
          className={`${
            isIncluded ? "bg-sky-key" : "bg-inherit"
          } w-4 h-4 border border-oil-key rounded-md`}
        />
        <p className="flex text-base text-black items-center gap-1">
          {item.value}
          {item?.mode === "range" && (
            <span className="text-storm-50 bg-base rounded-[2rem] text-xs py-0.5 px-1">
              Sub-Range
            </span>
          )}
          {item?.isClosed && (
            <span className="px-2 py-0.5 text-storm-50 rounded-xl bg-storm-20 text-xs font-roboto">
              Close
            </span>
          )}
          {item.rr && (
            <span className="px-2 rounded-md text-sm bg-[#CCE4FF] text-[#007BFF] py-0">
              R:R {item.rr}
            </span>
          )}
        </p>
      </div>

      <span className="text-storm-50 text-xs bg-storm-20 rounded-[2rem] py-1.5 px-2.5">
        {item.user.username}
      </span>
    </button>
  );
};

export const PointAmount = React.memo(
  ({
    label,
    labelColor,
    points,
    setSetupValues,
    selectedPoints,
    sortFunction,
    userId,
    onDeletePoint,
    calculateRiskToReward,
  }) => {
    const [pointIdCounter, setPointIdCounter] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const pointsPricesRegex = /^(\d{0,8}(\.\d{0,8})?)?$/;

    const optionsList = (points) => {
      return points?.map((pointItem, index) => {
        if (pointItem.max_amount !== 0)
          return [
            pointItem.base_amount,
            (pointItem.base_amount + pointItem.max_amount) / 2,
            pointItem.max_amount,
          ]?.map((rangeItem) => ({
            key: rangeItem.toString() + index,
            value: rangeItem,
            mode: "range",
            user: { id: pointItem.user_uuid, username: pointItem.user_name },
            isClosed: pointItem?.is_closed,
            rr: calculateRiskToReward ? calculateRiskToReward(rangeItem) : null,
          }));
        return {
          key: pointItem.base_amount.toString() + index,
          value: pointItem.base_amount,
          user: { id: pointItem.user_uuid, username: pointItem.user_name },
          isClosed: pointItem?.is_closed,
          rr: calculateRiskToReward ? calculateRiskToReward(pointItem.base_amount) : null,
        };
      });
    };

    const pointClickHandler = (item, isIncluded) => {
      if (selectedOptions.some((obj) => obj.key === item.key))
        setSelectedOptions((prevState) =>
          prevState.filter((option) => option.key !== item.key)
        );
      else if (!isIncluded)
        setSelectedOptions((prevState) => [
          ...prevState,
          {
            key: item.key,
            value: item.value,
            id: pointIdCounter,
            weight: undefined,
            user: item.user.id,
            isClosed: item.isClosed,
            rr: item?.rr,
          },
        ]);
      setPointIdCounter((prev) => prev + 1);
    };

    return (
      <div className="flex w-full flex-col gap-4">
        <Dropdown
          bgColor="base"
          multipleValues={true}
          title={
            <span
              className={`font-robotoMed text-sm text-${labelColor} py-0.5 px-1 rounded-2xl bg-storm-10`}
            >
              {label.toUpperCase()}
            </span>
          }
          placeholder={
            <span className="flex py-2.5 pl-3 text-sm text-storm-key ">
              Enter or Select amounts
            </span>
          }
          shouldShowSelectedValues={false}
          value={selectedPoints[label]}
          renderCustomOptionsList={
            <div className="flex flex-col">
              <div className="flex flex-col gap-4 ">
                <button
                  className="bg-sky-10 text-sky-key py-3 text-center text-sm font-robotoMed rounded-xl"
                  onClick={() => {
                    setSetupValues((prevState) => ({
                      ...prevState,
                      points: {
                        ...prevState.points,
                        [label]: [
                          ...prevState.points[label],
                          {
                            key: 0,
                            id: pointIdCounter,
                            value: undefined,
                            weight: undefined,
                            type: "custom",
                            user: userId,
                          },
                        ],
                      },
                    }));
                    setPointIdCounter((prev) => prev + 1);
                  }}
                >
                  add your own price
                </button>
                {points?.length > 0 && (
                  <span className="text-sm text-center font-roboto text-sky-key">
                    or choose from list
                  </span>
                )}
              </div>

              {optionsList(points)?.map((option, index) => {
                if (Array.isArray(option))
                  return (
                    <div
                      key={index}
                      className="flex my-2.5 rounded-lg bg-storm-10 p-2.5 flex-col"
                    >
                      {option?.map((item) =>
                        AmountOptionItem(
                          item,
                          selectedPoints[label]?.some((obj) => obj.key === item.key) ||
                            selectedOptions.some((obj) => obj.key === item.key),
                          pointClickHandler
                        )
                      )}
                    </div>
                  );
                return AmountOptionItem(
                  option,
                  selectedPoints[label]?.some((obj) => obj.key === option.key) ||
                    selectedOptions.some((obj) => obj.key === option.key),
                  pointClickHandler
                );
              })}
              {points?.length > 0 && (
                <div className="pt-1 sticky bottom-0 bg-base">
                  <button
                    className="py-2 w-full text-sm font-normal text-sky-key font-roboto border border-sky-key rounded-xl disabled:opacity-60 disabled:cursor-not-allowed"
                    disabled={selectedOptions.length === 0}
                    onClick={() => {
                      if (selectedOptions.length > 0) {
                        setSetupValues((prevState) => ({
                          ...prevState,
                          points: {
                            ...prevState.points,
                            [label]: sortFunction(
                              Math.max(...selectedOptions.map((item) => item.value)),
                              [...prevState.points[label], ...selectedOptions]
                            ),
                          },
                        }));
                        setSelectedOptions([]);
                      }
                    }}
                  >
                    add
                  </button>
                </div>
              )}
            </div>
          }
        />
        {selectedPoints[label].length > 0 &&
          selectedPoints[label]?.map((pointItem, index) => (
            <div key={index} className="flex items-center gap-2.5">
              <InputBase
                className={{
                  root: `w-full ${
                    pointItem?.type !== "custom" && pointItem.value !== 0
                      ? "bg-storm-10"
                      : "bg-base"
                  }`,
                }}
              >
                <div className="flex items-center justify-between gap-1 py-2 pl-2 w-full">
                  <span className=" font-roboto text-xs text-oil-60">
                    {label.toUpperCase()}.{index + 1}
                  </span>

                  <input
                    className={`w-full ${
                      pointItem?.type !== "custom" && pointItem.value !== 0
                        ? "bg-storm-10"
                        : "bg-base"
                    } text-right px-0.5 text-xs text-oil-dark leading-normal border-none focus:border-none focus-visible:border-none outline-none`}
                    value={pointItem.value}
                    disabled={
                      pointItem?.type !== "custom" ? pointItem.value !== 0 : false
                    }
                    onChange={(e) => {
                      if (pointsPricesRegex.test(e.target.value)) {
                        setSetupValues((prevState) => ({
                          ...prevState,
                          points: {
                            ...prevState.points,
                            [label]: prevState.points[label]?.map((item) => {
                              if (item.id === pointItem.id)
                                return {
                                  ...pointItem,
                                  value: e.target.value,
                                  isClosed: false,
                                  rr: calculateRiskToReward
                                    ? calculateRiskToReward(Number(e.target.value))
                                    : null,
                                };
                              return item;
                            }),
                          },
                        }));
                      }
                    }}
                    onBlur={(e) =>
                      setSetupValues((prevState) => ({
                        ...prevState,
                        points: {
                          ...prevState.points,
                          [label]: sortFunction(e.target.value, [
                            ...prevState.points[label],
                          ]),
                        },
                      }))
                    }
                    placeholder="$0.00"
                    type="text"
                  />
                  {pointItem?.rr && (
                    <span className="px-1 flex items-center gap-0.5 font-roboto rounded-md text-xs bg-[#CCE4FF] text-[#007BFF] py-0 w-fit">
                      <span>R:R</span> {pointItem?.rr}
                    </span>
                  )}
                </div>
              </InputBase>

              <InputBase className={{ root: "bg-base w-full" }}>
                <div className="flex items-center justify-between py-2 pl-2 w-auto">
                  <span className=" font-robotoMed text-xs text-oil-60">weight</span>
                  <input
                    id={pointItem.id}
                    className="w-full bg-base text-right text-xs text-oil-dark leading-normal border-none focus:border-none focus-visible:border-none outline-none"
                    value={pointItem.weight ? pointItem.weight : ""}
                    placeholder="50%"
                    onChange={(e) =>
                      setSetupValues((prevState) => ({
                        ...prevState,
                        points: {
                          ...prevState.points,
                          [label]: prevState.points[label]?.map((item) => {
                            if (item.id === pointItem.id)
                              return {
                                ...pointItem,
                                weight: Number(e.target.value),
                              };
                            return item;
                          }),
                        },
                      }))
                    }
                    type="text"
                  />
                  {pointItem.weight ? "%" : ""}
                </div>
              </InputBase>

              <button
                tabIndex={1}
                onClick={() => {
                  onDeletePoint({
                    label: label,
                    id: pointItem.id,
                    index: index + 1,
                  });
                }}
                onFocus={(e) => {
                  e.target.blur();
                }}
              >
                <TrashSvg color="#C2CFD6" className="min-w-fit" />
              </button>
            </div>
          ))}
      </div>
    );
  }
);

export const DeletePointConfirm = ({ pointInfo, confirmHandler, cancelHandler }) => {
  useKeySubmission(confirmHandler, undefined, (event) => event.key === "Enter", []);
  return (
    <div className="px-4 text-base text-oil-80 font-roboto flex flex-col gap-5">
      You are deleting your selected {pointInfo.label.toUpperCase()}.{pointInfo.index}{" "}
      point. <br />
      Are you sure?
      <div className="flex items-center flex-row-reverse gap-4 justify-between">
        <Button onClick={confirmHandler} variant="primary" className="w-full">
          Yes, it's ok
        </Button>

        <Button onClick={cancelHandler} variant="outline" className="w-full">
          Cancel
        </Button>
      </div>
    </div>
  );
};
