import React from "react";

function ProfileSvg({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10.1264 9.10958C10.0477 9.1017 9.95311 9.1017 9.86644 9.10958C7.99129 9.04655 6.5022 7.51018 6.5022 5.61928C6.5022 3.68897 8.0622 2.12109 10.0004 2.12109C11.9307 2.12109 13.4986 3.68897 13.4986 5.61928C13.4907 7.51018 12.0016 9.04655 10.1264 9.10958Z"
        stroke="#717D8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.18732 12.0169C4.28066 13.2933 4.28066 15.3733 6.18732 16.6418C8.35399 18.0915 11.9073 18.0915 14.074 16.6418C15.9807 15.3654 15.9807 13.2854 14.074 12.0169C11.9152 10.5751 8.36187 10.5751 6.18732 12.0169Z"
        stroke="#717D8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileSvg;
