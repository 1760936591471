import React from "react";

const Modal = ({ children, className, ...props }) => {
  return (
    <div
      className={` bg-[#1d262b99] items-center bottom-0 flex justify-center left-0 absolute right-0 top-0 z-50 ${className?.overlay}`}
      {...props}
    >
      <div
        className={`bg-base rounded-2xl flex flex-col gap-4 py-8 px-4 max-w-[23%] ${className?.container}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
