import { technicalApi } from "~/api/dashboard/analysis/technicalApi";

export const fetchTechnicalDetails = async (id, setDetails, setIsLoading) => {
  try {
    const result = await technicalApi.single(id);

    setDetails(result.data);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching pairs data:", error);
  }
};
