import React from "react";
import Symbols from "~/components/child/Symbols";
import Dropdown from "~/components/child/ui/DropDown";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

export const Details = ({ heatMapValues, setHeatMapValues, details }) => {
  return (
    <div className="flex flex-col gap-6 my-10">
      {details?.technical && (
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold text-sky-70">Technical</h3>
          <Symbols
            optionsList={Object.values(details?.technical?.pairs)?.map(
              (pair) => {
                return { key: pair.key, image: pair.image, value: pair.pair };
              }
            )}
            value={heatMapValues?.details?.technical?.pair || undefined}
            onSelect={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  technical: {
                    ...heatMapValues.details.technical,
                    pair: {
                      key: item.key,
                      value: item.value,
                      image: item.image,
                    },
                  },
                },
              });
            }}
          />
          <Dropdown
            multipleValues
            title="Timeframe"
            maxItems={5}
            value={
              heatMapValues?.details?.technical?.timeFrames?.length > 0
                ? heatMapValues?.details?.technical?.timeFrames
                : undefined
            }
            optionsList={details?.technical?.timeFrames?.map((timeFrame) => ({
              key: timeFrame?.id,
              value: timeFrame.time_frame,
            }))}
            renderItem={(item) => (
              <span className="py-2 text-xs font-roboto w-full text-left">
                {item.value}
              </span>
            )}
            onDeleteOption={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  technical: {
                    ...heatMapValues.details.technical,
                    timeFrames:
                      heatMapValues?.details?.technical?.timeFrames?.filter(
                        (timeFrame) => item.key !== timeFrame.key
                      ),
                  },
                },
              });
            }}
            onSelect={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  technical: {
                    ...heatMapValues.details.technical,
                    timeFrames: onChangeCheckboxes(
                      heatMapValues?.details?.technical?.timeFrames || [],
                      {
                        key: item.key,
                        value: item.value,
                      }
                    ),
                  },
                },
              });
            }}
          />
          <div className="flex items-center justify-between gap-2">
            <Dropdown
              multipleValues
              title="Type"
              value={
                heatMapValues?.details?.technical?.positionType?.length > 0
                  ? heatMapValues?.details?.technical?.positionType
                  : undefined
              }
              optionsList={details?.technical?.positionType?.map(
                (positionType) => ({
                  key: positionType.toLowerCase(),
                  value: changeWordFormatToTitleCase(positionType),
                })
              )}
              renderItem={(item) => (
                <span className="py-2 text-xs font-roboto w-full text-left">
                  {item.value}
                </span>
              )}
              onDeleteOption={(item) => {
                setHeatMapValues({
                  ...heatMapValues,
                  details: {
                    ...heatMapValues.details,
                    technical: {
                      ...heatMapValues.details.technical,
                      positionType:
                        heatMapValues?.details?.technical?.positionType?.filter(
                          (positionType) => item.key !== positionType.key
                        ),
                    },
                  },
                });
              }}
              onSelect={(item) => {
                setHeatMapValues({
                  ...heatMapValues,
                  details: {
                    ...heatMapValues.details,
                    technical: {
                      ...heatMapValues.details.technical,
                      positionType: onChangeCheckboxes(
                        heatMapValues?.details.technical?.positionType || [],
                        {
                          key: item.key,
                          value: item.value,
                        }
                      ),
                    },
                  },
                });
              }}
            />
            <Dropdown
              multipleValues
              title="Technical type"
              value={
                heatMapValues?.details?.technical?.technicalType?.length > 0
                  ? heatMapValues?.details?.technical?.technicalType
                  : undefined
              }
              optionsList={["Setup", "Analysis"].map((technicalType) => ({
                key: technicalType.toLowerCase(),
                value: changeWordFormatToTitleCase(technicalType),
              }))}
              renderItem={(item) => (
                <span className="py-2 text-xs font-roboto w-full text-left">
                  {item.value}
                </span>
              )}
              onDeleteOption={(item) => {
                setHeatMapValues({
                  ...heatMapValues,
                  details: {
                    ...heatMapValues.details,
                    technical: {
                      ...heatMapValues.details.technical,
                      technicalType:
                        heatMapValues?.details?.technical?.technicalType?.filter(
                          (technicalType) => item.key !== technicalType.key
                        ),
                    },
                  },
                });
              }}
              onSelect={(item) => {
                setHeatMapValues({
                  ...heatMapValues,
                  details: {
                    ...heatMapValues.details,
                    technical: {
                      ...heatMapValues.details.technical,
                      technicalType: onChangeCheckboxes(
                        heatMapValues?.details.technical?.technicalType || [],
                        {
                          key: item.key,
                          value: item.value,
                        }
                      ),
                    },
                  },
                });
              }}
            />
          </div>
        </div>
      )}
      {details?.fundamental && (
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold text-sky-70">Fundamental</h3>
          <Symbols
            multipleValues
            optionsList={Object.values(details?.fundamental?.pairs)?.map(
              (pair) => {
                return { key: pair.key, image: pair.image, value: pair.pair };
              }
            )}
            value={
              heatMapValues?.details?.fundamental?.pairs?.length > 0
                ? heatMapValues?.details?.fundamental?.pairs
                : undefined
            }
            onSelect={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  fundamental: {
                    ...heatMapValues.details.fundamental,
                    pairs: onChangeCheckboxes(
                      heatMapValues.details.fundamental.pairs,
                      {
                        key: item.key,
                        value: item.value,
                        image: item.image,
                      }
                    ),
                  },
                },
              });
            }}
            onDeleteOption={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  fundamental: {
                    ...heatMapValues.details.fundamental,
                    pairs: heatMapValues?.details.fundamental.pairs?.filter(
                      (pair) => item.key !== pair.key
                    ),
                  },
                },
              });
            }}
          />
        </div>
      )}
      {details?.onChain && (
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold text-sky-70">On-chain</h3>
          <Symbols
            multipleValues
            optionsList={Object.values(details?.onChain?.pairs)?.map((pair) => {
              return { key: pair.key, image: pair.image, value: pair.pair };
            })}
            value={
              heatMapValues?.details?.onChain?.pairs?.length > 0
                ? heatMapValues?.details?.onChain?.pairs
                : undefined
            }
            onSelect={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  onChain: {
                    ...heatMapValues?.details?.onChain,
                    pairs: onChangeCheckboxes(
                      heatMapValues?.details?.onChain?.pairs,
                      {
                        key: item.key,
                        value: item.value,
                        image: item.image,
                      }
                    ),
                  },
                },
              });
            }}
            onDeleteOption={(item) => {
              setHeatMapValues({
                ...heatMapValues,
                details: {
                  ...heatMapValues.details,
                  onChain: {
                    ...heatMapValues?.details?.onChain,
                    pairs: heatMapValues?.details?.onChain?.pairs?.filter(
                      (pair) => item.key !== pair.key
                    ),
                  },
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
