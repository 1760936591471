import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";

import { Loading } from "~/components/child/ui/Loading";

import { useUsersList } from "~/utils/hooks";

export const calculateWinRateInPercent = (winRate) => {
  return `${parseInt(Number(winRate) * 100)}${winRate !== null ? "%" : ""}`;
};

const UserList = () => {
  const [isLoading, setIsLoading] = useState(true);

  const analyzersList = useUsersList()?.data;

  useEffect(() => {
    if (analyzersList) setIsLoading(false);
  }, [analyzersList]);

  return (
    <div className="main__router__container">
      <div className="main__router__container__list-box-container">
        {!isLoading && analyzersList?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
              <table className="list-table-cstm-one__content-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Technical</th>
                    <th>Fundamental</th>
                    <th>On-Chain</th>
                    <th>Win rate</th>
                    <th>Detail</th>
                  </tr>
                </thead>

                <tbody>
                  {analyzersList?.map((item, index) => (
                    <tr className=" h-14" key={index}>
                      <td className="">
                        {item?.first_name} {item?.last_name}
                      </td>

                      <td className="">
                        {item?.technical.setup_count +
                          item.technical.analytic_count}
                      </td>

                      <td>{item?.fundamental}</td>
                      <td>{item?.on_chain}</td>

                      <td className="">
                        {item?.win_rate !== null
                          ? calculateWinRateInPercent(item?.win_rate?.total)
                          : "-"}
                      </td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <NavLink
                          className="flex justify-center items-center"
                          to={`/userList/userProfile?id=${item?.uuid}`}
                          target="_blank"
                        >
                          <ArrowRightSvg />
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : !isLoading ? (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">No results!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default UserList;
