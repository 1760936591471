import React from "react";
import { truncate } from "~/utils/truncate";
import RemoveSvg from "~/assets/icon/svgJsx/RemoveSvg";

const Preview = ({ images, onRemove, onSetAsPrimary, isEditable = true, className }) => {
  return (
    <div className="flex flex-col items-center gap-3">
      {images.map((item) => (
        <div
          key={item.id}
          className="flex items-center w-full rounded-lg shadow box-border justify-between p-2.5 text-storm-key font-roboto"
        >
          <div className={`flex items-center gap-4 ${className?.info}`}>
            <img
              className={`flex overflow-hidden rounded-lg w-24 h-24 ${className?.image}`}
              src={item.src}
              alt=""
            />
            <span>{truncate(item.name, 15)}</span>
          </div>
          {isEditable ? (
            <div className={`flex items-center gap-4 text-xs ${className?.actions}`}>
              <button className="flex items-center bg-storm-10 rounded-xl text-storm-key font-roboto gap-2 p-2.5">
                <input
                  onChange={() => {
                    if (onSetAsPrimary) onSetAsPrimary(item.id);
                  }}
                  type="radio"
                  name="active-picture"
                  checked={item.active}
                  className="text-sky-key cursor-pointer scale-125 m-0"
                />
                <label className={`text-sky-key ${className?.primary?.label}`}>
                  Set as primary
                </label>
              </button>

              <button
                className="bg-transparent"
                onClick={() => {
                  if (onRemove) onRemove(item.id);
                }}
              >
                <RemoveSvg className={`w-6 h-6 ${className?.primary?.icon}`} />
              </button>
            </div>
          ) : item.active ? (
            <span className=" text-xs text-sky-key font-robotoMed">Primary</span>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default Preview;
