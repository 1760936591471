import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { onChainApi } from "~/api/dashboard/analysis/onChainApi";

import AnalysisInformation from "~/components/child/AnalysisInformation";
import ImageSlider from "~/components/child/ui/ImageSlider";
import Comment from "~/components/child/Comment";
import { RightNav } from "~/components/child/RightNav";
import { PrivateNote } from "~/components/child/PrivateNote";
import { Loading } from "~/components/child/ui/Loading";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

const OnchainView = ({ permissions }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState();

  useEffect(() => {
    const fetchOnchainDetails = async () => {
      try {
        const result = await onChainApi.single(id);

        setDetails(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    if (id) fetchOnchainDetails();
  }, [id]);
  return (
    <>
      {!isLoading && details?.data ? (
        <div className="main__router__container">
          <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
            <div className="onchain-view">
              <AnalysisInformation
                user={
                  details?.data?.analysis?.user?.first_name +
                  " " +
                  details?.data?.analysis?.user?.last_name
                }
                pairs={details?.data.analysis.pair}
                type="On-Chain"
                analysisName={details?.data?.details[0]?.title}
                description={details?.data.analysis.description}
                createDateTime={{
                  time: details?.data?.analysis?.created_at?.split(" ")[1],
                  date: details?.data?.analysis?.created_at?.split(" ")[0],
                }}
                positionType={
                  details?.data.analysis.position_type &&
                  changeWordFormatToTitleCase(details?.data.analysis.position_type)
                }
              />

              <ImageSlider images={details?.data?.details[0].pictures} />
            </div>
            {(permissions?.new_comment || permissions?.comment_list) && (
              <Comment permissions={permissions} section="analytics" id={id} />
            )}
          </div>
          {(permissions?.new_private_note || permissions?.private_note_list) && (
            <RightNav>
              <PrivateNote section="analytics" permissions={permissions} id={id} />
            </RightNav>
          )}
        </div>
      ) : !isLoading ? (
        <div className="main__router__container">
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">
              There isn't any onchain analysis with this Id!
            </h4>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default OnchainView;
