import React, { useEffect, useState } from "react";
import { heatMapApi } from "~/api/dashboard/heatMapApi";

import { Loading } from "~/components/child/ui/Loading";
import AlertItem from "./Item";

const Alerts = ({ id, popUpIsOpen, setTotalCount }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const result = await heatMapApi.getAlerts(id);

        setListItems(result.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    if (!popUpIsOpen) fetchAlerts();
  }, [popUpIsOpen]);
  useEffect(() => {
    if (setTotalCount && listItems?.data)
      setTotalCount((prevState) => ({
        ...prevState,
        alerts: listItems?.data?.length,
      }));
  }, [listItems]);
  return (
    <div className="alerts">
      {!isLoading && listItems?.data?.length > 0 ? (
        <div className="alerts__list scrollbar-style scrollbar-style-thumb">
          {listItems?.data?.map((item, index) => (
            <div key={index}>
              <AlertItem alertKey={index} alert={item} />
            </div>
          ))}
        </div>
      ) : !isLoading ? (
        <div className="alerts__empty-list">
          <p>You don’t have any notification yet!</p>
        </div>
      ) : (
        <Loading size="small" />
      )}
    </div>
  );
};

export default Alerts;
