import { useState, useEffect } from "react";
import { setupApi } from "../../api/dashboard/setupApi";

export const usePairPrice = (pair) => {
  const [pairPrice, setPairPrice] = useState();

  useEffect(() => {
    const fetchPairsList = async (pair) => {
      try {
        const data = await setupApi.pairPrice(pair);
        setPairPrice(Object.values(Object.values(data.data)[0])[0]);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };
    if (pair) fetchPairsList(pair);
  }, [pair]);

  return pairPrice;
};
