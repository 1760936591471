import React from "react";

function PlusSvg() {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6663 8.66659H8.66634V12.6666H7.33301V8.66659H3.33301V7.33325H7.33301V3.33325H8.66634V7.33325H12.6663V8.66659Z" fill="white"/>
</svg>

  );
}

export default PlusSvg;