import { callRestPost, callRestGet, callRestPut } from "../../../utils/callRestApi";

export const technicalApi = {
  createNew: async (body) => {
    const result = { data: null, error: null };
    await callRestPost("dashboard/analytics/technical", body, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  edit: async (uuid, body) => {
    const result = { data: null, error: null };
    await callRestPost(`dashboard/analytics/technical/edit/${uuid}`, body, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        if (res.status === 201) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  editHistory: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`dashboard/analytics/technical/edit/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  single: async (uuid) => {
    const result = { data: null, error: null };
    await callRestGet(`/dashboard/analytics/technical/${uuid}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  cancel: async (uuid) => {
    const result = { data: null, error: null };
    await callRestPut(`dashboard/analytics/${uuid}`, null, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err.response.data.message;
      });

    return result;
  },
};
