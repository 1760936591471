import axios from "axios";
import { apiConfig } from "./constants";

const instance = axios.create({
  baseURL: `${apiConfig.BASE_API_URL}/trade/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  async (config) => {
    const headers = await SignToken();
    if (headers) {
      config.headers = { ...headers, ...config.headers };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function SignToken() {
  if (localStorage.getItem("accessToken")) {
    const secret = localStorage.getItem("accessToken");
    const refresh = localStorage.getItem("refreshToken");

    return {
      Authorization: "Bearer " + secret,
      factoryId: 1,
      refreshToken: refresh,
    };
  }

  return null;
}

export default instance;
