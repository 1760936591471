import { mainChartApi } from "~/api/dashboard/mainChartApi";

export const mainChartApiUpdateHelper = {
  event: (type, parameters) => {
    let body = { chart_data: parameters };
    mainChartApi.edit(type, body).then((res) => {
      if (res?.error) {
        console.log(
          `Error: main chart [event]:[edit], Type: , ['${type}'] => `,
          res?.error?.response?.data?.message
        );
      }
    });
  },

  nearRealTime: (intervalId, type, parameters) => {
    let body = {
      chart_data: parameters,
    };

    mainChartApi
      .edit(type, body)
      .then((res) => {
        if (res?.error) {
          clearInterval(intervalId);
          console.log(
            `Error: main chart [edit]:[nearRealTime], Type: , ['main_chart_data'] => `,
            res?.error?.response?.data?.message
          );
        }
      })
      .catch((err) => {
        clearInterval(intervalId);
        console.error(
          `Error: main chart [edit]:[nearRealTime], Type: , ['main_chart_data'] => `,
          err
        );
      });
  },
};
