export const sortAscending = (array, key = "value") => {
  return array?.sort((a, b) => a[key] - b[key]);
};

export const sortDescending = (array, key = "value") => {
  return array?.sort((a, b) => b[key] - a[key]);
};
export const calculateSumWeight = (array) => {
  return array.reduce((sum, item) => {
    return sum + (item.weight ? item.weight : 0);
  }, 0);
};

export const calculateMDD = (sumWeightedEP, sumWeightedSL, type) => {
  switch (type) {
    case "long":
      if (sumWeightedEP === 0) return 0;
      return (((sumWeightedSL - sumWeightedEP) / sumWeightedEP) * 100).toFixed(2);
    case "short":
      if (sumWeightedSL === 0) return 0;
      return (((sumWeightedEP - sumWeightedSL) / sumWeightedSL) * 100).toFixed(2);

    default:
      break;
  }
};

export const calculateMaxProfit = (sumWeightedEP, sumWeightedTP, type) => {
  switch (type) {
    case "long": {
      if (sumWeightedEP === 0) return 0;
      const maxProfit = (((sumWeightedTP - sumWeightedEP) / sumWeightedEP) * 100).toFixed(
        2
      );
      if (!isNaN(maxProfit)) return maxProfit;
      break;
    }
    case "short":
      if (sumWeightedTP === 0) return 0;
      const maxProfit = (((sumWeightedEP - sumWeightedTP) / sumWeightedTP) * 100).toFixed(
        2
      );
      if (!isNaN(maxProfit)) return maxProfit;

      break;
    default:
      break;
  }
};

export const calculateRiskToReward = (sumWeightedEP, sumWeightedTP, sumWeightedSL) => {
  if (sumWeightedEP - sumWeightedSL === 0) return 0;
  const riskToReward = Math.abs(
    (sumWeightedTP - sumWeightedEP) / (sumWeightedEP - sumWeightedSL)
  ).toFixed(2);
  if (!isNaN(riskToReward)) return riskToReward;
};

export const calculateRiskParams = (epList, slList, tpList, type) => {
  let mdd = 0;
  let maxProfit = 0;
  let rr = 0;

  const sumWeightedEP = epList.reduce((sum, item) => {
    return sum + (item.weight ? item.value * item.weight : 0);
  }, 0);

  const sumWeightedSL = slList.reduce((sum, item) => {
    return sum + (item.weight ? item.value * item.weight : 0);
  }, 0);
  const sumWeightedTP = tpList.reduce((sum, item) => {
    return sum + (item.weight ? item.value * item.weight : 0);
  }, 0);
  mdd = calculateMDD(sumWeightedEP, sumWeightedSL, type);
  maxProfit = calculateMaxProfit(sumWeightedEP, sumWeightedTP, type);
  rr = calculateRiskToReward(sumWeightedEP, sumWeightedTP, sumWeightedSL);
  return { mdd, maxProfit, rr };
};

export const sumWeightValidation = (sumWeight, setError) => {
  if (sumWeight > 0 && sumWeight !== 100) return true;
  return false;
};

export const newSetupFormValidation = (
  sumWeight,
  errorMessage,
  isLoading,
  setupValues
) => {
  return (
    errorMessage?.points !== undefined ||
    (errorMessage?.pointsWeight &&
      Object.keys(errorMessage?.pointsWeight).filter(
        (key) => errorMessage?.pointsWeight[key]
      ).length > 0) ||
    Object.values(sumWeight).some((value) => value === 0) ||
    isLoading ||
    !setupValues.vault ||
    Object.values(setupValues.points).some((arr) => arr.length === 0)
  );
};

export const getState = (currentState) => {
  if (currentState.includes("Target")) {
    return "Target";
  } else if (currentState.includes("Stop")) {
    return "Stop";
  } else if (
    currentState.includes("Entry") ||
    currentState.includes("Terminate") ||
    currentState.includes("Terminate.")
  ) {
    return "Entry";
  } else {
    return "Cancel";
  }
};
