import React from "react";
import { validateInput } from "../../../child/tradingViewMainChart/utils/helpers/tools/validateInput";

export const handlePlotImbalances = async (chartRef, setIsPlotZones) => {
  const functionName = handlePlotImbalances.name
  validateInput(chartRef, 'chartRef', functionName)
  validateInput(setIsPlotZones, 'setIsPlotZones', functionName)
  try {
    if (chartRef.current) {
      chartRef.current.ImbalancesOperation.plotZones();
      chartRef.current.ImbalancesOperation.plotRotations();
      setIsPlotZones(true);
    }
  } catch (error) {
    setIsPlotZones(false);
    console.log("failed handlePlotImbalances() Error => ", error);
  }
};

export const handleRemoveAllWidget = async (chartRef, setIsPlotZones) => {
  const functionName = handleRemoveAllWidget.name
  validateInput(chartRef, 'chartRef', functionName)
  validateInput(setIsPlotZones, 'setIsPlotZones', functionName)
  try {
    if (chartRef.current) {
      await chartRef.current.ImbalancesOperation.removeAllWidget();
      setIsPlotZones(false);
    }
  } catch (error) {
    console.log("handleRemoveAllWidget() imbalances Error => ", error);
    setIsPlotZones(true);
  }
};

export const useImbalanceInputCalculator = (
  imbalanceValue,
  setImbalanceValue
) => {
  const functionName = useImbalanceInputCalculator.name
  validateInput(imbalanceValue, 'imbalanceValue',functionName)
  validateInput(setImbalanceValue,'setImbalanceValue',functionName)
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (imbalanceValue >= 80 && imbalanceValue <= 99) {
        setImbalanceValue(imbalanceValue);
      } else if (imbalanceValue <= 79) {
        setImbalanceValue(80);
      } else if (imbalanceValue >= 99) {
        setImbalanceValue(99);
      }
    }, 300);
    return () => {
      clearInterval(intervalId);
    };
  }, [imbalanceValue]);
};
