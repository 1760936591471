import React from "react";
import { NavLink } from "react-router-dom";
import ChartSquareSvg from "../../assets/icon/svgJsx/ChartSquareSvg";

export default function FullChart({
  nameItem,
  navBoxIsOpen,
  breadcrumbItems,
  user,
}) {
  
  const currentMainListOfNav = breadcrumbItems?.main === nameItem;

  return (
    <li
      className={`nav__list__item itemClose ${
        navBoxIsOpen && currentMainListOfNav && "listOpen mainItemActive"
      } ${!navBoxIsOpen && currentMainListOfNav && "isActiveClose"}`}
    >
      <NavLink to="/fullchart">
        <div className="nav__list__item__box">
          <div className="nav__list__item__box__title">
            <i className="nav__list__item__box__icon ">
              <ChartSquareSvg />
            </i>
            <span>{navBoxIsOpen && <div>{nameItem}</div>}</span>
          </div>
        </div>
      </NavLink>

    </li>
  );
}
