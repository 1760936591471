import React, { useRef } from "react";
import { useKeySubmission } from "~/utils/hooks";
import SendSvg from "../../assets/icon/svgJsx/SendSvg";

export const MessageInput = ({ value, setValue, handleSubmit }) => {
  const inputRef = useRef(null);

  useKeySubmission(
    handleSubmit,
    undefined,
    (event) =>
      event.key === "Enter" &&
      document.activeElement === inputRef.current &&
      value.length > 0,
    [value]
  );

  return (
    <div className="messageInput">
      <input
        value={value}
        ref={inputRef}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Write your comment"
        className="mr-2"
      />
      <button onClick={() => handleSubmit()}>
        <SendSvg />
      </button>
    </div>
  );
};
