import React from "react";

function GroupArrowLeftSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      className={className}
      viewBox="0 0 8 14"
    >
      <path
        stroke="#DF2040"
        strokeLinecap="round"
        d="M7 .5L2.207 5.293a1 1 0 000 1.414L7 11.5M1 8.5l5 5"
      ></path>
    </svg>
  );
}

export default GroupArrowLeftSvg;
