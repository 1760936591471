import { validateInput } from "../helpers/tools/validateInput";

const intervalToDays = {
    '1': [2, 2],
    '3': [2, 2],
    '5': [6, 6],
    '15': [12, 12],
    '30': [12, 12], 
    '45': [15, 15],  
    '60': [18, 18],
    '120': [24, 24], 
    '180': [30, 30],
    '240': [36, 36],
    'D': [60, 60],
    'W': [730, 730],
    'M': [2190, 2190],
    '3M': [6570, 6570],
    '6M': [13140, 13140],
    '12M': [26280, 26280]
};


export const createChartTimeFrame = (interval, createdAt) => {

    if (interval && !intervalToDays.hasOwnProperty(interval)) { 
        validateInput(null, 'interval', 'createChartTimeFrame', `timeframe { from: $aFewDaysAgo, to: $aFewDaysLater } ) => Invalid : ${interval}. Valid intervals are: ${Object.keys(intervalToDays).join(' || ')}`)
    }

    const [aFewDaysAgo, aFewDaysLater] = intervalToDays[interval] || [0, 0];

    const convertDateToUnix = (date) => {
        date = new Date(date);
        date.setHours(0, 0, 0, 0);
        return Math.floor(date.getTime() / 1000);
    };

    const adjustDate = (date, days) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    };

    const createdAtDate = new Date(createdAt);
    const timeFrom = convertDateToUnix(adjustDate(createdAtDate, -aFewDaysAgo));
    const timeTo = convertDateToUnix(adjustDate(createdAtDate, aFewDaysLater));

    return { timeFrom, timeTo };
};
