import React from "react";

function ArrowLeftSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      fill="none"
      viewBox="0 0 8 15"
    >
      <path
        stroke="#FDFDFD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 14.5l-7-7 7-7"
      ></path>
    </svg>
  );
}

export default ArrowLeftSvg;
