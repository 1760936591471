import { callRestGet, callRestPut } from "../../utils/callRestApi";

export const mainChartApi = {

  detail: async (type) => {

    const result = { data: null, error: null };

    await callRestGet(`/dashboard/full/chart?chart_data_type=${type}`, null)

      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },

  edit: async (type, body) => {
    const result = { data: null, error: null };
    await callRestPut(`/dashboard/full/chart?chart_data_type=${type}`, body)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
