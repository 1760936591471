import { technicalApi } from "~/api/dashboard/analysis/technicalApi";
import { fundamentalApi } from "~/api/dashboard/analysis/fundamentalApi";
import { onChainApi } from "~/api/dashboard/analysis/onChainApi";

import {
  transformedAnalysisData,
  transformedAnalysisImages,
} from "~/utils/getTransformedData";

export const initialAnalysisValues = {
  new: {
    pair: {
      value: "BTCUSDT",
      key: "BTCUSDT",
      image: [
        "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
        "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      ],
      baseSymbol: "BTC",
      quoteSymbol: "USDT",
      exchange: "binance",
    },
    analysisType: "technical",
    positionType: "long",
  },
  technical: {
    type: "setup",
    timeFrame: {
      key: 4,
      value: "15 Minutes",
    },
    points: {
      ep: [{ value: "", desc: "" }],
      tp: [{ value: "", desc: "" }],
      sl: [{ value: "", desc: "" }],
    },
    notificationUsers: [],
    images: [],
    description: "",
    chart: null,
  },
  fundamental: {
    entries: [],
    images: [],
    description: "",
    chart: null,
  },
  onChain: {
    title: "",
    description: "",
    images: [],
  },
};

export const resetAnalysisValues = (isDataChanged, analysisValues, setNotification) => {
  if (isDataChanged) setNotification(false);
  return {
    technical: isDataChanged
      ? {
          ...initialAnalysisValues.technical,
          points: {
            ep: initialAnalysisValues.technical.points.ep.map((item) => ({
              value: "",
              desc: "",
            })),
            tp: initialAnalysisValues.technical.points.tp.map((item) => ({
              value: "",
              desc: "",
            })),
            sl: initialAnalysisValues.technical.points.sl.map((item) => ({
              value: "",
              desc: "",
            })),
          },
        }
      : { ...analysisValues.technical },
    fundamental: isDataChanged
      ? { ...initialAnalysisValues.fundamental }
      : { ...analysisValues.fundamental },
    onChain: isDataChanged
      ? { ...initialAnalysisValues.onChain }
      : { ...analysisValues.onChain },
  };
};

export const createOrEditAnalysis = (
  id,
  analysisType,
  setIsLoading,
  analysisValues,
  onSuccess,
  setErrorMessage,
  enableNotification,
  userId
) => {
  setIsLoading(true);

  if (analysisType === "technical") {
    const formData = new FormData();
    transformedAnalysisData(
      formData,
      analysisValues.technical,
      analysisValues.new.analysisType,
      analysisValues.new.pair.key,
      analysisValues.new.positionType,
      enableNotification,
      userId
    );

    if (id) {
      formData.append("_method", "PUT");

      technicalApi
        .edit(id, formData)
        .then((res) => {
          if (res && !res.error && res.data && res.data?.data?.analysis?.uuid) {
            onSuccess(res.data?.data?.analysis?.uuid);
          } else if (res?.error) {
            if (res?.error.response?.data.errors) {
              setErrorMessage(res?.error.response?.data.errors);
            } else setErrorMessage([res?.error.response?.data.message]);
          }
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    } else
      technicalApi
        .createNew(formData)
        .then((res) => {
          if (res && !res.error && res.data) {
            onSuccess();
          } else if (res?.error) {
            if (res?.error.response?.data.errors) {
              setErrorMessage(res?.error.response?.data.errors);
            } else setErrorMessage([res?.error.response?.data.message]);
          }
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
  }

  if (analysisType === "fundamental") {
    const formData = new FormData();

    transformedAnalysisData(
      formData,
      analysisValues.fundamental,
      analysisValues.new.analysisType,
      analysisValues.new.pair.key,
      analysisValues.new.positionType
    );

    fundamentalApi
      .createNew(formData)
      .then((res) => {
        if (res && !res.error && res.data) {
          onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  }
  if (analysisType === "on-chain") {
    const formData = new FormData();
    transformedAnalysisImages(formData, analysisValues.onChain.images, [
      "on_chain",
      "picture",
    ]);
    formData.append("title", analysisValues.onChain.title);
    formData.append("description", analysisValues.onChain.description);
    formData.append("analysis_type", "on_chain");
    formData.append("pair_key", analysisValues.new.pair.key);
    if (analysisValues.new.positionType !== "-")
      formData.append("position_type", analysisValues.new.positionType);

    onChainApi
      .createNew(formData)
      .then((res) => {
        if (res && !res.error && res.data) {
          onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  }
};
