const isDevelopment = process.env.NODE_ENV === 'development';

export function validateInput(input, inputName, callerName, additionalText) {
  if(isDevelopment) {
    const isInvalidInput = input == null || input === undefined;
    if (isInvalidInput) {
      throw new Error(`Invalid input: (${inputName}) => ${callerName}() ${additionalText ? '=> ' + additionalText : ''}`);
    }  
  }
}

export function validateInputType(input, nameInput, types, callerName) {
  if(isDevelopment) {
  if (!types.includes(typeof input)) {
    throw new Error(`TypeError: (${nameInput}) => Type must be one of : [${types.join(', ')}] => ${callerName}()`);
  }
  }
}
