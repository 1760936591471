import React from "react";

const Analyzers = ({ list, setSelected, selected }) => {
  return (
    <ul className="analyzers scrollbar-style scrollbar-style-thumb">
      {list.map((analyser, index) => (
        <li key={index}>
          <p className="flex items-center gap-2">
            <input
              id={analyser.uuid}
              type="checkbox"
              checked={selected?.includes(analyser)}
              onChange={() => {
                if (!selected?.includes(analyser)) setSelected([...selected, analyser]);
                else
                  setSelected((prevSelected) =>
                    prevSelected.filter((item) => item !== analyser)
                  );
              }}
            />
            <label htmlFor={analyser.uuid}>{analyser?.user_name}</label>
            {analyser?.is_closed && (
              <span className="p-2 text-storm-50 rounded-xl bg-storm-20 text-sm font-roboto">
                Close
              </span>
            )}
          </p>
          <span>{analyser?.analysis_date?.split(" ")[0]}</span>
        </li>
      ))}
    </ul>
  );
};

export default Analyzers;
