import { tvColors } from "../helpers/tools/tvColors";
import { validateInput } from "../helpers/tools/validateInput";

const widgetStyles = {
  ep: { color: tvColors.blue, style: 0 },
  sl: { color: tvColors.red, style: 0 },
  tp: { color: tvColors.green, style: 0 },
  fundamentalDatePoint: { color: tvColors.gray, style: 0 },
  fundamentalDatePointLong: { color: tvColors.green, style: 0 },
  fundamentalDatePointShort: { color: tvColors.red, style: 0 },
  analisisCreatedAt: { color: tvColors.orange, style: 2 },
};

export const widgetStyleDetermine = (type) => {
  const style = widgetStyles[type];
  const validTypes = Object.keys(widgetStyles); 
  validateInput(style, 'type', 'widgetStyleDetermine', `Invalid widget type: '${type}' => class => WidgetCreator. Valid Input: [${validTypes.join(', ')}]`);
  return style;
};
