import { useEffect } from "react";
import { mainChartApiUpdateHelper } from "../../child/tradingViewMainChart/utils/helpers/api/mainChartApiUpdateHelper";

export const useFullChartNearRealTimeUpdater = () => {
  useEffect(() => {
    let intervalId;
    const updateChart = () => {
      const parameters = localStorage.getItem("full_chart_near_real_time");
      if (parameters) {
        mainChartApiUpdateHelper.nearRealTime(intervalId, "main_chart_data", parameters);
      }
    };
    intervalId = setInterval(updateChart, 60000);
    return () => clearInterval(intervalId);
  }, []);
};
