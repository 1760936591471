import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { authApi } from "~/api/auth/authApi";

import Button from "~/components/child/ui/Button";
import Modal from "~/components/child/ui/Modal";

import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Welcome } from "./Welcome";

import signUpSvg from "~/assets/img/signUp.svg";
import signUpBackground from "~/assets/img/signUpBackground.png";

import logoSvg from "~/assets/img/logo.svg";

const SignUp = () => {
  const [step, setStep] = useState("signUp");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    mail: "",
    otp: "",
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendOTP = (e) => {
    e?.preventDefault();
    const body = {
      mobile_number: signUpData.phoneNumber,
    };
    authApi.otp(body).then((res) => {
      if (res && !res.error && res?.data) {
        setStep("otp");
        setTimeRemaining(120);
        setIsLoading(false);
      } else if (res?.error) {
        setErrorMessage(res?.error.response?.data.message);
        setIsLoading(false);
      }
    });
  };

  const register = (e) => {
    e?.preventDefault();
    setIsLoading(true);
    const body = {
      first_name: signUpData.firstName,
      last_name: signUpData.lastName,
      email: signUpData.mail,
      mobile_number: signUpData.phoneNumber,
      otp: signUpData.otp,
    };
    authApi
      .register(body)
      .then((res) => {
        if (res && !res.error && res?.data) {
          setStep("welcome");
        } else if (res?.error) {
          setErrorMessage(res?.error.response?.data.message);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    setErrorMessage("");
  }, [signUpData]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeRemaining]);

  return (
 
      <Modal
        className={{
          container: "w-[83.5%] md:w-full !max-w-7xl",
        }}
        style={{
          backgroundImage: `url(${signUpBackground})`,
        }}
      >
        <div className="flex gap-10 items-start justify-between my-auto md:px-6">
          <div className="hidden md:contents">
            <img alt="sign up" src={signUpSvg} />
          </div>
          <div
            className={`flex w-full flex-col gap-10 ${
              step === "signUp" ? "md:gap-12" : "md:gap-16"
            }`}
          >
            <div className="flex  items-center justify-between">
              <span className=" text-sky-80 text-lg font-light font-roboto">
                Trade War
              </span>
              <img className="" alt="logo" src={logoSvg} />
            </div>

            {step === "signUp" ? (
              <>
                <Step1 signUpData={signUpData} setSignUpData={setSignUpData} />
                <div className="flex w-full flex-col items-center gap-3">
                  <Button
                    disabled={
                      signUpData.firstName.length === 0 ||
                      signUpData.lastName.length === 0 ||
                      signUpData.phoneNumber.length < 11 ||
                      signUpData.mail.length === 0 ||
                      isLoading ||
                      errorMessage?.length > 0 ||
                      !validateEmail(signUpData.mail)
                    }
                    variant="secondary"
                    isLoading={isLoading}
                    onClick={() => {
                      if (!validateEmail(signUpData.mail))
                        setErrorMessage("Please enter a valid email address.");
                      else {
                        setIsLoading(true);
                        sendOTP();
                      }
                    }}
                    className="w-full"
                  >
                    Next
                  </Button>
                  <p className="text-center min-h-5 text-sm font-roboto text-infrared-key">
                    {errorMessage}
                  </p>
                </div>
                {/* <p className="text-base font-roboto flex flex-col gap-4 text-oil-60 items-center">
                  Already have an account?
                  <Link to="/login" className="text-base font-robotoMed text-sky-key">
                    Login
                  </Link>
                </p> */}
              </>
            ) : step === "otp" ? (
              <>
                <Step2
                  signUpData={signUpData}
                  setSignUpData={setSignUpData}
                  onBackHandler={() => setStep("signUp")}
                  timeRemaining={timeRemaining}
                  onResendCode={sendOTP}
                />
                <div className="flex flex-col items-center gap-3">
                  <Button
                    disabled={
                      signUpData.otp.length < 5 ||
                      timeRemaining === 0 ||
                      isLoading ||
                      errorMessage?.length > 0
                    }
                    variant="secondary"
                    isLoading={isLoading}
                    onClick={() => register()}
                    className="w-full"
                  >
                    Create account
                  </Button>

                  <p className="text-center text-sm font-roboto text-infrared-key">
                    {errorMessage}
                  </p>
                </div>

                <Link
                  className="text-center text-base text-sky-key"
                  onClick={() => {
                    setStep("signUp");
                    setSignUpData({ ...signUpData, otp: "" });
                  }}
                >
                  Back
                </Link>
              </>
            ) : (
              <Welcome />
            )}
          </div>
        </div>
      </Modal>
  );
};

export default SignUp;
