import React from "react";

import { refreshPage } from "../utils/helpers/tools/refreshPage";

const DataFailureAlert = () => {
  return (
    <div className="load-wrap">
      <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <h5 class="mb-2 text-1xl  tracking-tight text-infrared-50 font-roboto">
          There was a problem loading the chart
        </h5>

        <p class="mb-3 text-1xl font-normal text-infrared-50 font-roboto">
          Please try again
        </p>

        <button
          onClick={refreshPage}
          className="text-leaf-50 ring-1 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-robotoMed text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export default DataFailureAlert;
