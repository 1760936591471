import useLocalStorage from "use-local-storage";
import HeatmapChartProcessorClass from "../../../utils/classes/heatmapChartProcessorClass";
import PropTypes from 'prop-types';

import React, { useEffect, useRef, useState } from "react";
import { widget } from "../../../../../../charting_library";
import { WidgetCreatorClass } from "../../../utils/classes/widgetCreatorClass";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { createChartConfig } from "~/components/child/tradingViewMainChart/config/createChartConfig.js";
import { createTradeBoundariesWidgetTimeRange } from "../../../utils/creators/createTradeBoundariesWidgetTimeRange";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { tvColors } from "../../../utils/helpers/tools/tvColors";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { createWidgetLabelOverrides } from "../../../utils/creators/createWidgetLabelOverrides";
import { Checkbox } from "../../../../ui/Checkbox";
import { getPairDataConfig } from "../../../utils/helpers/tools/getPairDataConfig";
import { fundamentalWidgetPlotProcess } from "./helper";

import "../../index.css";
import { validateInput } from "../../../utils/helpers/tools/validateInput";

export const TVChartContainer = React.memo(
  ({ heatmapChartDataDetails, baseDateType, selectedAnalysersIds, theme }) => {
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("heatmap");

    const heatmapChartProcessor = new HeatmapChartProcessorClass(
      heatmapChartDataDetails,
      selectedAnalysersIds,
      theme
    );

    const {
      oldestAnalysisDate,
      pairDetail,
      technicalList,
      fundamentalList,
      date: createdAt,
      intervalData,
    } = heatmapChartProcessor;

    const { pair, dataFeed: datafeedUrl } = getPairDataConfig(
      pairDetail,
      "snake_case"
    );

    const [interval, setInterval] = useState(intervalData);
    const [userInterval, setUserInterval] = useLocalStorage("userInterval", "");
    const [showWidgets, setShowWidgets] = useState(true);
    const [showAnalyserName, setShowAnalyserName] = useState(false);
    const [tempDynamicRange, setTempDynamicRange] = useState(false);

    const chartData = heatmapChartProcessor.processChartData(
      userInterval,
      interval
    );

    const { timeFrom, timeTo } = createChartTimeFrame(
      interval,
      oldestAnalysisDate.split(" ")[0]
    );

    const handleShowWidgets = () => {
      setShowWidgets(!showWidgets);
    };
    const handleShowAnalyserName = () => {
      setShowAnalyserName(!showAnalyserName);
    };
    const handleTempDynamicRange = () => {
      setTempDynamicRange(!tempDynamicRange);
    };

    useEffect(() => {
      setUserInterval("");
    }, [selectedAnalysersIds]);

    useEffect(() => {
      const finalInterval = heatmapChartProcessor.intervalCalculatedHandler(
        userInterval,
        intervalData
      );
      setInterval(finalInterval);
    }, [technicalList, fundamentalList, userInterval, intervalData]);

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.disabledFeatures,
        enabled_features: specificConfigs?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        timeframe: { from: timeFrom, to: timeTo },
        saved_data: chartData,
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreatorClass(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const renderTradeBoundariesPoints = (
            techDetail,
            points,
            type,
            color
          ) => {
            const functionName = renderTradeBoundariesPoints.name
            validateInput(techDetail, 'techDetail', functionName)
            validateInput(points, 'points', functionName)
            validateInput(type, 'type', functionName)
            validateInput(color, 'color', functionName)
            
            const widgetLabelOverrides = (text, textcolorKey, textcolorValue) =>
              showAnalyserName &&
              createWidgetLabelOverrides(text, {
                text: techDetail?.analysis?.user_name,
                [textcolorKey]: textcolorValue,
              });

            if (points && points.length > 0) {
              for (const point of points) {
                const shapeType = point?.max_amount === 0 ? "single" : "range";
                const widgetTimeRange = createTradeBoundariesWidgetTimeRange(
                  interval,
                  techDetail?.analysis?.created_at,
                  baseDateType,
                  tempDynamicRange
                );

                if (shapeType === "single") {
                  createWidget.trendLine(
                    point?.base_amount.toString(),
                    widgetTimeRange,
                    type,
                    widgetLabelOverrides(
                      "trendLineHorzHeatMap",
                      "textcolor",
                      color
                    )
                  );
                }
                if (shapeType === "range") {
                  createWidget.rectangle(
                    [
                      point?.base_amount.toString(),
                      point?.max_amount.toString(),
                    ],
                    widgetTimeRange,
                    type,
                    widgetLabelOverrides(
                      "rectangleHorzHeatMap",
                      "textColor",
                      color
                    )
                  );
                }
              }
            }
          };

          technicalList?.forEach((techDetail) => {
            techDetail?.details?.forEach((detail) => {
              const tradeBoundariesPointsData = [
                { type: "ep", color: tvColors?.blue },
                { type: "tp", color: tvColors?.green },
                { type: "sl", color: tvColors?.red },
              ];

              tradeBoundariesPointsData.forEach(({ type, color }) => {
                if (detail?.[type]) {
                  renderTradeBoundariesPoints(
                    techDetail,
                    detail[type],
                    type,
                    color
                  );
                }
              });
            });
          });

          fundamentalWidgetPlotProcess(
            fundamentalList,
            createWidget,
            showAnalyserName
          );
          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
              setUserInterval(convertIntervalToTradingViewFormat(interval));
            });

          if (!showWidgets) {
            tvWidget.activeChart().removeAllShapes();
            tvWidget.activeChart().removeAllStudies();
          }
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [
      technicalList,
      fundamentalList,
      interval,
      userInterval,
      showWidgets,
      showAnalyserName,
      tempDynamicRange,
      chartData,
    ]);

    const checkBoxListData = [
      {
        id: "showWidgets",
        label: "Show widgets",
        checked: showWidgets,
        onChange: handleShowWidgets,
      },
      {
        id: "showNames",
        label: "Show names",
        checked: showAnalyserName,
        onChange: handleShowAnalyserName,
      },
      {
        id: "dynamicRange",
        label: "Dynamic range",
        checked: tempDynamicRange,
        onChange: handleTempDynamicRange,
      },
    ];

    return (
      <div className="flex flex-col w-full">
        <div className="flex mb-3 gap-5 text-sky-70 font-roboto font-bold">
          {checkBoxListData.map(({ id, label, checked, onChange }) => (
            <Checkbox
              key={id}
              id={id}
              label={label}
              checked={checked}
              onChange={onChange}
            />
          ))}
        </div>
        <div ref={chartContainerRef} className={"TVChartContainer"} />
      </div>
    );
  }
);


TVChartContainer.propTypes = {
  heatmapChartDataDetails: PropTypes.object.isRequired,
  baseDateType: PropTypes.string.isRequired,
  selectedAnalysersIds: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
};
