import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { heatMapApi } from "~/api/dashboard/heatMapApi";

import { useKeySubmission } from "~/utils/hooks";
import {
  transformedNewHeatMapParams,
  transformedDetailsHeatMapParams,
} from "~/utils/getTransformedData";
import Button from "~/components/child/ui/Button";
import Breadcrumb from "~/components/child/Breadcrumb";
import { NewHeatMap } from "./NewHeatMap";
import { Details } from "./Details";
import { ChooseAnalysis } from "./List";

import {
  newHeatMapStepValidation,
  detailsStepValidation,
} from "./helpers/validation";

const CreateNew = ({ setPopUpIsOpen }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState("newHeatMap");
  const [heatMapValues, setHeatMapValues] = useState({
    new: {
      title: "",
      analysisType: [],
      description: "",
      date: { from: undefined, to: undefined },
    },
    details: {
      technical: {
        pair: "",
        timeFrames: [],
        positionType: [],
        technicalType: [],
      },
      fundamental: { pairs: [] },
      onChain: { pairs: [] },
    },
    analytics: [],
  });

  const [analysisList, setAnalysisList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    details: undefined,
    analysisList: undefined,
    create: undefined,
  });
  const [details, setDetails] = useState(undefined);

  const fetchAnalysisList = async () => {
    setIsLoading(true);
    setErrorMessage({ ...errorMessage, analysisList: undefined });
    try {
      const result = await heatMapApi.getAnalysisList(
        currentPage,
        transformedDetailsHeatMapParams({
          technical: heatMapValues?.new?.analysisType?.some(
            (obj) => obj.key === "technical"
          )
            ? {
                pairs: [heatMapValues?.details?.technical?.pair.key],
                timeframes: [
                  ...heatMapValues?.details?.technical?.timeFrames?.map(
                    (timeFrame) => timeFrame.key
                  ),
                ],
                position: [
                  ...heatMapValues?.details?.technical?.positionType?.map(
                    (positionType) => positionType.key
                  ),
                ],
                technical_type: [
                  ...heatMapValues?.details?.technical?.technicalType?.map(
                    (technicalType) => technicalType.key
                  ),
                ],
                dates: {
                  start: `${heatMapValues.new.date.from?.year}-${heatMapValues.new.date.from?.month?.number}-${heatMapValues.new.date.from?.day}`,
                  end: `${heatMapValues.new.date.to?.year}-${heatMapValues.new.date.to?.month?.number}-${heatMapValues.new.date.to?.day}`,
                },
              }
            : null,
          fundamental: heatMapValues?.new?.analysisType?.some(
            (obj) => obj.key === "fundamental"
          )
            ? {
                pairs: [
                  ...heatMapValues?.details?.fundamental?.pairs?.map(
                    (pair) => pair.key
                  ),
                ],
                dates: {
                  start: `${heatMapValues.new.date.from?.year}-${heatMapValues.new.date.from?.month?.number}-${heatMapValues.new.date.from?.day}`,
                  end: `${heatMapValues.new.date.to?.year}-${heatMapValues.new.date.to?.month?.number}-${heatMapValues.new.date.to?.day}`,
                },
              }
            : null,
          on_chain: heatMapValues?.new?.analysisType?.some(
            (obj) => obj.key === "on_chain"
          )
            ? {
                pairs: [
                  ...heatMapValues?.details?.onChain?.pairs?.map(
                    (pair) => pair.key
                  ),
                ],
                dates: {
                  start: `${heatMapValues.new.date.from?.year}-${heatMapValues.new.date.from?.month?.number}-${heatMapValues.new.date.from?.day}`,
                  end: `${heatMapValues.new.date.to?.year}-${heatMapValues.new.date.to?.month?.number}-${heatMapValues.new.date.to?.day}`,
                },
              }
            : null,
        })
      );
      if (result?.data) {
        setAnalysisList((prev) => [
          ...prev,
          ...Object.values(result.data.data),
        ]);
        if (result?.data?.meta?.last_page < currentPage) setHasMoreData(false);
        setCurrentPage((prev) => prev + 1);
      } else if (result?.error) {
        if (result?.error.response?.data?.errors) {
          setErrorMessage({
            ...errorMessage,
            analysisList: result?.error?.response?.data?.errors,
          });
        } else
          setErrorMessage({
            ...errorMessage,
            analysisList: [result?.error.response?.data.message],
          });
      }
    } catch (error) {
      console.error("Error fetching analysis list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDetails = async () => {
    setIsLoading(true);

    try {
      setErrorMessage({ ...errorMessage, details: undefined });

      const result = await heatMapApi.getDetails({
        ...transformedNewHeatMapParams({ ...heatMapValues.new }),
      });
      if (result?.data) {
        setDetails({
          technical: result?.data?.data?.technical
            ? {
                pairs: Object.values(result?.data?.data?.technical?.pairs),
                timeFrames: result.data?.data?.technical?.time_frames,
                positionType: result.data?.data?.technical?.position_type,
              }
            : undefined,
          fundamental: result.data?.data?.fundamental
            ? {
                pairs: Object.values(result.data?.data?.fundamental?.pairs),
              }
            : undefined,
          onChain: result.data?.data?.on_chain
            ? {
                pairs: Object.values(result.data?.data?.on_chain?.pairs),
              }
            : undefined,
        });
        setStep("details");
      } else if (result?.error) {
        if (result?.error.response?.data?.errors) {
          setErrorMessage({
            ...errorMessage,
            details: result?.error?.response?.data?.errors,
          });
        } else
          setErrorMessage({
            ...errorMessage,
            details: [result?.error.response?.data.message],
          });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setErrorMessage({ ...errorMessage, details: undefined });
  }, [heatMapValues.new]);

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);
    setAnalysisList([]);
  }, [heatMapValues.new, heatMapValues.details]);

  const createNewHeatMap = () => {
    setErrorMessage({ ...errorMessage, create: undefined });

    const transformedAnalytics = { analytics: {} };
    heatMapValues.analytics.forEach((item, index) => {
      transformedAnalytics.analytics[index] = item?.toLowerCase();
    });
    heatMapApi
      .createNew({
        title: heatMapValues.new.title,
        description: heatMapValues.new.description,
        analytics: transformedAnalytics.analytics,
      })
      .then((res) => {
        if (res && !res.error && res.data) {
          setPopUpIsOpen(false);
          navigate("/heatMap-list");
        } else if (res?.error) {
          if (res?.error.response?.data?.errors) {
            setErrorMessage(res?.error?.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  useKeySubmission(
    step === "newHeatMap"
      ? fetchDetails
      : step === "details"
      ? () => {
          fetchAnalysisList();
          setStep("list");
        }
      : createNewHeatMap,
    () => {
      if (step === "newHeatMap")
        return newHeatMapStepValidation(
          {
            ...heatMapValues.new,
            date: {
              from: heatMapValues.new.date.from,
              to: heatMapValues.new.date.to,
            },
          },
          { ...errorMessage },
          isLoading
        );
      else if (step === "details")
        return detailsStepValidation({ ...heatMapValues?.details }, details);
      else return heatMapValues.analytics.length === 0 || isLoading;
    },
    (event) => event.key === "Enter",
    []
  );

  return (
    <div className="heatMap__new-heatMap-popup">
      <div className={`heatMap__new-heatMap-popup__box `}>
        <Breadcrumb
          steps={[
            { label: "New HeatMap", active: step === "newHeatMap" ? 1 : 0 },
            { label: "Details", active: step === "details" ? 1 : 0 },
            { label: "List", active: step === "list" ? 1 : 0 },
          ]}
        />
        {step === "newHeatMap" && (
          <>
            <NewHeatMap
              heatMapValues={heatMapValues}
              setHeatMapValues={setHeatMapValues}
            />
            <div className="heatMap__new-heatMap-popup__box__form__add-cancel-btn gap-3 flex flex-col">
              <div className="flex gap-4 justify-between">
                <button
                  onClick={() => setPopUpIsOpen(false)}
                  className="btn-cstm btn-outline-dark-cstm"
                >
                  Cancel
                </button>
                <Button
                  onClick={() => {
                    fetchDetails();
                  }}
                  disabled={newHeatMapStepValidation(
                    {
                      ...heatMapValues.new,
                      date: {
                        from: heatMapValues.new.date.from,
                        to: heatMapValues.new.date.to,
                      },
                    },
                    { ...errorMessage },
                    isLoading
                  )}
                  variant="primary"
                  isLoading={isLoading}
                  className="w-full"
                >
                  Next
                </Button>
              </div>
              {errorMessage?.details && Object.keys(errorMessage?.details) && (
                <div className="heatMap__new-heatMap-popup__box__errorBox">
                  {Object.keys(errorMessage?.details)?.map((key) => (
                    <p
                      key={key}
                      className="heatMap__new-heatMap-popup__box__errorBox__errorItem"
                    >
                      {errorMessage?.details[key]}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        {step === "details" && (
          <>
            <Details
              details={details}
              heatMapValues={heatMapValues}
              setHeatMapValues={setHeatMapValues}
            />
            <div className="heatMap__new-heatMap-popup__box__form__add-cancel-btn">
              <div className="flex gap-4 justify-between">
                <button
                  onClick={() => setStep("newHeatMap")}
                  className="btn-cstm btn-outline-dark-cstm"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    fetchAnalysisList();
                    setStep("list");
                  }}
                  className="btn-cstm btn-dark-cstm"
                  disabled={detailsStepValidation(
                    { ...heatMapValues?.details },
                    details
                  )}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
        {step === "list" && (
          <>
            <ChooseAnalysis
              analysisList={analysisList}
              heatMapValues={heatMapValues}
              setHeatMapValues={setHeatMapValues}
              currentPage={currentPage}
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              next={fetchAnalysisList}
            />
            <div className="heatMap__new-heatMap-popup__box__form__add-cancel-btn">
              <div className="flex gap-4 justify-between">
                <button
                  onClick={() => setStep("details")}
                  className="btn-cstm btn-outline-dark-cstm"
                >
                  Back
                </button>
                <button
                  className="btn-cstm btn-dark-cstm"
                  disabled={heatMapValues.analytics.length === 0 || isLoading}
                  onClick={() => createNewHeatMap()}
                >
                  Create
                </button>
              </div>
              {errorMessage && Object.keys(errorMessage) && (
                <div className="heatMap__new-heatMap-popup__box__errorBox">
                  {Object.keys(errorMessage)?.map((key) => (
                    <p
                      key={key}
                      className="heatMap__new-heatMap-popup__box__errorBox__errorItem"
                    >
                      {errorMessage[key]}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateNew;
