export const convertIntervalToTradingViewFormat = (interval) => {
  const intervalMap = {
    "1 Minute": "1",
    "3 Minutes": "3",
    "5 Minutes": "5",
    "15 Minutes": "15",
    "30 Minutes": "30",
    "45 Minutes": "45",
    "1 Hour": "60",
    "2 Hours": "120",
    "3 Hours": "180",
    "4 Hours": "240",
    "1 Day": "D",
    "1D": "D",
    "1 Week": "W",
    "1W": "W",
    "1 Month": "M",
    "1M": "M",
    "3 Months": "3M",
    "6 Months": "6M",
    "12 Months": "12M",
  };

  return intervalMap[interval] || interval;
};
